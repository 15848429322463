import React from "react";

import styled from "styled-components";
import { useConfig } from "../ConfigProvider";

function DevInfo() {
  const { serviceApiUrl, spaceUrl, stage, loadedFrom } = useConfig();

  return (
    <>
      <p>
        Version: <Highlight>{process.env.REACT_APP_VERSION}</Highlight>
      </p>
      <p>
        Build mode: <Highlight>{process.env.NODE_ENV}</Highlight>
      </p>
      <p>
        Deploy stage: <Highlight>{stage}</Highlight>
      </p>
      <p>
        Config source: <Highlight>{loadedFrom}</Highlight>
      </p>
      <p>
        API: <Highlight>{serviceApiUrl}</Highlight>
      </p>
      <p>
        Practitioner Space: <Highlight>{spaceUrl}</Highlight>
      </p>
      <p>Font support:</p>
      <p>Āā Ēē Īī Ōō Ūū </p>
      <p>
        <Highlight>Āā Ēē Īī Ōō Ūū</Highlight>
      </p>
    </>
  );
}

const Highlight = styled.span`
  font-weight: bold;
`;

export default DevInfo;
