export default function getAxiosErrorMessage(error) {
  // The API should send back a translated error - response()->json(['error' => trans('string')], xxx)
  // If not, we fall back on the http response - Request failed with status code xxx
  if (error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  }

  // If the API didn't return an error with translated string and it's a 422,
  // we have a validation error - and may have multiple issues.
  if (error.response && error.response.status === 422) {
    const data = error.response.data;
    const keys = Object.keys(data);
    let message = "";
    keys.forEach(function (k) {
      // We only need the 1st error for each field returned.
      // If there are multiple validation errors on a field we can take in stages.
      // In practice - there shouldn't be.
      // At time of writing, we only allow one message box with one text string.
      // Concat them with a space. In practice - client validation will normally prevent anyway.
      message += data[k][0] + " ";
    });
    return message;
  }
  // If there is no response, fallback to message.
  return error.message;
}
