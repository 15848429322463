import { AxiosInstance } from "axios";
import I18nString from "../utils/I18nString";

const AxiosAuthenticator = {
  /**
   * Handles Axios error responses; includes things like
   * - non 2xx responses (pass the body along, it's probably a string message)
   * - vanishing requests (pass a translatable "human" error)
   * - total failure to launch (pass the axios "inhuman" error)
   * @param {Object} error
   * @return {string}
   */
  handleRejectOrNetError: function (
    error: Error | (Error & { response: any; request: any }),
  ) {
    // console.log(error.config);
    if ("response" in error && error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx.
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      // If the response data doesn't have an error, show the status text
      return error.response.data.error
        ? error.response.data.error
        : error.response.statusText;
    } else if ("request" in error && error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js.
      // console.log(error.request);
      return new I18nString("errors.axios.requestFailure");
    } else {
      // Something happened in setting up the request that triggered an Error.
      // console.log('Error', error.message);
      return error.message;
    }
  },

  execute: async function (
    Api: AxiosInstance,
    credentials: { email: string; password: string },
  ) {
    const authEndpoint = "/user/login";
    let response;
    try {
      response = await Api.post(authEndpoint, credentials);
    } catch (error) {
      let err = error;
      if (!error || typeof error !== "object") {
        err = new Error("Unknown error" + error);
      }

      return {
        data: null,
        errorMessage: this.handleRejectOrNetError(err as Error),
      };
    }
    return {
      data: response.data,
      errorMessage: null,
    };
  },
};

export default AxiosAuthenticator;
