import type { TFunction } from "i18next";

/**
 * WARNING: We are trying this out - export translated array of content.
 * It may not work out in the long term if translations are not updated on user language change.
 * Using the translation within components ensures that they update. These may or not.
 * Keeping an open mind until we hit a snag.
 */

function ScenariosContent(t: TFunction) {
  /**
   * Translates a checkbox's image src when provided with the key of
   * a set of checkboxes directly from a content file
   */
  function translateCheckboxes(checkboxKey) {
    const checkboxArray = t(checkboxKey, { returnObjects: true });

    if (!Array.isArray(checkboxArray)) return [];

    const translatedCheckboxes = checkboxArray.map((checkbox, index) => {
      // If we haven't been give a key, construct it to fallback
      const name = checkbox?.name
        ? checkbox.name
        : t(`${checkboxKey}.${index}.name`);
      const image = checkbox?.image?.src
        ? checkbox.image.src
        : t(`${checkboxKey}.${index}.image.src`);

      return { name: name, image: { src: t(image) } };
    });

    return translatedCheckboxes;
  }

  const feelingCheckboxes = [
    {
      name: t("scenarios:shared.feeling.option1.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option1.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option1.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option2.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option2.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option2.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option3.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option3.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option3.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option4.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option4.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option4.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option5.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option5.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option5.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option6.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option6.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option6.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option7.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option7.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option7.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option8.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option8.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option8.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option9.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option9.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option9.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option10.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option10.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option10.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option11.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option11.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option11.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.feeling.option12.name"),
      image: {
        src: t(t("scenarios:shared.feeling.option12.image.src")),
      },
      imageOlderYP: {
        src: t(t("scenarios:shared.feeling.option12.imageOlderYP.src")),
      },
    },
  ];

  const goodInLifeCheckboxes = [
    {
      name: t("scenarios:shared.goodinlife.option1.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option2.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option3.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option4.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option5.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option6.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option7.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option8.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option9.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option9.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option10.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option10.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option11.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option11.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option12.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option12.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option13.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option13.image.src")),
      },
    },
    {
      name: t("scenarios:shared.goodinlife.option14.name"),
      image: {
        src: t(t("scenarios:shared.goodinlife.option14.image.src")),
      },
    },
  ];

  const peopleathomeCheckboxes = [
    {
      name: t("scenarios:shared.peopleathome.option1.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option2.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option3.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option4.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option5.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option6.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option7.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option8.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option9.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option9.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option10.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option10.image.src")),
      },
    },
    {
      name: t("scenarios:shared.peopleathome.option11.name"),
      image: {
        src: t(t("scenarios:shared.peopleathome.option11.image.src")),
      },
    },
  ];

  const getOnWithFriendsCheckboxes = [
    {
      name: t("scenarios:shared.getonwithfriends.option1.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option2.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option3.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option4.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option5.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option6.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option7.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option8.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.getonwithfriends.option9.name"),
      image: {
        src: t(t("scenarios:shared.getonwithfriends.option9.image.src")),
      },
    },
  ];

  const haveInLifeCheckboxes = [
    {
      name: t("scenarios:shared.haveinlife.option1.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option2.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option3.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option4.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option5.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option6.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option7.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option8.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option9.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option9.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option10.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option10.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option11.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option11.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option12.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option12.image.src")),
      },
    },
    {
      name: t("scenarios:shared.haveinlife.option13.name"),
      image: {
        src: t(t("scenarios:shared.haveinlife.option13.image.src")),
      },
    },
  ];

  const localAreaCheckboxes = [
    {
      name: t("scenarios:shared.localarea.option1.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option2.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option3.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option4.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option5.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option6.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option7.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option8.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option9.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option9.image.src")),
      },
    },
    {
      name: t("scenarios:shared.localarea.option10.name"),
      image: {
        src: t(t("scenarios:shared.localarea.option10.image.src")),
      },
    },
  ];

  const howsItGoingPositions = [
    {
      name: t("scenarios:shared.howsitgoing.position1.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position1.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position1.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position1.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position1.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position2.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position2.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position2.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position2.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position2.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position3.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position3.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position3.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position3.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position3.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position4.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position4.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position4.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position4.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position4.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position5.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position5.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position5.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position5.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position5.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position6.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position6.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position6.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position6.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position6.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position7.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position7.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position7.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position7.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position7.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position8.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position8.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position8.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position8.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position8.imageOlderYP.src")),
      },
    },
    {
      name: t("scenarios:shared.howsitgoing.position9.name"),
      image: {
        alt: t(t("scenarios:shared.howsitgoing.position9.image.alt")),
        src: t(t("scenarios:shared.howsitgoing.position9.image.src")),
      },
      imageOlderYP: {
        alt: t(t("scenarios:shared.howsitgoing.position9.imageOlderYP.alt")),
        src: t(t("scenarios:shared.howsitgoing.position9.imageOlderYP.src")),
      },
    },
  ];

  const notGoodInLifeCheckboxes = [
    {
      name: t("scenarios:shared.notgoodinlife.option1.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option1.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option2.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option2.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option3.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option3.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option4.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option4.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option5.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option5.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option6.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option6.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option7.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option7.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option8.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option8.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option9.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option9.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option10.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option10.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option11.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option11.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option12.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option12.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option13.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option13.image.src")),
      },
    },
    {
      name: t("scenarios:shared.notgoodinlife.option14.name"),
      image: {
        src: t(t("scenarios:shared.notgoodinlife.option14.image.src")),
      },
    },
  ];

  const scenarios = [
    {
      id: "worker-visit",
      title: t("scenarios:worker-visit.title"),
      titlePastTense: t("scenarios:worker-visit.title-past-tense"),
      description: t("scenarios:worker-visit.description"),
      slug: "worker-visit",
      image: t("images:front_door.src"),
      formLinks: [],
      pdfOrder: [
        "feeling",
        "goodinlife",
        "notgoodinlife",
        "whereyoulive",
        "bedifferent",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "goodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.goodinlife.title"),
          pdfTitle: t("scenarios:shared.goodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: goodInLifeCheckboxes,
        },
        {
          id: "notgoodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.notgoodinlife.title"),
          pdfTitle: t("scenarios:shared.notgoodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: notGoodInLifeCheckboxes,
        },
        {
          id: "whereyoulive",
          type: "checkboxestext",
          title: t("scenarios:shared.whereyoulive.title"),
          pdfTitle: t("scenarios:shared.whereyoulive.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:shared.whereyoulive.option1.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option1.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option2.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option2.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option3.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option3.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option4.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option4.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option5.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option5.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option6.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option6.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option7.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option7.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option8.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option8.image.src")),
              },
            },
          ],
        },
        {
          id: "bedifferent",
          type: "textarea",
          title: t("scenarios:shared.bedifferent.title"),
          pdfTitle: t("scenarios:shared.bedifferent.pdftitle"),
          description: t("scenarios:shared.bedifferent.description"),
          tags: [
            {
              name: t("scenarios:shared.bedifferent.option1.name"),
              fragment: t("scenarios:shared.bedifferent.option1.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option2.name"),
              fragment: t("scenarios:shared.bedifferent.option2.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option3.name"),
              fragment: t("scenarios:shared.bedifferent.option3.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option4.name"),
              fragment: t("scenarios:shared.bedifferent.option4.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option5.name"),
              fragment: t("scenarios:shared.bedifferent.option5.fragment"),
            },
          ],
          text: "",
        },
      ],
    },

    {
      id: "my-life",
      title: t("scenarios:my-life.title"),
      titlePastTense: t("scenarios:my-life.title-past-tense"),
      description: t("scenarios:my-life.description"),
      slug: "my-life",
      image: t("images:person_speaking_star.src"),
      formLinks: [],
      pdfOrder: [
        "feeling",
        "goodinlife",
        "notgoodinlife",
        "whereyoulive",
        "bedifferent",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "goodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.goodinlife.title"),
          pdfTitle: t("scenarios:shared.goodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: goodInLifeCheckboxes,
        },
        {
          id: "notgoodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.notgoodinlife.title"),
          pdfTitle: t("scenarios:shared.notgoodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: notGoodInLifeCheckboxes,
        },
        {
          id: "whereyoulive",
          type: "checkboxestext",
          title: t("scenarios:shared.whereyoulive.title"),
          pdfTitle: t("scenarios:shared.whereyoulive.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:shared.whereyoulive.option1.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option1.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option2.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option2.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option3.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option3.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option4.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option4.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option5.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option5.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option6.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option6.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option7.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option7.image.src")),
              },
            },
            {
              name: t("scenarios:shared.whereyoulive.option8.name"),
              image: {
                src: t(t("scenarios:shared.whereyoulive.option8.image.src")),
              },
            },
          ],
        },
        {
          id: "bedifferent",
          type: "textarea",
          title: t("scenarios:shared.bedifferent.title"),
          pdfTitle: t("scenarios:shared.bedifferent.pdftitle"),
          description: t("scenarios:shared.bedifferent.description"),
          tags: [
            {
              name: t("scenarios:shared.bedifferent.option1.name"),
              fragment: t("scenarios:shared.bedifferent.option1.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option2.name"),
              fragment: t("scenarios:shared.bedifferent.option2.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option3.name"),
              fragment: t("scenarios:shared.bedifferent.option3.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option4.name"),
              fragment: t("scenarios:shared.bedifferent.option4.fragment"),
            },
            {
              name: t("scenarios:shared.bedifferent.option5.name"),
              fragment: t("scenarios:shared.bedifferent.option5.fragment"),
            },
          ],
          text: "",
        },
      ],
    },

    {
      id: "preparation",
      title: t("scenarios:preparation.title"),
      titlePastTense: t("scenarios:preparation.title-past-tense"),
      description: t("scenarios:preparation.description"),
      slug: "preparation",
      image: t("images:handshake.src"),
      pdfOrder: [
        "meetingtype",
        "feeling",
        "location",
        "wantthere",
        "dontwantthere",
        "sitby",
        "goodinlife",
        "notgoodinlife",
        "meetingchair",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "meetingtype",
          type: "radioplus",
          title: t("scenarios:preparation.meetingtype.title"),
          pdfTitle: t("scenarios:preparation.meetingtype.pdftitle"),
          description: t("scenarios:preparation.meetingtype.description"),
          radioOptions: t("scenarios:preparation.meetingtype.radioOptions", {
            returnObjects: true,
          }),
        },
        {
          id: "question",
          type: "textarea",
          title: t("scenarios:preparation.question.title"),
          pdfTitle: t("scenarios:preparation.question.pdftitle"),
          description: t("scenarios:preparation.question.description"),
          tags: [
            {
              name: t("scenarios:preparation.question.option1.name"),
              fragment: t("scenarios:preparation.question.option1.fragment"),
            },
            {
              name: t("scenarios:preparation.question.option2.name"),
              fragment: t("scenarios:preparation.question.option2.fragment"),
            },
            {
              name: t("scenarios:preparation.question.option3.name"),
              fragment: t("scenarios:preparation.question.option3.fragment"),
            },
            {
              name: t("scenarios:preparation.question.option4.name"),
              fragment: t("scenarios:preparation.question.option4.fragment"),
            },
            {
              name: t("scenarios:preparation.question.option5.name"),
              fragment: t("scenarios:preparation.question.option5.fragment"),
            },
            {
              name: t("scenarios:preparation.question.option6.name"),
              fragment: t("scenarios:preparation.question.option6.fragment"),
            },
          ],
          text: "",
        },
        {
          id: "location",
          type: "smalltext",
          title: t("scenarios:preparation.location.title"),
          pdfTitle: t("scenarios:preparation.location.pdftitle"),
          description: t("scenarios:preparation.location.description"),
          placeholder: t("scenarios:preparation.location.placeholder"),
          text: "",
        },
        {
          id: "wantthere",
          type: "smalltext",
          title: t("scenarios:preparation.wantthere.title"),
          pdfTitle: t("scenarios:preparation.wantthere.pdftitle"),
          description: t("scenarios:preparation.wantthere.description"),
          placeholder: t("scenarios:preparation.wantthere.placeholder"),
          text: "",
        },
        {
          id: "dontwantthere",
          type: "smalltext",
          title: t("scenarios:preparation.dontwantthere.title"),
          pdfTitle: t("scenarios:preparation.dontwantthere.pdftitle"),
          description: t("scenarios:preparation.dontwantthere.description"),
          placeholder: t("scenarios:preparation.dontwantthere.placeholder"),
          text: "",
        },
        {
          id: "sitby",
          type: "smalltext",
          title: t("scenarios:preparation.sitby.title"),
          pdfTitle: t("scenarios:preparation.sitby.pdftitle"),
          description: t("scenarios:preparation.sitby.description"),
          placeholder: t("scenarios:preparation.sitby.placeholder"),
          text: "",
        },
        {
          id: "goodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.goodinlife.title"),
          pdfTitle: t("scenarios:shared.goodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: goodInLifeCheckboxes,
        },
        {
          id: "notgoodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.notgoodinlife.title"),
          pdfTitle: t("scenarios:shared.notgoodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: notGoodInLifeCheckboxes,
        },
        {
          id: "meetingchair",
          type: "checkboxes",
          title: t("scenarios:preparation.meetingchair.title"),
          pdfTitle: t("scenarios:preparation.meetingchair.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: translateCheckboxes(
            "scenarios:preparation.meetingchair.checkboxes",
          ),
        },
      ],
    },

    {
      id: "share-good-news",
      title: t("scenarios:share-good-news.title"),
      titlePastTense: t("scenarios:share-good-news.title-past-tense"),
      description: t("scenarios:share-good-news.description"),
      slug: "share-good-news",
      image: t("images:megaphone.src"),
      pdfOrder: ["howsitgoing", "feeling", "whathappened", "helpwithsomething"],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "whathappened",
          type: "textarea",
          title: t("scenarios:share-good-news.whathappened.title"),
          pdfTitle: t("scenarios:share-good-news.whathappened.pdftitle"),
          description: t("scenarios:share-good-news.whathappened.description"),
          tags: [
            {
              name: t("scenarios:share-good-news.whathappened.option1.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option1.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option2.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option2.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option3.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option3.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option4.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option4.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option5.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option5.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option6.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option6.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option7.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option7.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option8.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option8.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option9.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option9.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option10.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option10.fragment",
              ),
            },
            {
              name: t("scenarios:share-good-news.whathappened.option11.name"),
              fragment: t(
                "scenarios:share-good-news.whathappened.option11.fragment",
              ),
            },
          ],
          text: "",
        },
        {
          id: "helpwithsomething",
          type: "checkboxestext",
          title: t("scenarios:share-good-news.helpwithsomething.title"),
          pdfTitle: t("scenarios:share-good-news.helpwithsomething.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t(
                "scenarios:share-good-news.helpwithsomething.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:share-good-news.helpwithsomething.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:share-good-news.helpwithsomething.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:share-good-news.helpwithsomething.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:share-good-news.helpwithsomething.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:share-good-news.helpwithsomething.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:share-good-news.helpwithsomething.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:share-good-news.helpwithsomething.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:share-good-news.helpwithsomething.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:share-good-news.helpwithsomething.option5.image.src",
                  ),
                ),
              },
            },
          ],
        },
      ],
    },

    {
      id: "conference",
      title: t("scenarios:conference.title"),
      titlePastTense: t("scenarios:conference.title-past-tense"),
      description: t("scenarios:conference.description"),
      slug: "conference",
      image: t("images:conference.src"),
      pdfOrder: [
        "feeling",
        "whythemeeting",
        "firsttime",
        "explainwhy",
        "goodrightnow",
        "notgoodrightnow",
        "athome",
        "yourlife",
        "whatiwanttosay",
        "happennext",
        "gotomeeting",
        "sitby",
        "aftermeeting",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "whythemeeting",
          type: "radiofixed",
          title: t("scenarios:conference.whythemeeting.title"),
          pdfTitle: t("scenarios:conference.whythemeeting.pdftitle"),
          description: t("scenarios:conference.whythemeeting.description"),
          radioOptions: [
            { name: t("scenarios:conference.whythemeeting.option1") },
            { name: t("scenarios:conference.whythemeeting.option2") },
            { name: t("scenarios:conference.whythemeeting.option3") },
            { name: t("scenarios:conference.whythemeeting.option4") },
            { name: t("scenarios:conference.whythemeeting.option5") },
          ],
        },
        {
          id: "firsttime",
          type: "radiofixed",
          title: t("scenarios:conference.firsttime.title"),
          pdfTitle: t("scenarios:conference.firsttime.pdftitle"),
          description: t("scenarios:conference.firsttime.description"),
          radioOptions: [
            { name: t("scenarios:conference.firsttime.option1") },
            { name: t("scenarios:conference.firsttime.option2") },
            { name: t("scenarios:conference.firsttime.option3") },
          ],
        },
        {
          id: "explainwhy",
          type: "radiofixed",
          title: t("scenarios:conference.explainwhy.title"),
          pdfTitle: t("scenarios:conference.explainwhy.pdftitle"),
          description: t("scenarios:conference.explainwhy.description"),
          radioOptions: [
            { name: t("scenarios:conference.explainwhy.option1") },
            { name: t("scenarios:conference.explainwhy.option2") },
            { name: t("scenarios:conference.explainwhy.option3") },
          ],
        },
        {
          id: "goodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.goodinlife.title"),
          pdfTitle: t("scenarios:shared.goodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: goodInLifeCheckboxes,
        },
        {
          id: "notgoodinlife",
          type: "checkboxestext",
          title: t("scenarios:shared.notgoodinlife.title"),
          pdfTitle: t("scenarios:shared.notgoodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: notGoodInLifeCheckboxes,
        },
        {
          id: "athome",
          type: "textarea",
          title: t("scenarios:conference.athome.title"),
          pdfTitle: t("scenarios:conference.athome.pdftitle"),
          description: t("scenarios:conference.athome.description"),
          tags: [
            {
              name: t("scenarios:conference.athome.option1.name"),
              fragment: t("scenarios:conference.athome.option1.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option2.name"),
              fragment: t("scenarios:conference.athome.option2.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option3.name"),
              fragment: t("scenarios:conference.athome.option3.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option4.name"),
              fragment: t("scenarios:conference.athome.option4.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option5.name"),
              fragment: t("scenarios:conference.athome.option5.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option6.name"),
              fragment: t("scenarios:conference.athome.option6.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option7.name"),
              fragment: t("scenarios:conference.athome.option7.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option8.name"),
              fragment: t("scenarios:conference.athome.option8.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option9.name"),
              fragment: t("scenarios:conference.athome.option9.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option10.name"),
              fragment: t("scenarios:conference.athome.option10.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option11.name"),
              fragment: t("scenarios:conference.athome.option11.fragment"),
            },
            {
              name: t("scenarios:conference.athome.option12.name"),
              fragment: t("scenarios:conference.athome.option12.fragment"),
            },
          ],
          text: "",
        },
        {
          id: "yourlife",
          type: "textarea",
          title: t("scenarios:conference.yourlife.title"),
          pdfTitle: t("scenarios:conference.yourlife.pdftitle"),
          description: t("scenarios:conference.yourlife.description"),
          tags: [
            {
              name: t("scenarios:conference.yourlife.option1.name"),
              fragment: t("scenarios:conference.yourlife.option1.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option2.name"),
              fragment: t("scenarios:conference.yourlife.option2.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option3.name"),
              fragment: t("scenarios:conference.yourlife.option3.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option4.name"),
              fragment: t("scenarios:conference.yourlife.option4.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option5.name"),
              fragment: t("scenarios:conference.yourlife.option5.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option6.name"),
              fragment: t("scenarios:conference.yourlife.option6.fragment"),
            },
            {
              name: t("scenarios:conference.yourlife.option7.name"),
              fragment: t("scenarios:conference.yourlife.option7.fragment"),
            },
          ],
          text: "",
        },
        {
          id: "whatiwanttosay",
          type: "checkboxestext",
          title: t("scenarios:conference.whatiwanttosay.title"),
          pdfTitle: t("scenarios:conference.whatiwanttosay.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:conference.whatiwanttosay.option1.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option2.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option3.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option4.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option5.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option6.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:conference.whatiwanttosay.option7.name"),
              image: {
                src: t(
                  t("scenarios:conference.whatiwanttosay.option7.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "happennext",
          type: "textarea",
          title: t("scenarios:conference.happennext.title"),
          pdfTitle: t("scenarios:conference.happennext.pdftitle"),
          description: t("scenarios:conference.happennext.description"),
          tags: [],
          text: t("scenarios:conference.happennext.text"),
        },
        {
          id: "gotomeeting",
          type: "radiofixed",
          title: t("scenarios:conference.gotomeeting.title"),
          pdfTitle: t("scenarios:conference.gotomeeting.pdftitle"),
          description: t("scenarios:conference.gotomeeting.description"),
          radioOptions: [
            { name: t("scenarios:conference.gotomeeting.option1") },
            { name: t("scenarios:conference.gotomeeting.option2") },
            { name: t("scenarios:conference.gotomeeting.option3") },
          ],
        },
        {
          id: "sitby",
          type: "smalltext",
          title: t("scenarios:conference.sitby.title"),
          pdfTitle: t("scenarios:conference.sitby.pdftitle"),
          description: t("scenarios:conference.sitby.description"),
          text: "",
        },
        {
          id: "aftermeeting",
          type: "radiofixed",
          title: t("scenarios:conference.aftermeeting.title"),
          pdfTitle: t("scenarios:conference.aftermeeting.pdftitle"),
          description: t("scenarios:conference.aftermeeting.description"),
          radioOptions: [
            { name: t("scenarios:conference.aftermeeting.option1") },
            { name: t("scenarios:conference.aftermeeting.option2") },
            { name: t("scenarios:conference.aftermeeting.option3") },
          ],
        },
      ],
    },

    {
      id: "complaint",
      title: t("scenarios:complaint.title"),
      titlePastTense: t("scenarios:complaint.title-past-tense"),
      description: t("scenarios:complaint.description"),
      slug: "complaint",
      image: t("images:exclamation_mark.src"),
      pdfOrder: [
        "feeling",
        "desire",
        "complaint",
        "since",
        "happennext",
        "formal",
        "goodinlife",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "desire",
          type: "textarea",
          title: t("scenarios:complaint.desire.title"),
          pdfTitle: t("scenarios:complaint.desire.pdftitle"),
          description: t("scenarios:complaint.desire.description"),
          tags: [
            {
              name: t("scenarios:complaint.desire.option1.name"),
              fragment: t("scenarios:complaint.desire.option1.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option2.name"),
              fragment: t("scenarios:complaint.desire.option2.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option3.name"),
              fragment: t("scenarios:complaint.desire.option3.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option4.name"),
              fragment: t("scenarios:complaint.desire.option4.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option5.name"),
              fragment: t("scenarios:complaint.desire.option5.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option6.name"),
              fragment: t("scenarios:complaint.desire.option6.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option7.name"),
              fragment: t("scenarios:complaint.desire.option7.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option8.name"),
              fragment: t("scenarios:complaint.desire.option8.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option9.name"),
              fragment: t("scenarios:complaint.desire.option9.fragment"),
            },
            {
              name: t("scenarios:complaint.desire.option10.name"),
              fragment: t("scenarios:complaint.desire.option10.fragment"),
            },
          ],
          text: "",
        },
        {
          id: "complaint",
          type: "textarea",
          title: t("scenarios:complaint.complaint.title"),
          pdfTitle: t("scenarios:complaint.complaint.pdftitle"),
          description: t("scenarios:complaint.complaint.description"),
          tags: [
            {
              name: t("scenarios:complaint.complaint.option1.name"),
              fragment: t("scenarios:complaint.complaint.option1.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option2.name"),
              fragment: t("scenarios:complaint.complaint.option2.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option3.name"),
              fragment: t("scenarios:complaint.complaint.option3.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option4.name"),
              fragment: t("scenarios:complaint.complaint.option4.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option5.name"),
              fragment: t("scenarios:complaint.complaint.option5.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option6.name"),
              fragment: t("scenarios:complaint.complaint.option6.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option7.name"),
              fragment: t("scenarios:complaint.complaint.option7.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option8.name"),
              fragment: t("scenarios:complaint.complaint.option8.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option9.name"),
              fragment: t("scenarios:complaint.complaint.option9.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option10.name"),
              fragment: t("scenarios:complaint.complaint.option10.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option11.name"),
              fragment: t("scenarios:complaint.complaint.option11.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option12.name"),
              fragment: t("scenarios:complaint.complaint.option12.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option13.name"),
              fragment: t("scenarios:complaint.complaint.option13.fragment"),
            },
            {
              name: t("scenarios:complaint.complaint.option14.name"),
              fragment: t("scenarios:complaint.complaint.option14.fragment"),
            },
          ],
          text: "",
        },
        {
          id: "since",
          type: "radioplus",
          title: t("scenarios:complaint.since.title"),
          pdfTitle: t("scenarios:complaint.since.pdftitle"),
          description: t("scenarios:complaint.since.description"),
          radioOptions: [
            { name: t("scenarios:complaint.since.option1") },
            { name: t("scenarios:complaint.since.option2") },
            { name: t("scenarios:complaint.since.option3") },
          ],
        },
        {
          id: "happennext",
          type: "textarea",
          title: t("scenarios:complaint.happennext.title"),
          pdfTitle: t("scenarios:complaint.happennext.pdftitle"),
          description: t("scenarios:complaint.happennext.description"),
          tags: [
            {
              name: t("scenarios:complaint.happennext.option1.name"),
              fragment: t("scenarios:complaint.happennext.option1.fragment"),
            },
            {
              name: t("scenarios:complaint.happennext.option2.name"),
              fragment: t("scenarios:complaint.happennext.option2.fragment"),
            },
            {
              name: t("scenarios:complaint.happennext.option3.name"),
              fragment: t("scenarios:complaint.happennext.option3.fragment"),
            },
            {
              name: t("scenarios:complaint.happennext.option4.name"),
              fragment: t("scenarios:complaint.happennext.option4.fragment"),
            },
            {
              name: t("scenarios:complaint.happennext.option5.name"),
              fragment: t("scenarios:complaint.happennext.option5.fragment"),
            },
            {
              name: t("scenarios:complaint.happennext.option6.name"),
              fragment: t("scenarios:complaint.happennext.option6.fragment"),
            },
          ],
        },
        {
          id: "formal",
          type: "radiofixed",
          title: t("scenarios:complaint.formal.title"),
          pdfTitle: t("scenarios:complaint.formal.pdftitle"),
          description: t("scenarios:complaint.formal.description"),
          radioOptions: [
            { name: t("scenarios:complaint.formal.option1") },
            { name: t("scenarios:complaint.formal.option2") },
            { name: t("scenarios:complaint.formal.option3") },
            { name: t("scenarios:complaint.formal.option4") },
            { name: t("scenarios:complaint.formal.option5") },
          ],
        },
        {
          //this one has different title text to the shared version but
          //options are the same, so shared checkboxes used.
          id: "goodinlife",
          type: "checkboxestext",
          title: t("scenarios:complaint.goodinlife.title"),
          pdfTitle: t("scenarios:complaint.goodinlife.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: goodInLifeCheckboxes,
        },
      ],
    },

    {
      id: "pathway",
      title: t("scenarios:pathway.title"),
      titlePastTense: t("scenarios:pathway.title-past-tense"),
      description: t("scenarios:pathway.description"),
      slug: "pathway",
      image: t("images:adult_and_child.src"),
      pdfOrder: [
        "feeling",
        "myhappiness",
        "friendsandfamily",
        "myhealth",
        "mywellbeing",
        "myeducationandemployment",
        "mymoney",
        "myhome",
        "mylifeskills",
        "othersupport",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "myhappiness1",
          type: "smalltext",
          title: t("scenarios:pathway.myhappiness1.title"),
          pdfTitle: t("scenarios:pathway.myhappiness1.pdftitle"),
          description: t("scenarios:pathway.myhappiness1.description"),

          text: "",
        },
        {
          id: "myhappiness2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhappiness2.pdftitle"),
          description: t("scenarios:pathway.myhappiness2.description"),
          tags: [],
          text: t("scenarios:pathway.myhappiness2.text"),
        },
        {
          id: "myhappiness3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhappiness3.pdftitle"),
          description: t("scenarios:pathway.myhappiness3.description"),
          tags: t("scenarios:pathway.myhappiness3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
        {
          id: "friendsandfamily1",
          type: "smalltext",
          title: t("scenarios:pathway.friendsandfamily1.title"),
          pdfTitle: t("scenarios:pathway.friendsandfamily1.pdftitle"),
          description: t("scenarios:pathway.friendsandfamily1.description"),

          text: "",
        },
        {
          id: "friendsandfamily2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.friendsandfamily2.pdftitle"),
          description: t("scenarios:pathway.friendsandfamily2.description"),
          tags: [],
          text: t("scenarios:pathway.friendsandfamily2.text"),
        },
        {
          id: "friendsandfamily3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.friendsandfamily3.pdftitle"),
          description: t("scenarios:pathway.friendsandfamily3.description"),
          tags: t("scenarios:pathway.friendsandfamily3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
        {
          id: "myhealth1",
          type: "smalltext",
          title: t("scenarios:pathway.myhealth1.title"),
          pdfTitle: t("scenarios:pathway.myhealth1.pdftitle"),
          description: t("scenarios:pathway.myhealth1.description"),

          text: "",
        },
        {
          id: "myhealth2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhealth2.pdftitle"),
          description: t("scenarios:pathway.myhealth2.description"),
          tags: [],
          text: t("scenarios:pathway.myhealth2.text"),
        },
        {
          id: "myhealth3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhealth3.pdftitle"),
          description: t("scenarios:pathway.myhealth3.description"),
          tags: t("scenarios:pathway.myhealth3.tags", { returnObjects: true }),
          text: "",
        },
        {
          id: "mywellbeing1",
          type: "smalltext",
          title: t("scenarios:pathway.mywellbeing1.title"),
          pdfTitle: t("scenarios:pathway.mywellbeing1.pdftitle"),
          description: t("scenarios:pathway.mywellbeing1.description"),

          text: "",
        },
        {
          id: "mywellbeing2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mywellbeing2.pdftitle"),
          description: t("scenarios:pathway.mywellbeing2.description"),
          tags: [],
          text: t("scenarios:pathway.mywellbeing2.text"),
        },
        {
          id: "mywellbeing3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mywellbeing3.pdftitle"),
          description: t("scenarios:pathway.mywellbeing3.description"),
          tags: t("scenarios:pathway.mywellbeing3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
        {
          id: "myeducationandemployment1",
          type: "smalltext",
          title: t("scenarios:pathway.myeducationandemployment1.title"),
          pdfTitle: t("scenarios:pathway.myeducationandemployment1.pdftitle"),
          description: t(
            "scenarios:pathway.myeducationandemployment1.description",
          ),

          text: "",
        },
        {
          id: "myeducationandemployment2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myeducationandemployment2.pdftitle"),
          description: t(
            "scenarios:pathway.myeducationandemployment2.description",
          ),
          tags: [],
          text: t("scenarios:pathway.myeducationandemployment2.text"),
        },
        {
          id: "myeducationandemployment3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myeducationandemployment3.pdftitle"),
          description: t(
            "scenarios:pathway.myeducationandemployment3.description",
          ),
          tags: t("scenarios:pathway.myeducationandemployment3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
        {
          id: "mymoney1",
          type: "smalltext",
          title: t("scenarios:pathway.mymoney1.title"),
          pdfTitle: t("scenarios:pathway.mymoney1.pdftitle"),
          description: t("scenarios:pathway.mymoney1.description"),

          text: "",
        },
        {
          id: "mymoney2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mymoney2.pdftitle"),
          description: t("scenarios:pathway.mymoney2.description"),
          tags: [],
          text: t("scenarios:pathway.mymoney2.text"),
        },
        {
          id: "mymoney3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mymoney3.pdftitle"),
          description: t("scenarios:pathway.mymoney3.description"),
          tags: t("scenarios:pathway.mymoney3.tags", { returnObjects: true }),
          text: "",
        },
        {
          id: "myhome1",
          type: "smalltext",
          title: t("scenarios:pathway.myhome1.title"),
          pdfTitle: t("scenarios:pathway.myhome1.pdftitle"),
          description: t("scenarios:pathway.myhome1.description"),

          text: "",
        },
        {
          id: "myhome2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhome2.pdftitle"),
          description: t("scenarios:pathway.myhome2.description"),
          tags: [],
          text: t("scenarios:pathway.myhome2.text"),
        },
        {
          id: "myhome3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.myhome3.pdftitle"),
          description: t("scenarios:pathway.myhome3.description"),
          tags: t("scenarios:pathway.myhome3.tags", { returnObjects: true }),
          text: "",
        },
        {
          id: "mylifeskills1",
          type: "smalltext",
          title: t("scenarios:pathway.mylifeskills1.title"),
          pdfTitle: t("scenarios:pathway.mylifeskills1.pdftitle"),
          description: t("scenarios:pathway.mylifeskills1.description"),

          text: "",
        },
        {
          id: "mylifeskills2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mylifeskills2.pdftitle"),
          description: t("scenarios:pathway.mylifeskills2.description"),
          tags: [],
          text: t("scenarios:pathway.mylifeskills2.text"),
        },
        {
          id: "mylifeskills3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.mylifeskills3.pdftitle"),
          description: t("scenarios:pathway.mylifeskills3.description"),
          tags: t("scenarios:pathway.mylifeskills3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
        {
          id: "othersupport1",
          type: "smalltext",
          title: t("scenarios:pathway.othersupport1.title"),
          pdfTitle: t("scenarios:pathway.othersupport1.pdftitle"),
          description: t("scenarios:pathway.othersupport1.description"),

          text: "",
        },
        {
          id: "othersupport2",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.othersupport2.pdftitle"),
          description: t("scenarios:pathway.othersupport2.description"),
          tags: [],
          text: t("scenarios:pathway.othersupport2.text"),
        },
        {
          id: "othersupport3",
          type: "textarea",
          pdfTitle: t("scenarios:pathway.othersupport3.pdftitle"),
          description: t("scenarios:pathway.othersupport3.description"),
          tags: t("scenarios:pathway.othersupport3.tags", {
            returnObjects: true,
          }),
          text: "",
        },
      ],
    },

    {
      id: "foster-care-review",
      title: t("scenarios:foster-care-review.title"),
      titlePastTense: t("scenarios:foster-care-review.title-past-tense"),
      description: t("scenarios:foster-care-review.description"),
      slug: "foster-care-review",
      image: t("images:review_stars.src"),
      pdfOrder: [
        "feeling",
        "getonwithcarers",
        "getonwithcarerschildren",
        "feellivingatcarers",
        "goodlivingwithcarers",
        "notgoodlivingwithcarers",
        "anythingelse",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:shared.feeling.title"),
          pdfTitle: t("scenarios:shared.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "getonwithcarers",
          type: "checkboxestext",
          title: t("scenarios:foster-care-review.getonwithcarers.title"),
          pdfTitle: t("scenarios:foster-care-review.getonwithcarers.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option7.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option8.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option8.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarers.option9.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarers.option9.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "getonwithcarerschildren",
          type: "checkboxestext",
          title: t(
            "scenarios:foster-care-review.getonwithcarerschildren.title",
          ),
          pdfTitle: t(
            "scenarios:foster-care-review.getonwithcarerschildren.pdftitle",
          ),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.getonwithcarerschildren.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.getonwithcarerschildren.option6.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "feellivingatcarers",
          type: "checkboxestext",
          title: t("scenarios:foster-care-review.feellivingatcarers.title"),
          pdfTitle: t(
            "scenarios:foster-care-review.feellivingatcarers.pdftitle",
          ),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option7.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option8.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option8.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option9.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option9.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option10.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option10.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:foster-care-review.feellivingatcarers.option11.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:foster-care-review.feellivingatcarers.option11.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "goodlivingwithcarers",
          type: "checkboxestext",
          title: t("scenarios:foster-care-review.goodlivingwithcarers.title"),
          pdfTitle: t(
            "scenarios:foster-care-review.goodlivingwithcarers.pdftitle",
          ),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:shared.livingwithcarers.option1.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option2.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option3.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option4.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option5.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option6.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option7.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option8.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option9.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option10.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option11.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option12.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "notgoodlivingwithcarers",
          type: "checkboxestext",
          title: t(
            "scenarios:foster-care-review.notgoodlivingwithcarers.title",
          ),
          pdfTitle: t(
            "scenarios:foster-care-review.notgoodlivingwithcarers.pdftitle",
          ),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:shared.livingwithcarers.option1.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option2.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option3.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option4.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option5.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option6.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option7.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option8.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option9.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option10.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option11.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:shared.livingwithcarers.option12.name"),
              image: {
                src: t(
                  t("scenarios:shared.livingwithcarers.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "anythingelse",
          type: "textarea",
          title: t("scenarios:foster-care-review.anythingelse.title"),
          pdfTitle: t("scenarios:foster-care-review.anythingelse.pdftitle"),
          description: t(
            "scenarios:foster-care-review.anythingelse.description",
          ),
          tags: [],
          text: "",
        },
      ],
    },

    {
      id: "post-meeting",
      title: t("scenarios:post-meeting.title"),
      titlePastTense: t("scenarios:post-meeting.title-past-tense"),
      description: t("scenarios:post-meeting.description"),
      slug: "post-meeting",
      image: t("images:worker.src"),
      pdfOrder: [
        "whatwasgood",
        "whatwasntgood",
        "howdidyoufeel",
        "morethoughts",
      ],
      questions: [
        {
          id: "whatwasgood",
          type: "textarea",
          title: t("scenarios:post-meeting.whatwasgood.title"),
          pdfTitle: t("scenarios:post-meeting.whatwasgood.pdftitle"),
          description: t("scenarios:post-meeting.whatwasgood.description"),
          placeholder: t("scenarios:post-meeting.whatwasgood.placeholder"),
          tags: [],
          text: "",
        },
        {
          id: "whatwasntgood",
          type: "textarea",
          title: t("scenarios:post-meeting.whatwasntgood.title"),
          pdfTitle: t("scenarios:post-meeting.whatwasntgood.pdftitle"),
          description: t("scenarios:post-meeting.whatwasntgood.description"),
          placeholder: t("scenarios:post-meeting.whatwasntgood.placeholder"),
          tags: [],
          text: "",
        },
        {
          id: "howdidyoufeel",
          type: "radiofixed",
          title: t("scenarios:post-meeting.howdidyoufeel.title"),
          pdfTitle: t("scenarios:post-meeting.howdidyoufeel.pdftitle"),
          description: t("scenarios:post-meeting.howdidyoufeel.description"),
          singleChoice: true,
          radioOptions: [
            { name: t("scenarios:post-meeting.howdidyoufeel.option1") },
            { name: t("scenarios:post-meeting.howdidyoufeel.option2") },
            { name: t("scenarios:post-meeting.howdidyoufeel.option3") },
          ],
        },
        {
          id: "morethoughts",
          type: "textarea",
          title: t("scenarios:post-meeting.morethoughts.title"),
          pdfTitle: t("scenarios:post-meeting.morethoughts.pdftitle"),
          description: t("scenarios:post-meeting.morethoughts.description"),
          placeholder: t("scenarios:post-meeting.morethoughts.placeholder"),
          tags: [],
          text: "",
        },
      ],
    },

    {
      id: "my-education",
      title: t("scenarios:my-education.title"),
      titlePastTense: t("scenarios:my-education.title-past-tense"),
      description: t("scenarios:my-education.description"),
      slug: "my-education",
      image: t("images:homework.src"),
      pdfOrder: [
        "feeling",
        "goingwell",
        "notgoingwell",
        "speakto",
        "goodat",
        "improveat",
        "proudof",
        "change",
        "help",
        "feelaboutfuture",
        "hobbies",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:my-education.howsitgoing.title"),
          pdfTitle: t("scenarios:my-education.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:my-education.feeling.title"),
          pdfTitle: t("scenarios:my-education.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "goingwell",
          type: "checkboxestext",
          title: t("scenarios:my-education.goingwell.title"),
          pdfTitle: t("scenarios:my-education.goingwell.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-education.goingwell.option1.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option2.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option3.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option4.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option5.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option5.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option6.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option6.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option7.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option7.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option8.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option8.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option9.name"),
              image: {
                src: t(t("scenarios:my-education.goingwell.option9.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option10.name"),
              image: {
                src: t(
                  t("scenarios:my-education.goingwell.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option11.name"),
              image: {
                src: t(
                  t("scenarios:my-education.goingwell.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.goingwell.option12.name"),
              image: {
                src: t(
                  t("scenarios:my-education.goingwell.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "notgoingwell",
          type: "checkboxestext",
          title: t("scenarios:my-education.notgoingwell.title"),
          pdfTitle: t("scenarios:my-education.notgoingwell.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-education.notgoingwell.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option10.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option11.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.notgoingwell.option12.name"),
              image: {
                src: t(
                  t("scenarios:my-education.notgoingwell.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "speakto",
          type: "radioplus",
          title: t("scenarios:my-education.speakto.title"),
          pdfTitle: t("scenarios:my-education.speakto.pdftitle"),
          description: t("scenarios:my-education.speakto.description"),
          radioOptions: t("scenarios:my-education.speakto.radioOptions", {
            returnObjects: true,
          }),
        },
        {
          id: "goodat",
          type: "checkboxestext",
          title: t("scenarios:my-education.goodat.title"),
          pdfTitle: t("scenarios:my-education.goodat.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: translateCheckboxes(
            "scenarios:my-education.goodat.checkboxes",
          ),
        },
        {
          id: "improveat",
          type: "checkboxestext",
          title: t("scenarios:my-education.improveat.title"),
          pdfTitle: t("scenarios:my-education.improveat.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: translateCheckboxes(
            "scenarios:my-education.improveat.checkboxes",
          ),
        },
        {
          id: "proudof",
          type: "smalltext",
          title: t("scenarios:my-education.proudof.title"),
          pdfTitle: t("scenarios:my-education.proudof.pdftitle"),
          description: t("scenarios:my-education.proudof.description"),
          placeholder: t("scenarios:my-education.proudof.placeholder"),
          text: "",
        },
        {
          id: "change",
          type: "checkboxestext",
          title: t("scenarios:my-education.change.title"),
          pdfTitle: t("scenarios:my-education.change.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-education.change.option1.name"),
              image: {
                src: t(t("scenarios:my-education.change.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option2.name"),
              image: {
                src: t(t("scenarios:my-education.change.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option3.name"),
              image: {
                src: t(t("scenarios:my-education.change.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option4.name"),
              image: {
                src: t(t("scenarios:my-education.change.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option5.name"),
              image: {
                src: t(t("scenarios:my-education.change.option5.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option6.name"),
              image: {
                src: t(t("scenarios:my-education.change.option6.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option7.name"),
              image: {
                src: t(t("scenarios:my-education.change.option7.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.change.option8.name"),
              image: {
                src: t(t("scenarios:my-education.change.option8.image.src")),
              },
            },
          ],
        },
        {
          id: "help",
          type: "checkboxestext",
          title: t("scenarios:my-education.help.title"),
          pdfTitle: t("scenarios:my-education.help.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-education.help.option1.name"),
              image: {
                src: t(t("scenarios:my-education.help.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.help.option2.name"),
              image: {
                src: t(t("scenarios:my-education.help.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.help.option3.name"),
              image: {
                src: t(t("scenarios:my-education.help.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.help.option4.name"),
              image: {
                src: t(t("scenarios:my-education.help.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.help.option5.name"),
              image: {
                src: t(t("scenarios:my-education.help.option5.image.src")),
              },
            },
          ],
        },
        {
          id: "feelaboutfuture",
          type: "checkboxestext",
          title: t("scenarios:my-education.feelaboutfuture.title"),
          pdfTitle: t("scenarios:my-education.feelaboutfuture.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-education.feelaboutfuture.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-education.feelaboutfuture.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.feelaboutfuture.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-education.feelaboutfuture.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.feelaboutfuture.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-education.feelaboutfuture.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.feelaboutfuture.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-education.feelaboutfuture.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-education.feelaboutfuture.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-education.feelaboutfuture.option5.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "hobbies",
          type: "checkboxes",
          title: t("scenarios:my-education.hobbies.title"),
          pdfTitle: t("scenarios:my-education.hobbies.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: [
            {
              name: t("scenarios:my-education.hobbies.option1.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option2.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option3.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option4.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option5.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option5.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option6.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option6.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option7.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option7.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option8.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option8.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option9.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option9.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option10.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option10.image.src")),
              },
            },
            {
              name: t("scenarios:my-education.hobbies.option11.name"),
              image: {
                src: t(t("scenarios:my-education.hobbies.option11.image.src")),
              },
            },
          ],
        },
      ],
    },

    {
      id: "my-wellbeing",
      title: t("scenarios:my-wellbeing.title"),
      titlePastTense: t("scenarios:my-wellbeing.title-past-tense"),
      description: t("scenarios:my-wellbeing.description"),
      slug: "my-wellbeing",
      image: t("images:health_heart.src"),
      pdfOrder: [
        "feeling",
        "happyabout",
        "sadabout",
        "goinglately",
        "peopleathome",
        "copewhennotgood",
        "getonwithfriends",
        "haveinlife",
        "wheresafe",
        "localarea",
        "feelaboutself",
        "tellanadult",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:my-wellbeing.feeling.title"),
          pdfTitle: t("scenarios:my-wellbeing.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "happyabout",
          type: "checkboxestext",
          title: t("scenarios:my-wellbeing.happyabout.title"),
          pdfTitle: t("scenarios:my-wellbeing.happyabout.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.happyabout.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option10.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option11.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option12.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option12.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option13.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option13.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option14.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option14.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option15.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option15.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.happyabout.option16.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.happyabout.option16.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "sadabout",
          type: "checkboxestext",
          title: t("scenarios:my-wellbeing.sadabout.title"),
          pdfTitle: t("scenarios:my-wellbeing.sadabout.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.sadabout.option1.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option2.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option3.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option4.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option5.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option5.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option6.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option6.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option7.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option7.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option8.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option8.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option9.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option9.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option10.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option10.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option11.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option11.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option12.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option12.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option13.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option13.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option14.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option14.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option15.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option15.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.sadabout.option16.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.sadabout.option16.image.src")),
              },
            },
          ],
        },
        {
          id: "goinglately",
          type: "checkboxes",
          title: t("scenarios:my-wellbeing.goinglately.title"),
          pdfTitle: t("scenarios:my-wellbeing.goinglately.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.goinglately.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option10.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option11.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.goinglately.option12.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.goinglately.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "peopleathome",
          type: "checkboxes",
          title: t("scenarios:shared.peopleathome.title"),
          pdfTitle: t("scenarios:shared.peopleathome.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: peopleathomeCheckboxes,
        },
        {
          id: "copewhennotgood",
          type: "checkboxestext",
          title: t("scenarios:my-wellbeing.copewhennotgood.title"),
          pdfTitle: t("scenarios:my-wellbeing.copewhennotgood.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.copewhennotgood.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.copewhennotgood.option9.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "getonwithfriends",
          type: "checkboxes",
          title: t("scenarios:shared.getonwithfriends.title"),
          pdfTitle: t("scenarios:shared.getonwithfriends.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: getOnWithFriendsCheckboxes,
        },
        {
          id: "haveinlife",
          type: "checkboxes",
          title: t("scenarios:shared.haveinlife.title"),
          pdfTitle: t("scenarios:shared.haveinlife.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: haveInLifeCheckboxes,
        },
        {
          id: "wheresafe",
          type: "checkboxestext",
          title: t("scenarios:my-wellbeing.wheresafe.title"),
          pdfTitle: t("scenarios:my-wellbeing.wheresafe.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.wheresafe.option1.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option1.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option2.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option2.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option3.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option3.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option4.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option4.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option5.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option5.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option6.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option6.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option7.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option7.image.src")),
              },
            },
            {
              name: t("scenarios:my-wellbeing.wheresafe.option8.name"),
              image: {
                src: t(t("scenarios:my-wellbeing.wheresafe.option8.image.src")),
              },
            },
          ],
        },
        {
          id: "localarea",
          type: "checkboxes",
          title: t("scenarios:shared.localarea.title"),
          pdfTitle: t("scenarios:shared.localarea.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: localAreaCheckboxes,
        },
        {
          id: "feelaboutself",
          type: "checkboxes",
          title: t("scenarios:my-wellbeing.feelaboutself.title"),
          pdfTitle: t("scenarios:my-wellbeing.feelaboutself.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: [
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option10.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option11.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-wellbeing.feelaboutself.option12.name"),
              image: {
                src: t(
                  t("scenarios:my-wellbeing.feelaboutself.option12.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "tellanadult",
          type: "smalltext",
          title: t("scenarios:shared.tellanadult.title"),
          pdfTitle: t("scenarios:shared.tellanadult.pdftitle"),
          description: t("scenarios:shared.tellanadult.description"),
        },
      ],
    },

    {
      id: "return-from-missing",
      title: t("scenarios:return-from-missing.title"),
      titlePastTense: t("scenarios:return-from-missing.title-past-tense"),
      description: t("scenarios:return-from-missing.description"),
      slug: "return-from-missing",
      image: t("images:return.src"),
      pdfOrder: [
        "feeling",
        "getonwithfriends",
        "whowith",
        "wherego",
        "missed",
        "howsafe",
        "whatdo",
        "haveinlife",
        "peopleathome",
        "localarea",
        "stopyougoing",
        "tellanadult",
      ],
      questions: [
        {
          id: "howsitgoing",
          type: "slider",
          title: t("scenarios:shared.howsitgoing.title"),
          pdfTitle: t("scenarios:shared.howsitgoing.pdftitle"),
          description: t("scenarios:shared.howsitgoing.description"),
          hint: t("scenarios:shared.howsitgoing.hint"),
          positions: howsItGoingPositions,
        },
        {
          id: "feeling",
          type: "checkboxes",
          title: t("scenarios:return-from-missing.feeling.title"),
          pdfTitle: t("scenarios:return-from-missing.feeling.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: feelingCheckboxes,
        },
        {
          id: "getonwithfriends",
          type: "checkboxes",
          title: t("scenarios:shared.getonwithfriends.title"),
          pdfTitle: t("scenarios:shared.getonwithfriends.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: getOnWithFriendsCheckboxes,
        },
        {
          id: "whowith",
          type: "radioplus",
          title: t("scenarios:return-from-missing.whowith.title"),
          pdfTitle: t("scenarios:return-from-missing.whowith.pdftitle"),
          description: t("scenarios:return-from-missing.whowith.description"),
          radioOptions: [
            { name: t("scenarios:return-from-missing.whowith.option1") },
            { name: t("scenarios:return-from-missing.whowith.option2") },
            { name: t("scenarios:return-from-missing.whowith.option3") },
            { name: t("scenarios:return-from-missing.whowith.option4") },
            { name: t("scenarios:return-from-missing.whowith.option5") },
            { name: t("scenarios:return-from-missing.whowith.option6") },
            { name: t("scenarios:return-from-missing.whowith.option7") },
          ],
        },
        {
          id: "wherego",
          type: "smalltext",
          title: t("scenarios:return-from-missing.wherego.title"),
          pdfTitle: t("scenarios:return-from-missing.wherego.pdftitle"),
          description: t("scenarios:return-from-missing.wherego.description"),
        },
        {
          id: "missed",
          type: "checkboxes",
          title: t("scenarios:return-from-missing.missed.title"),
          pdfTitle: t("scenarios:return-from-missing.missed.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: [
            {
              name: t("scenarios:return-from-missing.missed.option1.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option2.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option3.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option4.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option5.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option6.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option7.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.missed.option8.name"),
              image: {
                src: t(
                  t("scenarios:return-from-missing.missed.option8.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "howsafe",
          type: "slider",
          title: t("scenarios:return-from-missing.howsafe.title"),
          pdfTitle: t("scenarios:return-from-missing.howsafe.pdftitle"),
          description: t("scenarios:return-from-missing.howsafe.description"),
          hint: t("scenarios:return-from-missing.howsafe.hint"),
          positions: [
            {
              name: t("scenarios:return-from-missing.howsafe.position1.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position1.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position1.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position1.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position2.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position2.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position2.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position2.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position3.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position3.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position3.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position3.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position4.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position4.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position4.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position4.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position5.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position5.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position5.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position5.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position6.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position6.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position6.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position6.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position7.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position7.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position7.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position7.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position8.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position8.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position8.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position8.imageOlderYP.src"),
                ),
              },
            },
            {
              name: t("scenarios:return-from-missing.howsafe.position9.name"),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.howsafe.position9.image.src",
                  ),
                ),
              },
              imageOlderYP: {
                alt: t(
                  t("scenarios:shared.howsitgoing.position9.imageOlderYP.alt"),
                ),
                src: t(
                  t("scenarios:shared.howsitgoing.position9.imageOlderYP.src"),
                ),
              },
            },
          ],
        },
        {
          id: "whatdo",
          type: "smalltext",
          title: t("scenarios:return-from-missing.whatdo.title"),
          pdfTitle: t("scenarios:return-from-missing.whatdo.pdftitle"),
          description: t("scenarios:return-from-missing.whatdo.description"),
        },
        {
          id: "haveinlife",
          type: "checkboxes",
          title: t("scenarios:shared.haveinlife.title"),
          pdfTitle: t("scenarios:shared.haveinlife.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: haveInLifeCheckboxes,
        },
        {
          id: "peopleathome",
          type: "checkboxes",
          title: t("scenarios:shared.peopleathome.title"),
          pdfTitle: t("scenarios:shared.peopleathome.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: peopleathomeCheckboxes,
        },
        {
          id: "localarea",
          type: "checkboxes",
          title: t("scenarios:shared.localarea.title"),
          pdfTitle: t("scenarios:shared.localarea.pdftitle"),
          description: t("pages:scenario.checkboxes"),
          checkboxes: localAreaCheckboxes,
        },
        {
          id: "stopyougoing",
          type: "checkboxestext",
          title: t("scenarios:return-from-missing.stopyougoing.title"),
          pdfTitle: t("scenarios:return-from-missing.stopyougoing.pdftitle"),
          description: t("pages:scenario.checkboxestext.description"),
          checkboxes: [
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option7.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option8.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option8.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:return-from-missing.stopyougoing.option9.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:return-from-missing.stopyougoing.option9.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "tellanadult",
          type: "smalltext",
          title: t("scenarios:shared.tellanadult.title"),
          pdfTitle: t("scenarios:shared.tellanadult.pdftitle"),
          description: t("scenarios:shared.tellanadult.description"),
        },
      ],
    },

    {
      id: "this-is-me",
      title: t("scenarios:this-is-me.title"),
      titlePastTense: t("scenarios:this-is-me.title"),
      description: "",
      slug: "this-is-me",
      image: t("images:this_is_me.src"),
      pdfOrder: [
        "likeToBeCalled",
        "myPronouns",
        "myEthnicity",
        "myIdentity",
        "makeMeFeelGood",
        "makeMeFeelBad",
        "myRelationshipPreference",
        "theseMakeMeAngry",
        "myDreamsForFuture",
        "aLotOfTheTimeIFeel",
        "thingsILikeToDo",
        "whereIFeelSafe",
        "bestWayToHelpMe",
        "peopleLikeAboutMe",
        "oneThingToKnowAboutMe",
      ],
      questions: [
        {
          id: "likeToBeCalled",
          type: "smalltext",
          title: t("scenarios:this-is-me.likeToBeCalled.title"),
          pdfTitle: t("scenarios:this-is-me.likeToBeCalled.title"),
        },
        {
          id: "myPronouns",
          type: "radioplus",
          title: t("scenarios:this-is-me.myPronouns.title"),
          pdfTitle: t("scenarios:this-is-me.myPronouns.title"),
          radioOptions: [
            { name: t("scenarios:this-is-me.myPronouns.option1") },
            { name: t("scenarios:this-is-me.myPronouns.option2") },
            { name: t("scenarios:this-is-me.myPronouns.option3") },
          ],
        },
        {
          id: "myEthnicity",
          type: "textarea",
          title: t("scenarios:this-is-me.myEthnicity.title"),
          pdfTitle: t("scenarios:this-is-me.myEthnicity.title"),
          tags: [],
        },
        {
          id: "myIdentity",
          type: "checkboxes",
          title: t("scenarios:this-is-me.myIdentity.title"),
          pdfTitle: t("scenarios:this-is-me.myIdentity.title"),
          ageGuard: 13,
          checkboxes: translateCheckboxes(
            "scenarios:this-is-me.myIdentity.checkboxes",
          ),
        },
        {
          id: "makeMeFeelGood",
          type: "checkboxestext",
          title: t("scenarios:this-is-me.makeMeFeelGood.title"),
          pdfTitle: t("scenarios:this-is-me.makeMeFeelGood.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option8.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option9.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option10.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option11.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option12.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option12.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option13.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option13.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelGood.option14.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelGood.option14.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "makeMeFeelBad",
          type: "checkboxestext",
          title: t("scenarios:this-is-me.makeMeFeelBad.title"),
          pdfTitle: t("scenarios:this-is-me.makeMeFeelBad.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option8.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option9.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option10.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.makeMeFeelBad.option11.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.makeMeFeelBad.option11.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "myRelationshipPreference",
          type: "checkboxes",
          title: t("scenarios:this-is-me.myRelationshipPreference.title"),
          pdfTitle: t("scenarios:this-is-me.myRelationshipPreference.title"),
          ageGuard: 13,
          checkboxes: [
            {
              name: t(
                "scenarios:this-is-me.myRelationshipPreference.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.myRelationshipPreference.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:this-is-me.myRelationshipPreference.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.myRelationshipPreference.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:this-is-me.myRelationshipPreference.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.myRelationshipPreference.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:this-is-me.myRelationshipPreference.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.myRelationshipPreference.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:this-is-me.myRelationshipPreference.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.myRelationshipPreference.option5.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "theseMakeMeAngry",
          type: "checkboxestext",
          title: t("scenarios:this-is-me.theseMakeMeAngry.title"),
          pdfTitle: t("scenarios:this-is-me.theseMakeMeAngry.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option8.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.theseMakeMeAngry.option9.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.theseMakeMeAngry.option9.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "myDreamsForFuture",
          type: "textarea",
          title: t("scenarios:this-is-me.myDreamsForFuture.title"),
          pdfTitle: t("scenarios:this-is-me.myDreamsForFuture.title"),
          tags: [],
        },
        {
          id: "aLotOfTheTimeIFeel",
          type: "checkboxes",
          title: t("scenarios:this-is-me.aLotOfTheTimeIFeel.title"),
          pdfTitle: t("scenarios:this-is-me.aLotOfTheTimeIFeel.title"),
          iconSize: "small",
          checkboxes: [
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option1.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option2.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option3.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option4.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option5.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option6.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option7.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option8.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option8.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option9.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option9.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option10.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option10.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option11.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option11.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.aLotOfTheTimeIFeel.option12.name"),
              image: {
                src: t(
                  t(
                    "scenarios:this-is-me.aLotOfTheTimeIFeel.option12.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "thingsILikeToDo",
          type: "checkboxestext",
          title: t("scenarios:this-is-me.thingsILikeToDo.title"),
          pdfTitle: t("scenarios:this-is-me.thingsILikeToDo.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option8.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option9.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option9.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option10.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option10.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option11.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option11.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option12.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option12.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.thingsILikeToDo.option13.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.thingsILikeToDo.option13.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "whereIFeelSafe",
          type: "checkboxes",
          title: t("scenarios:this-is-me.whereIFeelSafe.title"),
          pdfTitle: t("scenarios:this-is-me.whereIFeelSafe.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.whereIFeelSafe.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.whereIFeelSafe.option7.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "bestWayToHelpMe",
          type: "checkboxestext",
          title: t("scenarios:this-is-me.bestWayToHelpMe.title"),
          pdfTitle: t("scenarios:this-is-me.bestWayToHelpMe.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.bestWayToHelpMe.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.bestWayToHelpMe.option7.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "peopleLikeAboutMe",
          type: "checkboxes",
          title: t("scenarios:this-is-me.peopleLikeAboutMe.title"),
          pdfTitle: t("scenarios:this-is-me.peopleLikeAboutMe.title"),
          checkboxes: [
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option1.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option2.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option3.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option4.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option5.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option6.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option7.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option8.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:this-is-me.peopleLikeAboutMe.option9.name"),
              image: {
                src: t(
                  t("scenarios:this-is-me.peopleLikeAboutMe.option9.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "oneThingToKnowAboutMe",
          type: "textarea",
          title: t("scenarios:this-is-me.oneThingToKnowAboutMe.title"),
          pdfTitle: t("scenarios:this-is-me.oneThingToKnowAboutMe.title"),
          tags: [],
        },
      ],
    },
    {
      id: "my-world",
      title: t("scenarios:my-world.title"),
      titlePastTense: t("scenarios:my-world.title"),
      description: t("scenarios:my-world.description"),
      slug: "my-world",
      image: t("images:my_world.src"),
      questions: [
        {
          id: "howIFeelAtHome",
          type: "sliderGroup",
          title: t("scenarios:my-world.howIFeelAtHome.title"),
          pdfTitle: t("scenarios:my-world.howIFeelAtHome.title"),
          subQuestions: [
            {
              title: t("scenarios:my-world.howIFeelAtHome.subQuestion1.title"),
              image: {
                src: t(
                  t("scenarios:my-world.howIFeelAtHome.subQuestion1.image.src"),
                ),
              },
            },
            {
              title: t("scenarios:my-world.howIFeelAtHome.subQuestion2.title"),
              image: {
                src: t(
                  t("scenarios:my-world.howIFeelAtHome.subQuestion2.image.src"),
                ),
              },
            },
            {
              title: t("scenarios:my-world.howIFeelAtHome.subQuestion3.title"),
              image: {
                src: t(
                  t("scenarios:my-world.howIFeelAtHome.subQuestion3.image.src"),
                ),
              },
            },
            {
              title: t("scenarios:my-world.howIFeelAtHome.subQuestion4.title"),
              image: {
                src: t(
                  t("scenarios:my-world.howIFeelAtHome.subQuestion4.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "whatHappensInMyFamily",
          type: "checkboxestext",
          title: t("scenarios:my-world.whatHappensInMyFamily.title"),
          pdfTitle: t("scenarios:my-world.whatHappensInMyFamily.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.whatHappensInMyFamily.option1.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatHappensInMyFamily.option1.image.src",
                  ),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInMyFamily.option1.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInMyFamily.option2.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatHappensInMyFamily.option2.image.src",
                  ),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInMyFamily.option2.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInMyFamily.option3.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatHappensInMyFamily.option3.image.src",
                  ),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInMyFamily.option3.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInMyFamily.option4.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatHappensInMyFamily.option4.image.src",
                  ),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInMyFamily.option4.tags", {
                returnObjects: true,
              }),
            },
          ],
        },
        {
          id: "importantPeopleInMyLife",
          type: "textarea",
          title: t("scenarios:my-world.importantPeopleInMyLife.title"),
          pdfTitle: t("scenarios:my-world.importantPeopleInMyLife.title"),
          tags: [],
        },
        {
          id: "whoITellFirst",
          type: "textarea",
          title: t("scenarios:my-world.whoITellFirst.title"),
          pdfTitle: t("scenarios:my-world.whoITellFirst.title"),
          tags: [],
        },
        {
          id: "iMissThisPerson",
          type: "textarea",
          title: t("scenarios:my-world.iMissThisPerson.title"),
          pdfTitle: t("scenarios:my-world.iMissThisPerson.title"),
          tags: [],
        },
        {
          id: "whatMyFriendsAreLike",
          type: "checkboxes",
          title: t("scenarios:my-world.whatMyFriendsAreLike.title"),
          pdfTitle: t("scenarios:my-world.whatMyFriendsAreLike.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option1.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option2.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option3.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option4.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option5.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option6.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option7.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option8.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option8.image.src",
                  ),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsAreLike.option9.name"),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.whatMyFriendsAreLike.option9.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "whenImWithMyFriends",
          type: "checkboxestext",
          title: t("scenarios:my-world.whenImWithMyFriends.title"),
          pdfTitle: t("scenarios:my-world.whenImWithMyFriends.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.whenImWithMyFriends.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whenImWithMyFriends.option1.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whenImWithMyFriends.option1.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whenImWithMyFriends.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whenImWithMyFriends.option2.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whenImWithMyFriends.option2.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whenImWithMyFriends.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whenImWithMyFriends.option3.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whenImWithMyFriends.option3.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whenImWithMyFriends.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whenImWithMyFriends.option4.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whenImWithMyFriends.option4.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whenImWithMyFriends.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whenImWithMyFriends.option5.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whenImWithMyFriends.option5.tags", {
                returnObjects: true,
              }),
            },
          ],
        },
        {
          id: "whatMyFriendsThink",
          type: "checkboxes",
          title: t("scenarios:my-world.whatMyFriendsThink.title"),
          pdfTitle: t("scenarios:my-world.whatMyFriendsThink.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option8.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.whatMyFriendsThink.option9.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatMyFriendsThink.option9.image.src"),
                ),
              },
            },
          ],
        },
        {
          id: "howIFeelAboutSchool",
          type: "sliderGroup",
          title: t("scenarios:my-world.howIFeelAboutSchool.title"),
          pdfTitle: t("scenarios:my-world.howIFeelAboutSchool.title"),
          subQuestions: [
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion1.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion1.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion2.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion2.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion3.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion3.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion4.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion4.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion5.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion5.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutSchool.subQuestion6.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutSchool.subQuestion6.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "whatHappensInSchool",
          type: "checkboxestext",
          title: t("scenarios:my-world.whatHappensInSchool.title"),
          pdfTitle: t("scenarios:my-world.whatHappensInSchool.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.whatHappensInSchool.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option1.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option1.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInSchool.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option2.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option2.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInSchool.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option3.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option3.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInSchool.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option4.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option4.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInSchool.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option5.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option5.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.whatHappensInSchool.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-world.whatHappensInSchool.option6.image.src"),
                ),
              },
              tags: t("scenarios:my-world.whatHappensInSchool.option6.tags", {
                returnObjects: true,
              }),
            },
          ],
        },
        {
          id: "howIFeelAboutMyHealth",
          type: "sliderGroup",
          title: t("scenarios:my-world.howIFeelAboutMyHealth.title"),
          pdfTitle: t("scenarios:my-world.howIFeelAboutMyHealth.title"),
          subQuestions: [
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion1.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion1.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion2.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion2.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion3.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion3.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion4.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion4.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion5.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion5.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion6.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion6.image.src",
                  ),
                ),
              },
            },
            {
              title: t(
                "scenarios:my-world.howIFeelAboutMyHealth.subQuestion7.title",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:my-world.howIFeelAboutMyHealth.subQuestion7.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "myMoodRightNow",
          type: "checkboxestext",
          title: t("scenarios:my-world.myMoodRightNow.title"),
          pdfTitle: t("scenarios:my-world.myMoodRightNow.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.myMoodRightNow.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option1.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option1.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option2.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option2.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option3.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option3.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option4.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option4.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option5.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option5.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option6.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option6.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option7.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option7.tags", {
                returnObjects: true,
              }),
            },
            {
              name: t("scenarios:my-world.myMoodRightNow.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-world.myMoodRightNow.option8.image.src"),
                ),
              },
              tags: t("scenarios:my-world.myMoodRightNow.option8.tags", {
                returnObjects: true,
              }),
            },
          ],
        },
        {
          id: "howFamilyDescribeMe",
          type: "checkboxes",
          title: t("scenarios:my-world.howFamilyDescribeMe.title"),
          pdfTitle: t("scenarios:my-world.howFamilyDescribeMe.title"),
          checkboxes: [
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option1.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option1.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option2.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option2.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option3.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option3.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option4.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option4.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option5.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option5.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option6.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option6.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option7.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option7.image.src"),
                ),
              },
            },
            {
              name: t("scenarios:my-world.howFamilyDescribeMe.option8.name"),
              image: {
                src: t(
                  t("scenarios:my-world.howFamilyDescribeMe.option8.image.src"),
                ),
              },
            },
          ],
        },
      ],
    },
    {
      id: "youth-justice-and-me",
      title: t("scenarios:youth-justice-and-me.title"),
      titlePastTense: t("scenarios:youth-justice-and-me.title"),
      description: t("scenarios:youth-justice-and-me.description"),
      slug: "youth-justice-and-me",
      image: t("images:working_with_youth_justice.src"),
      questions: [
        {
          id: "whenICommittedOffence",
          type: "checkboxes",
          title: t(
            "scenarios:youth-justice-and-me.whenICommittedOffence.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.whenICommittedOffence.title",
          ),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option7.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option7.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whenICommittedOffence.option8.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whenICommittedOffence.option8.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "myBehaviourCausedProblems",
          type: "textarea",
          title: t(
            "scenarios:youth-justice-and-me.myBehaviourCausedProblems.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.myBehaviourCausedProblems.title",
          ),
          description: t(
            "scenarios:youth-justice-and-me.myBehaviourCausedProblems.description",
          ),
          tags: [
            {
              name: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option1.name",
              ),
              fragment: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option1.fragment",
              ),
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option2.name",
              ),
              fragment: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option2.fragment",
              ),
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option3.name",
              ),
              fragment: t(
                "scenarios:youth-justice-and-me.myBehaviourCausedProblems.option3.fragment",
              ),
            },
          ],
        },
        {
          id: "whatICanDoToMakeUpForIt",
          type: "textarea",
          title: t(
            "scenarios:youth-justice-and-me.whatICanDoToMakeUpForIt.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.whatICanDoToMakeUpForIt.title",
          ),
          tags: [],
        },
        {
          id: "hopesAndAmbitions",
          type: "checkboxes",
          title: t("scenarios:youth-justice-and-me.hopesAndAmbitions.title"),
          pdfTitle: t("scenarios:youth-justice-and-me.hopesAndAmbitions.title"),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.hopesAndAmbitions.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.hopesAndAmbitions.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.hopesAndAmbitions.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.hopesAndAmbitions.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.hopesAndAmbitions.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.hopesAndAmbitions.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.hopesAndAmbitions.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.hopesAndAmbitions.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.hopesAndAmbitions.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.hopesAndAmbitions.option5.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "stopHopesAndAmbitions",
          type: "checkboxes",
          title: t(
            "scenarios:youth-justice-and-me.stopHopesAndAmbitions.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.stopHopesAndAmbitions.title",
          ),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option6.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option7.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.stopHopesAndAmbitions.option7.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "howIFeelAboutFuture",
          type: "checkboxes",
          title: t("scenarios:youth-justice-and-me.howIFeelAboutFuture.title"),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.howIFeelAboutFuture.title",
          ),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option5.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutFuture.option6.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutFuture.option6.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "thingIWantToChange",
          type: "textarea",
          title: t("scenarios:youth-justice-and-me.thingIWantToChange.title"),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.thingIWantToChange.title",
          ),
          tags: [],
        },
        {
          id: "howIFeelAboutYouthJustice",
          type: "checkboxestext",
          title: t(
            "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.title",
          ),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option1.image.src",
                  ),
                ),
              },
              tags: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option1.tags",
                {
                  returnObjects: true,
                },
              ),
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option2.image.src",
                  ),
                ),
              },
              tags: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option2.tags",
                {
                  returnObjects: true,
                },
              ),
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option3.image.src",
                  ),
                ),
              },
              tags: t(
                "scenarios:youth-justice-and-me.howIFeelAboutYouthJustice.option3.tags",
                {
                  returnObjects: true,
                },
              ),
            },
          ],
        },
        {
          id: "whatHasBeenGood",
          type: "checkboxes",
          title: t("scenarios:youth-justice-and-me.whatHasBeenGood.title"),
          pdfTitle: t("scenarios:youth-justice-and-me.whatHasBeenGood.title"),
          checkboxes: [
            {
              name: t(
                "scenarios:youth-justice-and-me.whatHasBeenGood.option1.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whatHasBeenGood.option1.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whatHasBeenGood.option2.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whatHasBeenGood.option2.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whatHasBeenGood.option3.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whatHasBeenGood.option3.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whatHasBeenGood.option4.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whatHasBeenGood.option4.image.src",
                  ),
                ),
              },
            },
            {
              name: t(
                "scenarios:youth-justice-and-me.whatHasBeenGood.option5.name",
              ),
              image: {
                src: t(
                  t(
                    "scenarios:youth-justice-and-me.whatHasBeenGood.option5.image.src",
                  ),
                ),
              },
            },
          ],
        },
        {
          id: "howYouthJusticeCouldHelp",
          type: "textarea",
          title: t(
            "scenarios:youth-justice-and-me.howYouthJusticeCouldHelp.title",
          ),
          pdfTitle: t(
            "scenarios:youth-justice-and-me.howYouthJusticeCouldHelp.title",
          ),
          tags: [],
        },
      ],
    },
  ];

  return scenarios;
}

export default ScenariosContent;
