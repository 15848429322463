import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Hidden, Visible } from "react-grid-system";
import styled from "styled-components";

import {
  colour_blue_cerulean,
  colour_mine_shaft,
  colour_white,
  fontsize_large,
} from "../assets/css/variables";

// We conditionally apply Childline branding to the link if
// we receive their phone number from translation
const StyledLink = styled.a<{ t: TFunction }>`
  align-items: center;
  background: ${(props) =>
    props.t("pages:scenarios.link_helpline.number") === "0800 1111"
      ? colour_blue_cerulean
      : colour_white};
  border: ${(props) =>
    props.t("pages:scenarios.link_helpline.number") === "0800 1111"
      ? `none`
      : `2px solid ${colour_mine_shaft}`};
  border-radius: 2rem;
  color: ${(props) =>
    props.t("pages:scenarios.link_helpline.number") === "0800 1111"
      ? colour_white
      : colour_mine_shaft};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-width: 25rem;
  min-height: 3.5rem;
  padding: 0 2rem;
  width: 100%;

  img {
    height: 2.5rem;
  }

  .name {
    height: 1.5rem;
  }

  span {
    display: flex;
    font-size: ${fontsize_large};

    img {
      margin-left: 0.5rem;
    }
  }

  div {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

const Intro = styled.p`
  font-size: ${fontsize_large};
  margin: 2rem 0 0.5rem 0;
  text-align: center;
  width: 100%;
`;

const Call = styled.p`
  font-size: ${fontsize_large};
  font-weight: bold;
  margin: 0;
  text-align: center;
  width: 100%;
`;

/**
 * Shows a tel: link for mobile devices with localised helpline number.
 * On larger devices the link is replaced with the number displayed directly.
 */
export const HelplineLink = () => {
  const { t } = useTranslation("pages");

  return (
    <div>
      <Intro>{t("pages:scenarios.link_helpline.label")}</Intro>
      <Hidden md lg xl>
        <StyledLink
          href={("tel:" + t("pages:scenarios.link_helpline.number")).replaceAll(
            " ",
            "",
          )}
          t={t}
        >
          <img src={t("images:phone_ringing_white.src")} alt="" />
          <span>
            <span>{t("pages:scenarios.link_helpline.action")}</span>
            <img className="name" src={t("images:childline.src")} alt="" />
          </span>
          <div />
        </StyledLink>
      </Hidden>
      <Visible md lg xl>
        <Call>
          {t("pages:scenarios.link_helpline.desktop_action") +
            " " +
            t("pages:scenarios.link_helpline.number")}
        </Call>
      </Visible>
    </div>
  );
};
