import React, { Component, type ReactNode as Node } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_royal_purple } from "../../assets/css/variables";
import { GreenButtonAction } from "./ButtonAction";

type Props = {
  children?: Node;
  className?: string;
  name: string;
  isCollapsed: boolean;
  t: TFunction;
  color?: "green";
};

type State = {
  isCollapsed: boolean;
};

let idCounter = 0;

class ButtonToggle extends Component<Props, State> {
  static defaultProps = {
    isCollapsed: true,
  };

  id: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: props.isCollapsed,
    };

    this.id = `${idCounter++}_button_toggle`;

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(({ isCollapsed }) => ({
      isCollapsed: !isCollapsed,
    }));
  }

  render() {
    const { children, className, name, t } = this.props;
    const { isCollapsed } = this.state;

    const imageName = isCollapsed
      ? "arrow_circle_down_black"
      : "arrow_circle_up_black";
    const image = {
      src: t("images:" + imageName + ".src"),
      pos: "right" as const,
      alt: t("images:" + imageName + ".alt"),
    };

    return (
      <div className={className}>
        <StyledGreenButtonAction
          image={image}
          name={name}
          onClick={this.toggle}
          aria-controls={this.id}
        />
        {!isCollapsed && (
          <div id={this.id} aria-expanded="true">
            {children}
          </div>
        )}
      </div>
    );
  }
}

const StyledButtonToggle = styled(ButtonToggle)`
  width: 100%;
`;

const StyledGreenButtonAction = styled(GreenButtonAction)`
  margin: 0;
  max-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-right: calc(1.8rem + 0.9rem); /* img width + right */
  text-indent: calc(1.8rem + 0.9rem); /* img width + right */

  img {
    width: 1.8rem;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0.9rem; /* copied padding from ButtonAction.jsx */
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    transform: none;
  }
`;

export default withTranslation()(StyledButtonToggle);
