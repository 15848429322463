import React, { Component, Fragment, type ReactNode as Node } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { colour_mine_shaft } from "../../../assets/css/variables";
import session from "../../../session";
import getAxiosErrorMessage from "../../../utils/getAxiosErrorMessage";
import type { Message } from "../../../utils/types";
import type { RouteComponentProps } from "react-router-dom";
import { GreenButtonAction } from "../../subcomponents/ButtonAction";
import { ScrollToElement } from "../../ScrollToElement";
import MessageBox from "../../subcomponents/MessageBox";
import { ApiHocProps, withApi } from "../../../api";

type Props = RouteComponentProps & {
  purpose: "consent_momo" | "consent_third_party" | "forget_account";
  t: TFunction;
  userId?: number;
};

type State = {
  message?: Message;
  requestDelete: boolean;
  requestNoMomo: boolean;
  requestNoThirdParty: boolean;
};

class PrivacyControl extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      message: { text: "" },
      requestDelete: false,
      requestNoMomo: false,
      requestNoThirdParty: false,
    };

    this.addSendConsent = this.addSendConsent.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.withdrawConsent = this.withdrawConsent.bind(this);
  }

  async addSendConsent() {
    // Try add send consent.
    const { userId } = this.props;
    let response;
    if (userId) {
      try {
        response = await this.props.Api.post(`/user/${userId}/consents`, {
          type: "one_third_party",
        });
      } catch (e) {
        const msg = getAxiosErrorMessage(e);
        return this.setState({ message: { text: msg } });
      }
      this.setState({
        requestNoThirdParty: this.noSendConsent() || false,
      });
      const user = response ? response.data : session.user;
      session.setSession(user);
    } else {
      // If we don't have a user - return to landing.
      this.props.history.push("/");
    }
  }

  cancelAction() {
    this.setState({
      requestDelete: false,
      requestNoMomo: false,
      requestNoThirdParty: this.noSendConsent() || false,
    });
  }

  noSendConsent() {
    // If we have a user return true if send consent not found.
    return session.user
      ? !(session.user.consented_to.indexOf("one_third_party") > -1)
      : false;
  }

  async deleteAccount() {
    // If already requested delete, this must be the Yes, I'm sure button.
    if (this.state.requestDelete) {
      const id = this.props.userId;
      // Try delete endpoint - redirect user to login with message - your account deleted.
      try {
        await this.props.Api.delete("/user/" + id);
      } catch (e) {
        return this.setState({
          message: {
            type: "error",
            text: (e as any).response
              ? (e as any).response.message
              : this.props.t(
                  "pages:privacy.controls.delete_account_error_no_server_msg",
                ),
          },
        });
      }
      // Delete was successful - ends session and redirect to login with message.
      return session.deAuth(() =>
        this.props.history.push("/", {
          message: {
            text: this.props.t("pages:privacy.controls.delete_account_success"),
          },
        }),
      );
    } else {
      return this.setState({
        requestDelete: true,
      });
    }
  }

  async withdrawConsent(consent) {
    switch (consent) {
      case "one_momo":
        // We set requestDelete true to skip the first are you sure step.
        // YP will still need to click Yes, please delete my account for delete to happen.
        this.setState({ requestDelete: true, requestNoMomo: true });
        break;
      case "one_third_party": {
        const { userId } = this.props;
        let response;
        if (userId) {
          try {
            response = await this.props.Api.delete(`/user/${userId}/consents`);
          } catch (e) {
            const msg = getAxiosErrorMessage(e);
            return this.setState({ message: { text: msg } });
          }
          this.setState({
            requestNoThirdParty: this.noSendConsent() || false,
          });
        } else {
          // If we don't have a user - return to landing.
          this.props.history.push("/");
        }
        this.setState({ requestNoThirdParty: true });
        const user = response ? response.data : session.user;
        session.setSession(user);
        break;
      }
      default:
        // Do nothing.
        break;
    }
  }

  render() {
    const { purpose, t } = this.props;
    const { requestDelete, requestNoMomo, requestNoThirdParty } = this.state;
    let buttonText = "";
    let desc = "";
    let onClick = function () {};

    // We have 3 possibilities - defined: consent_one_momo, consent_third_party and delete.
    switch (purpose) {
      case "consent_momo":
        buttonText = requestNoMomo
          ? t("pages:privacy.controls.delete_account_button_step2")
          : t("pages:privacy.controls.withdraw_signup_consent_button");
        desc = requestNoMomo
          ? t("pages:privacy.controls.withdraw_signup_consent_delete_desc")
          : t("pages:privacy.controls.withdraw_signup_consent_desc");
        onClick = () => {
          requestNoMomo
            ? this.deleteAccount()
            : this.withdrawConsent("one_momo");
        };
        break;
      case "consent_third_party":
        buttonText = requestNoThirdParty
          ? t("pages:privacy.controls.add_send_consent_button")
          : t("pages:privacy.controls.withdraw_send_consent_button");
        desc = requestNoThirdParty
          ? t("pages:privacy.controls.add_send_consent_desc")
          : t("pages:privacy.controls.withdraw_send_consent_desc");
        onClick = () => {
          requestNoThirdParty
            ? this.addSendConsent()
            : this.withdrawConsent("one_third_party");
        };
        break;
      case "forget_account":
        buttonText = requestDelete
          ? t("pages:privacy.controls.delete_account_button_step2")
          : t("pages:privacy.controls.delete_account_button_step1");
        desc = t("pages:privacy.controls.delete_account_desc_2");
        onClick = this.deleteAccount;
        break;
      default:
        break;
    }

    return (
      <Fragment>
        <MessageBox message={this.state.message} />
        <ButtonBox
          buttonText={buttonText}
          cancelButton={
            this.state.requestDelete
              ? {
                  action: this.cancelAction,
                  text: t("pages:privacy.controls.button_cancel"),
                }
              : null
          }
          desc={desc}
          onClick={onClick}
        />
      </Fragment>
    );
  }
}

type ButtonBoxProps = {
  buttonText: string;
  cancelButton: { action: () => void; text: string } | null;
  desc: string | Node;
  onClick: () => void;
};

function ButtonBox(props: ButtonBoxProps) {
  const { buttonText, cancelButton, desc, onClick } = props;
  return (
    <ButtonBoxContainer>
      <p>{desc}</p>
      <GreenButtonAction name={buttonText} onClick={onClick} />

      {cancelButton && (
        <>
          <GreenButtonAction
            name={cancelButton.text}
            onClick={cancelButton.action}
          />
          <ScrollToElement />
        </>
      )}
    </ButtonBoxContainer>
  );
}

const ButtonBoxContainer = styled.div`
  border: 1px solid ${colour_mine_shaft};
  border-radius: 2rem;
  padding: 0.5em 1.5em;
  width: 100%;
`;

const PrivacyControlWithApi = withApi(PrivacyControl);

// With router so we can redirect on delete account. Might be a better pattern.
export default withRouter(withTranslation()(PrivacyControlWithApi));
