import React, { Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_gallery, colour_mine_shaft } from "../../assets/css/variables";
import GoogleLogo from "../../assets/images/powered-by-google.png";
import session from "../../session";
import type { History, Message } from "../../utils/types";
import { getLocaleDefaults } from "../../utils/l10n";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import ProgressBars from "../subcomponents/ProgressBars";
import SubmitButton from "../subcomponents/SubmitButton";
import { AxiosResponse } from "axios";
import { GeoSuggestLoader } from "../subcomponents/GeoSuggestLoader";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  t: TFunction;
};

type LatLng = {
  lat: number;
  lng: number;
};

type GeoSuggestion = {
  placeName: string;
  latLng?: LatLng;
  countryName: string;
};

type State = {
  awaitingResponse: boolean;
  geolocatorSearch: GeoSuggestion;
  initialValue: string;
  isValid: boolean;
  message: Message;
};

const AreaLookup = styled(GeoSuggestLoader)`
  width: 100%;

  ul {
    margin: 0;

    li {
      border: 1px solid ${colour_gallery};
      padding: 1em 1.5em;
      cursor: pointer;

      &.geosuggest__item--active {
        border: 1px solid ${colour_mine_shaft};
      }
    }
  }

  li:last-child:after {
    content: "";
    display: block;
    background: url(${GoogleLogo}) right center no-repeat white;
    background-size: 120px 15px;
    width: 100%;
    height: 1.5em;
    padding-bottom: 0;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__input-wrapper {
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    input {
      border: 1px solid ${colour_mine_shaft};
      border-radius: 2em;
      box-sizing: border-box;
      height: 3.5em;
      outline: none;
      padding: 0.5em 1.5em;
      width: 100%;

      &:focus {
        background: ${colour_gallery};
      }

      /* Prevents Firefox highlighting */
      &:required {
        box-shadow: none;
      }
    }

    label {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }
`;

class Locator extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);

    const initialValue =
      session.user && session.user.locationName
        ? session.user.locationName
        : "";

    this.state = {
      awaitingResponse: false,
      geolocatorSearch: { placeName: "", countryName: "" },
      initialValue,
      isValid: false,
      message: this.getLocationState().message || { text: "" },
    };
    this.onChange = this.onChange.bind(this);
    this.onSuggestSelect = this.onSuggestSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(event) {
    event.preventDefault();
    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });
    const locationState = this.state.geolocatorSearch;

    const data = {
      label: locationState.placeName,
      lat: locationState.latLng ? locationState.latLng.lat : null,
      lng: locationState.latLng ? locationState.latLng.lng : null,
      country_name: locationState.countryName,
    };
    let response: AxiosResponse;
    try {
      const id = session.user ? session.user.id : null;
      if (session.user && id) {
        response = await this.props.Api.put("/user/" + id, {
          name: session.user.name,
          email: session.user.email,
          location: JSON.stringify(data),
        });
      } else {
        this.setState({ awaitingResponse: false });
        return new Error("Invalid User");
      }
    } catch (e) {
      return this.setState({
        awaitingResponse: false,
        // TODO fix this casting
        message: { text: (e as { message: string }).message, type: "error" },
      });
    }
    // Success - user object returned. Save user to local session and forward to next page.
    try {
      session.setSession(response.data);
    } catch (e) {
      return this.setState({
        awaitingResponse: false,
        message: { text: (e as { message: string }).message, type: "error" },
      });
    }
    this.setState({ awaitingResponse: false });

    if (this.getLocationState().fromMyAccount) {
      const { t } = this.props;

      this.props.history.push("/account", {
        message: { text: t("pages:locator.message_on_success") },
      });
    } else {
      this.props.history.push("/services");
    }
  }

  onChange() {
    this.setState({
      geolocatorSearch: { placeName: "", countryName: "" },
      isValid: false,
    });
  }

  onSuggestSelect(suggest) {
    // If the input is empty, geosuggest it will return undefined by design.
    if (!suggest) {
      return false;
    }

    // find country name in the address_components; two criteria for that...
    const address_component = suggest.gmaps.address_components.find(
      function (item) {
        // Go through the types and return true on the first that...
        return item.types.some(function (type) {
          // ...has a type that matches
          if (getLocaleDefaults().tld !== "uk") {
            // Otherwise we don't get the country, we get a 'region'
            // resulting in the country for the location being England,
            return ["country"].indexOf(type) > -1;
          }
          return ["administrative_area_level_1", "country"].indexOf(type) > -1;
        });
      },
    );

    this.setState({
      geolocatorSearch: {
        placeName: suggest.label,
        latLng: suggest.location,
        countryName: address_component.long_name,
      },
      isValid: true,
    });
  }

  // third instance of getLocationState. TODO: take this to utils
  getLocationState(): { message?: Message; fromMyAccount?: boolean } {
    const { history } = this.props;

    if (history && history.location && history.location.state) {
      return history.location.state;
    }
    return {};
  }

  render() {
    const { t } = this.props;
    const { initialValue } = this.state;
    const { fromMyAccount } = this.getLocationState();
    const submitLabel = fromMyAccount
      ? t("pages:locator.button_submit_to_my_account")
      : t("pages:locator.button_submit");

    const placeTypes = ["(cities)"];

    const localeDefaults = getLocaleDefaults();
    const searchScope = process.env.REACT_APP_SEARCHSCOPE
      ? JSON.parse(process.env.REACT_APP_SEARCHSCOPE)["countries"]
      : localeDefaults.searchScope;

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:locator.heading")}
            subheading={t("pages:locator.subheading")}
            message={this.state.message}
            hideLogoutButton={!fromMyAccount}
            hideAccountButton={!fromMyAccount}
            hideHomeButton={!fromMyAccount}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <form onSubmit={this.onSubmit}>
                <AreaLookup
                  onChange={this.onChange}
                  onSuggestSelect={this.onSuggestSelect}
                  initialValue={initialValue}
                  placeholder={t("pages:locator.label_geolocatorSearch")}
                  country={searchScope}
                  types={placeTypes}
                  queryDelay={350}
                  minLength={3}
                  label="Add your nearest town"
                  id="user-location"
                  autoActivateFirstSuggest={true}
                />
                <SubmitButton
                  label={submitLabel}
                  noIcon={fromMyAccount}
                  name="locator"
                  disabled={!this.state.isValid || this.state.awaitingResponse}
                />
              </form>
            </Col>
          </Row>
        </LayoutContent>
        <LayoutFooter>
          {!fromMyAccount && <ProgressBars count={3} position={2} />}
        </LayoutFooter>
      </Layout>
    );
  }
}

export default withTranslation()(withApi(Locator));
