import "react-app-polyfill/ie11";
import React from "react";
import { createRoot } from "react-dom/client";
import { setConfiguration } from "react-grid-system";
import ReactModal from "react-modal";
import { BrowserRouter } from "react-router-dom";

// The global CSS styles, resets and variables
import "normalize.css";
import "./assets/css/global.css";
import { gutter_px } from "./assets/css/variables";

import App from "./App";
import "./i18n"; // init i18n instance
import { ConfigProvider } from "./components/ConfigProvider";

const domRoot = document.getElementById("root");
const root = domRoot ? createRoot(domRoot) : null;

// So screenreaders don't read behind the modal.
ReactModal.setAppElement(domRoot!);

// Set grid gutter width globally.
setConfiguration({ gutterWidth: gutter_px });

// Only try to render if we have a DOM node to put it in.
// Otherwise Flow complains because getElementById might return null, making this invalid.
if (root) {
  root.render(
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>,
  );
} else {
  console.error("Missing root dom element");
}
