import Geosuggest, { Props } from "@ubilabs/react-geosuggest";
import { Helmet } from "react-helmet";
import { useConfig } from "../ConfigProvider";
import { useLayoutEffect, useMemo, useState } from "react";
import { Loading } from "./Loading";

export type GeoSuggestLoaderProps = Props;

let globalLoaded = false;

export const GeoSuggestLoader = (props: GeoSuggestLoaderProps) => {
  const { placesApiKey, loading } = useConfig();
  const [apiLoaded, setApiLoaded] = useState(globalLoaded);

  // Generate a unique callback name for each instance
  const callbackName = useMemo(
    () => `globalHandler_${Math.random().toString(36).slice(2, 9)}`,
    [],
  );

  useLayoutEffect(() => {
    // Attach the unique handler
    window[callbackName] = () => {
      setApiLoaded(true);
      globalLoaded = true;
    };

    // Clean up the handler on unmount
    return () => {
      window[callbackName] = undefined;
    };
  }, [callbackName]);

  return (
    <>
      <Helmet>
        {placesApiKey && !globalLoaded && (
          <script
            src={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${placesApiKey}&callback=${callbackName}`}
            async
            defer
          ></script>
        )}
      </Helmet>
      {loading && <Loading small />}
      {!loading && apiLoaded && <Geosuggest {...props} />}
    </>
  );
};
