import React from "react";

import styled from "styled-components";

import {
  colour_gallery,
  colour_red_ribbon,
  colour_white,
  colour_mine_shaft,
} from "../../assets/css/variables";
import InputStateIndicator from "./InputStateIndicator";
import MessageBox from "../subcomponents/MessageBox";

type Props = {
  className?: string;
  onChange: any;
  id: string;
  label: string;
  name: string;
  required?: boolean;
  valid: boolean;
  value?: string;
  type?: string;
  error?: string;
  ariaHide?: boolean;
  autoComplete?: string;
};

function TextField(props: Props) {
  const autoCompleteMaybe =
    typeof props.autoComplete !== "undefined"
      ? { autoComplete: props.autoComplete }
      : {};

  return (
    <div {...(props.ariaHide === true ? { "aria-hidden": "true" } : {})}>
      <div className={props.className}>
        {props.type !== "hidden" && (
          <label htmlFor={props.id}>{props.label}</label>
        )}
        <input
          type={props.type}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          required={props.required}
          aria-required={props.required}
          aria-invalid={!props.valid}
          tabIndex={props.ariaHide === true ? -1 : 0}
          // Ridiculously roundabout way of not printing undefined attributes.
          {...autoCompleteMaybe}
        />
        {props.type !== "hidden" && (
          <InputStateIndicator
            required={props.required}
            valid={props.valid}
            value={props.value}
          />
        )}
      </div>
      {props.error && (
        <MessageBox
          message={{
            type: "error",
            text: props.error,
          }}
        />
      )}
    </div>
  );
}

// Todo - move to common size vars
const textFieldHeight = "3.5em";

const StyledTextField = styled(TextField)`
  box-sizing: border-box;
  display: flex;
  margin: 1em 0;
  position: relative;
  align-items: center;
  justify-content: center;

  input {
    border: ${(props) =>
      !props.valid && props.value !== ""
        ? "3px solid " + colour_red_ribbon
        : "1px solid " + colour_mine_shaft};
    border-radius: 2em;
    box-sizing: border-box;
    height: ${textFieldHeight};
    margin-right: -3em;
    outline: none;
    padding: 0.5em 3em 0.5em 1.5em;
    width: 100%;

    &:focus {
      background: ${colour_gallery};
    }

    // Prevents Firefox highlighting
    &:required {
      box-shadow: none;
    }
  }

  label {
    background: ${colour_white};
    border-radius: 2rem;
    color: ${(props) =>
      !props.valid && props.value !== ""
        ? colour_red_ribbon
        : colour_mine_shaft};
    font-weight: bold;
    left: 2rem;
    padding: 0 0.5rem;
    position: absolute;
    top: -0.6rem;
    white-space: nowrap;
  }
`;

export default StyledTextField;
