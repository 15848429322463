import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import ReactModal from "react-modal";
import styled from "styled-components";
import { Hidden, Visible } from "react-grid-system";

import {
  colour_blue_cerulean,
  colour_white,
  colour_shadow,
} from "../../assets/css/variables";

type Props = {
  t: TFunction;
};

type State = {
  isOpen: boolean;
};

class SafetyLinkButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  openModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeModal() {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { t } = this.props;
    const childlinePhone = t("pages:scenarios.link_helpline.number");

    // Thank you to https://github.com/reactjs/react-modal/issues/627#issuecomment-901081678
    // for the trick with ReactModal and StyledComponents
    return (
      <div>
        <StaticButton
          aria-label={t("images:need_help_now.alt")}
          isOpen={this.state.isOpen}
          onClick={this.openModal}
        >
          <FixedButtonImage
            src={t("images:safety_link.src")}
            onMouseOver={(e) =>
              (e.currentTarget.src = t("images:need_help_now.src"))
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = t("images:safety_link.src"))
            }
          />
        </StaticButton>
        <ReactModal
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}
          className="_"
          overlayClassName="_"
          contentLabel={t("messagesAndSettings:content_label")}
          contentElement={(props, children) => (
            <ModalStyle {...props}>{children}</ModalStyle>
          )}
          overlayElement={(props, contentElement) => (
            <OverlayStyle {...props}>{contentElement}</OverlayStyle>
          )}
        >
          <ButtonDiv>
            <ModalHeadingImage src={t("images:safety_link.src")} />
            <ModalHelpText>
              {t("messagesAndSettings:safety_modal_header")}
            </ModalHelpText>
            <CloseModalButton
              aria-label={t("pages:scenario.checkboxestext.modal.button_close")}
              onClick={this.closeModal}
            >
              <img src={t("images:cross_blue.src")} alt="" />
            </CloseModalButton>
          </ButtonDiv>

          <ModalMessageText>
            {t("messagesAndSettings:safety_modal_content")}
          </ModalMessageText>

          <Hidden md lg xl>
            <ChildLineLinkButton
              aria-label={t(
                "pages:scenarios.link_helpline.desktop_action",
              ).replace(":", "")}
              href={("tel:" + childlinePhone).replace(/\s/g, "")}
            >
              <ChildLineLinkButtonInternalDiv>
                <ModalButtonImage src={t("images:phone_ringing_white.src")} />
                <TextDiv>
                  {t("messagesAndSettings:safety_modal_call")}
                  <br />
                  {childlinePhone}
                </TextDiv>
                <Spacer />
              </ChildLineLinkButtonInternalDiv>
            </ChildLineLinkButton>
          </Hidden>
          <Visible md lg xl>
            <ChildLineLinkButton as={"div"}>
              <ChildLineLinkButtonInternalDiv>
                <Spacer />
                <TextDiv>
                  {t("messagesAndSettings:safety_modal_call")}
                  <br />
                  {childlinePhone}
                </TextDiv>
                <Spacer />
              </ChildLineLinkButtonInternalDiv>
            </ChildLineLinkButton>
          </Visible>
        </ReactModal>
      </div>
    );
  }
}

const StaticButton = styled.button<{ isOpen: boolean }>`
  background: none;
  border-radius: 10rem;
  bottom: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  padding: 0.5rem;
  position: fixed;
  right: 20px;
  z-index: ${(props) => (props.isOpen ? 0 : 1)};
`;

const ButtonDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 4rem;
  justify-content: space-around;
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

const ChildLineLinkButton = styled.a`
  background-color: ${colour_blue_cerulean};
  border-radius: 2em;
  color: ${colour_white};
  height: 4rem;
  padding: 5px;
  text-align: center;
  width: 100%;
  align-items: center;
`;

const ChildLineLinkButtonInternalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  align-items: center;
`;

const ModalHelpText = styled.h2`
  color: ${colour_blue_cerulean};
  text-align: center;
  margin: 0.5rem;
  font-weight: bold;
`;

const ModalMessageText = styled.div`
  color: ${colour_blue_cerulean};
  margin-inline: 1rem;
  text-align: center;
`;

const FixedButtonImage = styled.img`
  width: 4rem;
  height: 4rem;
`;

const ModalHeadingImage = styled.img`
  height: 100%;
  width: auto;
  margin: 5px;
`;

const TextDiv = styled.div`
  flex: 50%;
`;
const Spacer = styled.div`
  flex: 25%;
`;

const ModalButtonImage = styled.img`
  height: 30px;
  flex: 25%;
`;

const CloseModalButton = styled.button`
  align-items: center;
  background-color: ${colour_white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  border-radius: 50%;
  border: 3px solid ${colour_blue_cerulean};
  display: flex;
  height: 100%;
  aspect-ratio: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ModalStyle = styled.div`
  background-color: ${colour_white};
  border-radius: 2rem;
  bottom: 1rem;
  box-shadow: 0 0 0.5rem 0.125rem ${colour_shadow};
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  outline: 0px;
  position: fixed;
  right: 2rem;

  width: 18rem;
  height: 15rem;

  @media (max-width: 768px) {
    right: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
`;

export default withTranslation()(SafetyLinkButton);
