import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { colour_white } from "../assets/css/variables";
import { LogoutSymbol } from "../assets/images/symbols/LogoutSymbol";
import session from "../session";
import { useConfig } from "./ConfigProvider";
import { useApi } from "../api";

export const LogoutButton = () => {
  const { t } = useTranslation("images");
  const history = useHistory();
  const { spaceUrl } = useConfig();
  const Api = useApi();

  const logoutAndRedirect = async () => {
    await Api.post("/user/logout");

    // If it's a profile session go back to Practitioner Space
    if (session.user && session.user.role === "profile") {
      const practitionerSpaceUrl = spaceUrl;
      session.deAuth(() => window.location.assign(practitionerSpaceUrl));
    } else {
      session.deAuth(() => history.push("/"));
    }
  };

  return session.isAuth === true ? (
    <Button type="button" onClick={logoutAndRedirect}>
      <LogoutSymbol title={t("logout.alt")} />
    </Button>
  ) : (
    <p>{t("messagesAndSettings:logout_button.p_not_auth")}</p>
  );
};

const Button = styled.button`
  background: none;
  border-radius: 50%;
  display: block;
  height: 2rem;
  padding: 0;

  &:active,
  &:focus {
    box-shadow: 0 0 0.5rem 0.25rem ${colour_white};
    outline: none;
  }

  svg {
    height: 44px;
    width: 44px;
  }
`;
