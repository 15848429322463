import React, { type ReactNode as Node } from "react";

import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_royal_purple,
} from "../../assets/css/variables";

type Props = {
  className?: string;
  href: string;
  name?: string;
  newtab?: boolean;
  children?: Node;
};

function TextLinkExternal(props: Props) {
  const { className, href, name, newtab, children } = props;

  if (newtab) {
    return (
      <a
        href={href}
        target={"_blank"}
        rel={"noopener noreferrer"}
        className={className}
      >
        {name || children}
      </a>
    );
  }

  return (
    <a href={href} className={className}>
      {name || children}
    </a>
  );
}

const StyledTextLinkExternal = styled(TextLinkExternal)`
  color: ${colour_mine_shaft};
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: ${colour_royal_purple};
    text-decoration: underline;
  }
`;

export default StyledTextLinkExternal;
