import { getLocaleDefaults } from "./utils/l10n";
import i18n, { ReactOptions } from "i18next";
import httpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import moment from "moment";

import session from "./session";

const user: any = session.user ? session.user : {};
// Use config to get fallback language array, if none - go with 'en'
const stackLangs = process.env.REACT_APP_FALLBACK_LANGUAGES
  ? process.env.REACT_APP_FALLBACK_LANGUAGES.split(",")
  : getLocaleDefaults().fallbackLng || ["en"];

const lang = user ? user.language_code : stackLangs[0];
// The file system uses - between lang and country and  _ between country and region.
// The Database uses - between country and region.
const loc =
  user && user.country_code ? user.country_code.replace("-", "_") : "";

// Avoids adding a trailing -.
const userLocale = loc ? lang + "-" + loc : lang;

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: stackLangs,
    lng: userLocale,
    ns: [
      "messagesAndSettings",
      "fieldsetImages",
      "images",
      "scenarios",
      "pages",
      "rights",
      "yjrights",
    ],
    defaultNS: "messagesAndSettings",
    debug: process.env.NODE_ENV === "production",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    returnNull: false,

    backend: {
      loadPath(lng, ns) {
        // cachebreaking query param to stop the browser caching the json files
        // if we ever transition to a server providing these translations we should configure
        // the server's response to turn of caching/cache appropriately
        return `/locales/${lng}/${ns}.json?_cb=${moment.now()}`;
      },
    },

    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: false,
    } as ReactOptions,
  });

export default i18n;
