import styled from "styled-components";

import type { Statement } from "./pages/Statements";
import { StatementListItem } from "./StatementListItem";

interface StatementListProps {
  statements: Array<Statement> | undefined;
  notifyTrashClick: (id: number) => void;
}

const List = styled.ul`
  width: 100%;
  max-width: 800px;
`;

export const StatementList = ({
  statements,
  notifyTrashClick,
}: StatementListProps) => {
  if (!statements) {
    return null;
  }

  return (
    <List>
      {statements
        .map((statement: Statement) => {
          return (
            <StatementListItem
              key={statement.id}
              statement={statement}
              onTrashClick={notifyTrashClick}
            />
          );
        })
        .reverse()}
    </List>
  );
};
