import React from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import {
  colour_alto,
  colour_caribbean_green,
  colour_mine_shaft,
  colour_spring_green,
  colour_shadow,
  fontsize_large,
} from "../../assets/css/variables";
import BaseButton from "../subcomponents/BaseButton";

type Props = {
  className?: string;
  name?: string;
  label: string;
  disabled: boolean;
  t: TFunction;
  noIcon: boolean;
};

function SubmitButton(props: Props) {
  const optionalName = props.name ? { name: props.name } : {};

  return (
    <BaseButton
      {...optionalName}
      aria-label={props.label}
      className={props.className}
      type="submit"
      disabled={props.disabled}
    >
      {props.noIcon ? (
        props.label
      ) : (
        <Icon
          src={props.t("images:arrow_right_black.src")}
          alt={props.t("images:arrow_right_black.alt")}
        />
      )}
    </BaseButton>
  );
}

SubmitButton.defaultProps = {
  disabled: true,
  noIcon: false,
};

const StyledSubmitButton = styled(SubmitButton)`
  background-color: ${(props) =>
    props.disabled ? colour_alto : colour_caribbean_green};
  border-radius: 2rem;
  color: ${colour_mine_shaft};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  margin: 1rem 0;
  width: 100%;
  font-size: ${fontsize_large};
  font-weight: 700;
  &:disabled {
    cursor: not-allowed;
  }
  &:enabled:hover {
    background-color: ${colour_spring_green};
    box-shadow: 0.125rem 0.25rem 0.5rem 0 ${colour_shadow};
    transform: translate(-0.125rem, -0.25rem);
  }
`;

const Icon = styled.img`
  max-height: 1.2em;
  max-width: 1.2em;
`;

StyledSubmitButton.defaultProps = {
  disabled: true,
};

export default withTranslation()(StyledSubmitButton);
