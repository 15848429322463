import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import session from "../session";

interface StatementMessageProps {
  status: string;
  details?: string;
  date: string;
}

const Message = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
  justify-content: space-between;

  * {
    margin-top: 0.5rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 2rem 0;

    * {
      margin-top: 0;
    }

    *:nth-child(1) {
      flex-basis: 20%;
    }

    *:nth-child(2) {
      flex-basis: 50%;
    }

    *:nth-child(3) {
      flex-grow: 0;
      flex-basis: 30%;
    }

    div {
      color: green;
    }
  }
`;

export const StatementMessage = ({
  status,
  details,
  date,
}: StatementMessageProps) => {
  const { t } = useTranslation("messagesAndSettings");

  const month_list = t("month_list");

  const language_code = session?.user?.language_code || "en";
  moment.updateLocale(language_code, {
    months: month_list.split("_"),
  });

  const dateFromString = moment(date, "YYYY-MM-DD HH:mm:ss Z");

  const displayDate = dateFromString.format(t("date_formats.statement_date"));

  return (
    <Message>
      <span>{status}</span>
      <span>{details}</span>
      <span>{displayDate}</span>
    </Message>
  );
};
