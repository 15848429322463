const MockAuthenticator = {
  /**
   * "Executes" Authentication by pausing, then sending the data.
   * @param {Object} credentials
   * @param {string} credentials.email
   * @param {string} credentials.password
   * @return {Promise<Object>}
   */
  execute: async function () {
    const data = {
      id: 24,
      uuid: "efd8d11a-86e8-34c4-a541-7255109495c5",
      momo_worker_id: null,
      active: 1,
      email: "momo+app@neontribe.co.uk",
      name: "Charlie Appuser",
      dob: "1997-08-31",
      care_status: "I don't know",
      local_authority_id: 6,
      language_id: null,
      organisation_id: null,
      auth_token:
        "a95173a42845edcfe8778c172f5ab85ce681770bb5eb38d8d8b483141d00762d9f5b6622dbbe5a6bf95504e35b98cb5da6f7d414ee20241f7096dcc0086f8ae7",
      auth_token_expires_at: "2020-04-09 13:45:26",
      auth_token_ttl: 0.041666666666667,
      created_at: "2018-03-19 13:04:54",
      updated_at: "2018-04-09 12:45:26",
      deleted_at: null,
      role: "momo",
      first_name: "Charlie",
      last_name: "Appuser",
      is_test_profile: false,
      country_id: 6,
      locale: "GB-ENG",
      lang: "en",
      language_code: "en",
      country_code: "GB-ENG",
      phone_number: null,
      worker: null,
      organisation: null,
      language: null,
      local_authority: {
        id: 6,
        name: "West Sussex",
        country_id: 6,
        not_listed: 0,
        created_at: "2018-03-19 13:04:49",
        updated_at: "2018-03-19 13:04:49",
        country: {
          id: 6,
          name: "Vatican",
          code: "GB-ENG",
          created_at: "2018-03-19 13:04:49",
          updated_at: "2018-03-19 13:04:49",
        },
      },
    };

    // Pause for a bit.
    await new Promise(function (resolve) {
      return setTimeout(resolve, 100);
    });

    return { data: data, errorMessage: null };
  },
};

export default MockAuthenticator;
