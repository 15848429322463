import React from "react";

import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

import {
  colour_caribbean_green,
  colour_mine_shaft,
  colour_spring_green,
  colour_shadow,
  fontsize_large,
  fontsize_larger,
} from "../../assets/css/variables";

type Props = LinkProps & {
  name: string;
};

function ButtonLink({ name, ...props }: Props) {
  return <Link {...props}>{name}</Link>;
}

const StyledButtonLink = styled(ButtonLink)`
  background-color: ${colour_caribbean_green};
  border-radius: 2rem;
  color: ${colour_mine_shaft};
  display: block;
  font-size: ${fontsize_large};
  font-weight: bold;
  height: 3.5rem;
  line-height: 3.5rem;
  margin: 0.5rem auto;
  max-width: 30rem;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: ${colour_spring_green};
    box-shadow: 0.125rem 0.25rem 0.5rem 0 ${colour_shadow};
    transform: translate(-0.125rem, -0.25rem);
  }
`;

const LargeButtonLink = styled(StyledButtonLink)`
  font-size: ${fontsize_larger};
  font-weight: normal;
`;

export { LargeButtonLink };
export default StyledButtonLink;
