import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { VerticalSpacing } from "../VerticalSpacing";
import {
  colour_royal_purple,
  colour_gallery,
} from "../../assets/css/variables";

type LoadingProps = {
  message?: string;
  small?: boolean;
  t: TFunction;
};

const LoadingContainer = styled.div`
  text-align: center;
`;

const Spinner = styled.div`
  animation: spin 1.8s linear infinite;
  border-radius: 50%;
  border: 0.5rem solid ${colour_gallery};
  border-top: 0.5rem solid ${colour_royal_purple};
  height: 7rem;
  margin: auto;
  width: 7rem;

  /* If the user does not want animation, show a full circle */
  @media (prefers-reduced-motion) {
    border: 0.5rem solid ${colour_royal_purple};
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SmallSpinner = styled(Spinner)`
  border: 0.2rem solid ${colour_gallery};
  border-top: 0.2rem solid ${colour_royal_purple};
  height: 2rem;
  width: 2rem;

  /* If the user does not want animation, show a full circle */
  @media (prefers-reduced-motion) {
    border: 0.2rem solid ${colour_royal_purple};
  }
`;

export const Loading = withTranslation()(({
  small,
  message,
  t,
}: LoadingProps) => {
  return (
    <LoadingContainer>
      {small ? (
        <Fragment>
          <VerticalSpacing size={1} />
          <SmallSpinner />
          <VerticalSpacing size={1} />
          {message ? message : t("messagesAndSettings:loading")}
        </Fragment>
      ) : (
        <Fragment>
          <VerticalSpacing />
          <Spinner />
          <VerticalSpacing />
          {message ? message : t("messagesAndSettings:loading")}
        </Fragment>
      )}
    </LoadingContainer>
  );
});
