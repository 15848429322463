type StatementsSymbolProps = {
  title: string;
};

export const StatementsSymbol = ({ title }: StatementsSymbolProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="10"
      role="img"
    >
      <title>{title}</title>
      <circle
        cx="16"
        cy="16"
        r="15.339"
        fill="none"
        stroke="#fff"
        strokeWidth="1.6"
      />
      <path
        d="M17.8171 6.9136h-7.269c-.9972 0-1.8173.8202-1.8173 1.8174v14.538c0 .9972.8201 1.8174 1.8173 1.8174H21.452c.9972 0 1.8173-.8202 1.8173-1.8174V12.3653l-5.452-5.4517Z"
        fill="none"
        stroke="#fff"
        strokeWidth="1.47954"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      />
      <path
        d="M17.8171 6.9136v5.4517h5.4516M13.274 18.7258h5.4516M13.274 21.755h5.4516"
        fill="none"
        stroke="#fff"
        strokeWidth="1.47954"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      />
    </svg>
  );
};
