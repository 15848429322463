import React, { Component, Fragment } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import type User from "../../../auth/User";
import getJSON from "../../../utils/getJson";
import type { History, Message, Organisation } from "../../../utils/types";
import session from "../../../session";
import HeaderSection from "../../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../../layouts/Layout";

import PrivacyContent from "../Privacy/PrivacyContent";
import AccountDetails from "./AccountDetails";
import LocationList from "./LocationList";
import ServiceList from "./ServiceList";
import { getLocationMessage } from "../../../utils/getLocationMessage";
import { ApiHocProps, withApi } from "../../../api";

type Props = {
  t: TFunction;
  history: History;
};

type State = {
  awaitingStatements: boolean;
  user?: User | null;
  services: Array<Organisation>;
  message?: Message;
};

// copy/pasted from external,
// TODO: use ../../../utils/getAxiosErrorMessage.js
function getAxiosErrorMessage(error) {
  if (error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  }

  return error.message;
}

const SectionHeading = styled.h3`
  text-align: center;
`;

const Section = styled.section`
  & + & {
    margin-top: 2rem;
  }
`;

class Account extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.state = {
      user: session.user,
      services: [],
      awaitingStatements: true,
    };

    this.onUserUpdated = this.onUserUpdated.bind(this);
  }

  onUserUpdated(userData) {
    session.setSession(userData);

    this.setState({ user: session.user });
  }

  handleError(e) {
    if (e.response.status === 401) {
      // A 401 on the account page probably means we need to log in again.
      // In fact - we might want to move this to the getAxiosErrorMessage
      // and also consider 403s - maybe rename that function handleAxiosError...
      return session.deAuth(() =>
        this.props.history.push("/", {
          message: { text: getAxiosErrorMessage(e), type: "error" },
        }),
      );
    }

    return this.setState({
      message: {
        text: getAxiosErrorMessage(e),
        type: "error",
      },
    });
  }

  async componentDidMount() {
    let orgs;
    let serviceIds;

    try {
      // TODO: rewrite the backend /user/services so we don't need to keep redoing this two-step
      [serviceIds, orgs] = await Promise.all([
        getJSON(this.props.Api, "/user/services"),
        getJSON(this.props.Api, "/organisation"),
      ]);
    } catch (err) {
      this.setState({
        awaitingStatements: false,
      });
      return this.handleError(err);
    }

    this.setState({
      awaitingStatements: false,
      services: orgs.filter(function (org) {
        return serviceIds.indexOf(org.id) !== -1;
      }),
    });
  }

  render() {
    const { history, t } = this.props;
    // We might have a message in this component state or one passed from previous page.
    const message = this.state.message || getLocationMessage(history.location);
    const { user } = this.state;
    const userInfo = user
      ? { userInfo: { consents: user.consented_to, id: user.id } }
      : undefined;
    const name =
      user && user.first_name ? user.first_name : t("defaults.user_firstname");
    const locationName = user && user.locationName;
    const isProfile = user && user.role === "profile";

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:account.heading", { name })}
            subheading={t("pages:account.subheading")}
            message={message}
          />
        </LayoutHeader>
        <LayoutContent>
          {/* My account */}
          <Section>
            <SectionHeading>
              {t("pages:account.account_form_heading")}
            </SectionHeading>
            <AccountDetails
              history={history}
              user={user}
              onUserUpdated={this.onUserUpdated}
            />
          </Section>
          {!isProfile && (
            <Fragment>
              {/* Services I use */}
              <Section>
                <SectionHeading>
                  {t("pages:account.services_heading")}
                </SectionHeading>
                <ServiceList services={this.state.services} />
              </Section>
              {/* My location */}
              <Section>
                <SectionHeading>
                  {t("pages:account.location_heading")}
                </SectionHeading>
                <LocationList locationName={locationName} />
              </Section>
              {/* Privacy */}
              <Section>
                <SectionHeading>
                  {t("pages:account.privacy_heading")}
                </SectionHeading>
                <PrivacyContent
                  sections={new Set(["signup", "send", "account"])}
                  {...userInfo}
                  context={"account"}
                />
              </Section>
            </Fragment>
          )}
        </LayoutContent>
      </Layout>
    );
  }
}

export default withTranslation()(withApi(Account));
