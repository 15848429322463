import React from "react";

import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { type Organisation } from "../../../utils/types";
import ButtonLink from "../../subcomponents/ButtonLink";
import List, { ListItem } from "../../subcomponents/List";

type Props = {
  services: Array<Organisation>;
  t: TFunction;
};

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function Services(props: Props) {
  return (
    <div>
      <ListContainer>
        <List>
          {props.services.map(function (service) {
            return <ListItem key={service.id}>{service.name}</ListItem>;
          })}
        </List>
      </ListContainer>
      <Row justify="center">
        <Col md={4} lg={3}>
          <ButtonLink
            to={{
              pathname: "/services",
              state: { fromMyAccount: true },
            }}
            name={props.t("pages:account.services_link_to_services")}
          />
        </Col>
      </Row>
    </div>
  );
}

export default withTranslation()(Services);
