// This whole file is pretty much copy/pasted from ./ServicesList
import React from "react";

import styled from "styled-components";
import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";

import ButtonLink from "../../subcomponents/ButtonLink";
import List, { ListItem } from "../../subcomponents/List";

export type Props = {
  locationName?: string | null;
  t: TFunction;
};

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function Services(props: Props) {
  return (
    <div>
      <ListContainer>
        {props.locationName && (
          <List>
            <ListItem>{props.locationName}</ListItem>
          </List>
        )}
      </ListContainer>
      <Row justify="center">
        <Col md={4} lg={3}>
          <ButtonLink
            to={{
              pathname: "/locator",
              state: { fromMyAccount: true },
            }}
            name={props.t("pages:account.location_link_to_locator")}
          />
        </Col>
      </Row>
    </div>
  );
}

export default withTranslation()(Services);
