import React, { Component } from "react";
import Helmet from "react-helmet";

import i18n from "i18next";
import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_royal_purple } from "../../assets/css/variables";
import { getLocaleDefaults } from "../../utils/l10n";
import type { History, Message } from "../../utils/types";
import Layout, { LayoutContent, LayoutFooter } from "../layouts/Layout";
import ButtonReciteMe from "../subcomponents/ButtonReciteMe";
import { LargeButtonLink } from "../subcomponents/ButtonLink";
import LanguageChoice from "../subcomponents/LanguageChoice";
import MessageBox from "../subcomponents/MessageBox";
import TextLinkExternal from "../subcomponents/TextLinkExternal";
import { getLocationMessage } from "../../utils/getLocationMessage";

type Props = {
  history: History;
  t: TFunction;
};

type State = {
  language: { value: number };
  message: Message;
};

class Landing extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      language: { value: 1 },
      message: { text: "" },
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  async componentDidMount() {
    const fetched = localStorage.getItem("language");

    const languageId = fetched ? JSON.parse(fetched).id : null;
    const langCode = fetched ? JSON.parse(fetched).code : null;

    // If we retrieve a local language object, set the language
    if (langCode !== null) {
      i18n.changeLanguage(langCode);
    }

    // Provide the language to state
    if (languageId !== null) {
      this.setState(function (prevState) {
        return {
          ...prevState,
          language: { value: languageId },
        };
      });
    }
  }

  onLanguageChange(lang) {
    // Change the language
    i18n.changeLanguage(lang.code);
    // Save the language outside the user
    localStorage.setItem("language", JSON.stringify(lang));
    // Set the language into state for other components
    this.setState(function (prevState) {
      return {
        ...prevState,
        language: { value: lang.id },
      };
    });
  }

  render() {
    const { t } = this.props;
    const message = getLocationMessage(this.props.history.location);

    // Only display the accessibility link in local dev or the UK sites
    const shouldDisplayAccessibilityLink =
      getLocaleDefaults().tld === "" || getLocaleDefaults().tld === "uk";

    const shouldDisplayReciteMe = !window.usingCordova;

    return (
      <Layout>
        <LayoutContent>
          <Helmet>
            <title>{"One app"}</title>
          </Helmet>
          <Row justify="center">
            <LanguageChoice
              inline
              onChangeNotify={this.onLanguageChange}
              selected={this.state.language.value}
              legend={t("messagesAndSettings:language_choice")}
            />
            {shouldDisplayReciteMe ? <ButtonReciteMe /> : null}
          </Row>
          <Row justify="center">
            <LogoHolder>
              <Logo
                src={t("images:logo_mymind.src")}
                alt={t("images:logo_mymind.alt")}
              />
            </LogoHolder>
          </Row>
          <Description>{t("pages:landing.description_p_login")}</Description>
          <Row justify="center">
            <Col>
              <LargeButtonLink
                to="/login"
                name={t("pages:landing.link_login")}
              />
            </Col>
          </Row>
          <Description>{t("pages:landing.description_p_signup")}</Description>
          <Row justify="center">
            <Col>
              <LargeButtonLink
                to="/signup"
                name={t("pages:landing.link_signup")}
              />
            </Col>
          </Row>
          <Row justify="center">
            {message && <MessageBox message={message} />}
          </Row>
        </LayoutContent>
        <LayoutFooter>
          <LinkList>
            <LinkItem>
              <TextLinkExternal
                href={t("messagesAndSettings:privacy_policy_href")}
                name={t("messagesAndSettings:privacy_policy_link")}
                newtab
              />
            </LinkItem>
            {shouldDisplayAccessibilityLink ? (
              <LinkItem>
                <TextLinkExternal
                  href={t("messagesAndSettings:accessibility_statement_href")}
                  name={t("messagesAndSettings:accessibility_statement_link")}
                  newtab
                />
              </LinkItem>
            ) : null}
          </LinkList>
        </LayoutFooter>
      </Layout>
    );
  }
}

const LogoHolder = styled.div`
  background-color: ${colour_royal_purple};
  border-radius: 50%;
  height: 18em;
  width: 18em;
  line-height: 20em;
  margin: 3em 1em;
  display: flex;
`;

const Logo = styled.img`
  margin: auto;
  display: block;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 2.5rem 1rem;
`;

const Description = styled.p`
  text-align: center;
  margin: 0.9rem 0 0.3rem 0;
  width: 100%;
`;

const LinkList = styled.ul`
  text-align: center;
`;

const LinkItem = styled.li`
  & + li {
    margin-top: 0.5rem;
  }
`;

export default withTranslation()(Landing);
