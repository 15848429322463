import React from "react";

import styled from "styled-components";

import { fontsize_larger } from "../../assets/css/variables";

type Props = {
  className?: string;
  text: string;
};

function Heading(props: Props) {
  return <h1 className={props.className}>{props.text}</h1>;
}

const StyledHeading = styled(Heading)`
  font-size: ${fontsize_larger};
  margin: 1em 0 0 0;
  text-align: center;
`;

export default StyledHeading;
