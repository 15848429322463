import React, { Component } from "react";

import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_royal_purple,
} from "../../assets/css/variables";
import type { TextareaValue } from "../../utils/types";
import FieldsetWrapper from "../layouts/FieldsetWrapper";
import { TagButton } from "../subcomponents/ButtonAction";
import TextareaField from "../subcomponents/TextareaField";

type Props = {
  autoFocus?: boolean;
  className?: string;
  heading?: string;
  id: string;
  label: string;
  placeholder: string;
  onChangeNotify: (val: Readonly<TextareaValue>) => void;
  tags: Array<{ name: string; fragment: string }>;
  value: string;
};

type State = {
  value: string;
};

class Textarea extends Component<Props, State> {
  textareaInput: { current: null | HTMLTextAreaElement };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value ? props.value : "",
    };

    this.textareaInput = React.createRef();

    this.focusTextarea = this.focusTextarea.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  notifyChanges() {
    // Notify parent of change through onChange passed in props.
    const { onChangeNotify, id } = this.props;
    const { value } = this.state;

    const isValid = value && value.length ? true : false;

    onChangeNotify({
      id,
      isValid,
      value: { text: value },
      contentKey: "text",
    });
  }

  focusTextarea() {
    // Need to check current is not null before each action... because flow
    if (this.textareaInput.current) {
      this.textareaInput.current.scrollIntoView();
    }
    if (this.textareaInput.current) {
      this.textareaInput.current.focus();
    }
  }

  async onButtonClick({ currentTarget }) {
    await this.setState(({ value: oldValue }) => {
      const newLine = oldValue.length ? "\n" : "";
      this.focusTextarea();
      return {
        value: oldValue + newLine + currentTarget.value + " ",
      };
    });

    this.notifyChanges();
  }

  async onChange({ currentTarget }: any) {
    await this.setState(function () {
      return {
        value: currentTarget.value || "",
      };
    });

    this.notifyChanges();
  }

  render() {
    const { className, heading, id, label, tags, placeholder } = this.props;
    return (
      <FieldsetWrapper id={id} label={label} heading={heading}>
        <TextareaField
          autoFocus={this.props.autoFocus}
          className={className}
          id={id}
          placeholder={placeholder}
          onChangeNotify={this.onChange}
          textareaRef={this.textareaInput}
          rows={5}
          value={this.state.value}
        />
        <TagContainer>
          {tags.map((tag, key) => {
            return (
              <TagButton
                key={id + key}
                name={tag.name}
                onClick={this.onButtonClick}
                value={tag.fragment}
              />
            );
          })}
        </TagContainer>
      </FieldsetWrapper>
    );
  }
}

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    margin: 10px 5px;
  }
`;

const StyledTextarea = styled(Textarea)`
  border-radius: 2rem;
  border: 1px solid ${colour_mine_shaft};
  overflow: auto;

  &:focus {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    outline: none;
  }

  // Prevents Firefox highlighting
  &:required {
    box-shadow: none;
  }
`;

export default StyledTextarea;
