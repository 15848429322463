import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import session from "../../session";
import moment from "moment";

import VisuallyHidden from "./VisuallyHidden";
import {
  colour_royal_purple,
  colour_school_bus_yellow,
  fontsize_base,
} from "../../assets/css/variables";
import type { IconSize, ImageAttr } from "../../utils/types";

type Props = {
  className?: string;
  onCheckboxChange: Function;
  image: ImageAttr;
  imageOlderYP?: ImageAttr;
  isSelected: boolean;
  label: string;
  t: TFunction;
  value: string;
  size: IconSize;
};

// Mostly a copy/paste job from ./Checkbox.jsx
// this component diverged too much from the original
class ImageCheckbox extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.props.onCheckboxChange(this.props.value);
  }

  render() {
    const {
      className,
      label,
      value,
      isSelected,
      image,
      imageOlderYP,
      size,
      t,
    } = this.props;

    const userDOB = new Date(session?.user?.dob || Date.now());
    const userAgeinYears = moment(Date.now()).diff(moment(userDOB), "years");

    return (
      <label className={className}>
        <VisuallyHidden>
          <Input
            type="checkbox"
            value={value}
            checked={isSelected}
            onChange={this.onChange}
          />
        </VisuallyHidden>
        <BackgroundCircle>
          {userAgeinYears < 13 ? (
            //if young person is under 13:
            <IconContainer>
              {image?.src ? (
                <Image src={image.src} alt="" size={size} />
              ) : (
                <Image
                  src={t("fieldsetImages:life.default.src")}
                  alt=""
                  size={size}
                />
              )}
            </IconContainer>
          ) : (
            //if young person is 13 or older:
            <IconContainer>
              {imageOlderYP ? (
                <Image src={imageOlderYP.src} alt="" size={size} />
              ) : (
                <Image
                  src={
                    image?.src
                      ? image.src
                      : t("fieldsetImages:life.default.src")
                  }
                  alt=""
                  size={size}
                />
              )}
            </IconContainer>
          )}
        </BackgroundCircle>
        <LabelText>{label}</LabelText>
      </label>
    );
  }
}

const IconContainer = styled.span`
  align-items: center;
  display: block;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: -20%;
  width: 100%;
`;

const Image = styled.img<{ size: IconSize }>`
  display: block;
  margin: 0 auto;
  width: ${(props) => (props.size === "small" ? `calc(80% * 2 / 3)` : `80%`)};
`;

const BackgroundCircle = styled.span`
  position: relative;
  display: block;
  border-radius: 50%;
  height: 0;
  width: 100%;
  padding-bottom: 100%; /* square element padding hack */
  z-index: -1;
`;

const LabelText = styled.span`
  margin: 0 auto;
  display: block;
  font-size: ${fontsize_base};
  margin-top: -2rem;
  min-height: 2.3rem; /* two lines */
  text-align: center;
  word-wrap: break-word;
  width: 100%;
`;

const Input = styled.input`
  &:checked + ${BackgroundCircle} {
    background-color: ${colour_school_bus_yellow};
  }

  &:checked ~ ${LabelText} {
    font-weight: bold;
  }

  &:hover + ${BackgroundCircle} {
    box-shadow: 0 0 0 4px ${colour_school_bus_yellow};
  }

  &:focus ~ ${BackgroundCircle} {
    box-shadow: 0 0 8px 2px ${colour_royal_purple};
  }
`;

export default withTranslation()(styled(ImageCheckbox)`
  cursor: pointer;
  padding: 8px; /* box-shadow */
  padding-top: 10%;
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 10rem;
  width: 100%;
  height: 100%;
`);
