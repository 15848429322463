import React from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import {
  colour_caribbean_green,
  colour_royal_purple,
} from "../../assets/css/variables";

type BarProps = {
  className?: string;
  isActive: boolean;
};

type BarsProps = {
  className?: string;
  count: number;
  position: number;
  t: TFunction;
};

const FrogContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    height: 0.8em;
    width: 3em;
  }
`;

function ProgressBar(props: BarProps) {
  return <div className={props.className} />;
}

function ProgressBars(props: BarsProps) {
  const { t } = props;
  // Make an array with undefined values of count length.
  // Fill them with true or false (isActive) depending on given position.
  const bars = Array(...Array(props.count)).map(function (value, key) {
    return key + 1 === props.position;
  });
  const renderBars = bars.map(function (isActive, index) {
    let frogImage = t("images:frog_leaping.src");
    let frogImageAlt = t("images:frog_leaping.alt");
    if (index === 0) {
      frogImage = t("images:frog_sitting.src");
      frogImageAlt = t("images:frog_sitting.alt");
    }

    return (
      <FrogContainer key={index}>
        {isActive && (
          <img src={frogImage} alt={props.position + " " + frogImageAlt} />
        )}
        <StyledProgressBar isActive={isActive} />
      </FrogContainer>
    );
  });

  return <div className={props.className}>{renderBars}</div>;
}

const StyledProgressBar = styled(ProgressBar)`
  background-color: ${(props) =>
    props.isActive ? colour_caribbean_green : colour_royal_purple};
  border-radius: 2em;
  height: 0.4em;
  margin: 0.4em;
  width: 2em;
`;

const StyledProgressBars = styled(ProgressBars)`
  align-items: flex-end;
  display: flex;
  justify-content: center;
`;

export default withTranslation()(StyledProgressBars);
