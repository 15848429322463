import React, { ChangeEventHandler, Component, SyntheticEvent } from "react";

import { type i18n } from "i18next";
import moment from "moment";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { Row, Col } from "react-grid-system";

import type User from "../../../auth/User";
import session from "../../../session";
import getAxiosErrorMessage from "../../../utils/getAxiosErrorMessage";
import type {
  History,
  IntInput,
  Message,
  TextInput,
} from "../../../utils/types";
import {
  EMAILREGEX,
  PASSWORDLENGTH,
  PHONEREGEX,
} from "../../../utils/validation";
import DateOfBirth from "../../subcomponents/DateOfBirth";
import LanguageChoice from "../../subcomponents/LanguageChoice";
import MessageBox from "../../subcomponents/MessageBox";
import SubmitButton from "../../subcomponents/SubmitButton";
import TextField from "../../subcomponents/TextField";
import { AxiosError } from "axios";
import { ApiHocProps, withApi } from "../../../api";

/** TODO: This form diverges from other forms in that is has logic around the isDirty
 * state of the fields. We may end up wanting to strip this feature.
 * This logic is used to:
 * - help control what we send to the server (probably overkill as the backend _may_ not care if we send too much)
 * - disable the input button if fields haven't been changed
 */
type EnhancedTextField = TextInput & {
  isDirty: boolean;
  originalVal: string;
};

type AccountUserFormData = {
  fullName: EnhancedTextField;
  email: EnhancedTextField;
  language: IntInput & { isDirty: boolean };
  password: EnhancedTextField;
  passwordConfirm: EnhancedTextField;
  phoneNumber: EnhancedTextField;
  dob: EnhancedTextField;
};

type Fields = keyof AccountUserFormData;

type TextFieldArgs = { name: Fields; tKey: string; type?: string };

type Props = {
  t: TFunction;
  history: History;
  i18n: i18n;
  user?: User | null;
  onUserUpdated: (sessionData: unknown) => unknown;
};

type State = {
  form: AccountUserFormData;
  dobFields: {
    day: number;
    month: number;
    year: number;
  };
  message?: Message;
  awaitingResponse: boolean;
  userId?: number;
};

let idCounter = 0;

class AccountDetails extends Component<ApiHocProps<Props>, State> {
  id: string;
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.id = `account_details_form_${idCounter++}`;

    this.state = {
      awaitingResponse: false,
      dobFields: { day: 0, month: 0, year: 0 },
      form: {
        fullName: AccountDetails.initFieldState(""),
        email: AccountDetails.initFieldState(""),
        language: {
          error: "",
          isValid: true,
          value: 0,
          isDirty: false,
        },
        password: AccountDetails.initFieldState(""),
        passwordConfirm: AccountDetails.initFieldState(""),
        phoneNumber: AccountDetails.initFieldState(""),
        dob: AccountDetails.initFieldState(""),
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onDOBChange = this.onDOBChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static initFieldState(defaultValue) {
    // fields on this screen cannot start off in an invalid state.
    return {
      value: defaultValue,
      isValid: true,
      error: "",
      isDirty: false,
      originalVal: defaultValue,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user || {};

    if (props.user.id !== state?.userId) {
      const dob = user.dob ? moment(user.dob, "YYYY-MM-DD") : null;
      let dobFields = { day: 0, month: 0, year: 0 };

      if (dob) {
        dobFields = {
          day: dob.date(),
          // zero indexed... https://momentjs.com/docs/#/get-set/month/
          month: dob.month() + 1,
          year: dob.year(),
        };
      }

      const userId = user.id;

      // User phone_number comes through as null we need an empty string for form to work.
      const phoneNumString = user.phone_number ? user.phone_number.value : "";
      // Default will be the stack fallback which 'should' be language with id 1 in the database.
      const language_id = user.language_id ? user.language_id : 1;

      return {
        form: {
          fullName: AccountDetails.initFieldState(user.name),
          email: AccountDetails.initFieldState(user.email),
          // We can always include language.
          // Can't be processed in batch with others because number is not string.
          // Flow unable to cope with the values being passed through same functions.
          // Only dirty if changed. We don't need to save default lang. Might revisit.
          // Remain null if not touched, save if user switches between - once changed isDirty.
          language: {
            value: language_id,
            error: "",
            isDirty: false,
            isValid: true,
          },
          phoneNumber: AccountDetails.initFieldState(phoneNumString),
          password: AccountDetails.initFieldState(""),
          passwordConfirm: AccountDetails.initFieldState(""),
          dob: AccountDetails.initFieldState(user.dob),
        },
        dobFields,
        userId,
      };
    }
    return null;
  }

  getIdFor(name) {
    return `${this.id}_${name}`;
  }

  onDOBChange: ChangeEventHandler<HTMLSelectElement> = async (evt) => {
    const dateField = evt.currentTarget.name;
    const dateValue = evt.currentTarget.value;

    await this.setState(function (state) {
      return {
        dobFields: {
          ...state.dobFields,
          [dateField]: dateValue,
        },
      };
    });

    // strict get date
    const dob = moment(
      `${this.state.dobFields.year}-${this.state.dobFields.month}-${this.state.dobFields.day}`,
      "YYYY-M-D",
      true,
    );

    if (dob.isValid()) {
      this.updateFormField("dob", dob.format("YYYY-MM-DD"));
    }
  };

  onChange(evt: SyntheticEvent<HTMLInputElement>) {
    const fieldName = evt.currentTarget.name;
    const fieldValue = evt.currentTarget.value;

    this.updateFormField(fieldName, fieldValue);
  }

  componentDidUpdate(_prevProps, prevState) {
    // We use componentDidUpdate to make sure we are acting on the values
    // updated in updateFormField()

    if (this.state.form.password.value !== prevState.form?.password?.value) {
      // We check validation for changed fields only, so when editing the password field
      // we need to trigger validation of passwordConfirm manually
      if (
        this.state.form["password"].value.length > 0 &&
        !this.state.form["passwordConfirm"].isDirty
      ) {
        this.setState(function (state) {
          const oldField = state.form["passwordConfirm"];
          return {
            form: {
              ...state.form,
              passwordConfirm: { ...oldField, isDirty: true, isValid: false },
            },
          };
        });
      }

      // if a user clears their change to the password field, we need to remember to
      // make passwordConfirm valid and not dirty too
      else if (
        this.state.form["password"].value === "" &&
        this.state.form["passwordConfirm"].isDirty
      ) {
        this.setState(function (state) {
          const oldField = state.form["passwordConfirm"];
          return {
            form: {
              ...state.form,
              passwordConfirm: { ...oldField, isDirty: false, isValid: true },
            },
          };
        });
      }
    }

    if (!this.state.awaitingResponse && prevState.awaitingResponse) {
      // if we have stopped awaiting a response, make sure the form is not dirty
      this.setState(function (state) {
        return {
          form: {
            fullName: { ...state.form.fullName, isDirty: false },
            email: { ...state.form.email, isDirty: false },
            language: { ...state.form.language, isDirty: false },
            phoneNumber: { ...state.form.phoneNumber, isDirty: false },
            password: { ...state.form.password, isDirty: false },
            passwordConfirm: { ...state.form.passwordConfirm, isDirty: false },
            dob: { ...state.form.dob, isDirty: false },
          },
        };
      });
    }
  }

  onLanguageChange(lang: { code: Readonly<string>; id: Readonly<number> }) {
    this.setState(function (prevState) {
      return {
        form: {
          ...prevState.form,
          language: { value: lang.id, isDirty: true, isValid: true, error: "" },
        },
      };
    });
  }

  updateFormField(fieldName, fieldValue) {
    const { t, i18n } = this.props;

    this.setState((prevState) => {
      const oldField = prevState.form[fieldName];
      const isDirty = fieldValue !== oldField.originalVal;
      const isValid = !isDirty ? true : this.validate(fieldName, fieldValue);
      const tKey = `pages:account.account_form_label_validation_${fieldName}`;

      return {
        form: {
          ...prevState.form,
          [fieldName]: {
            ...oldField,
            isValid,
            value: fieldValue,
            isDirty,
            // only show an error message if we have one and the field is invalid
            error: !isValid && i18n.exists(tKey) ? t(tKey) : "",
          },
        },
      };
    });
  }

  validate(fieldName, fieldValue) {
    const { password } = this.state.form;

    // copied from Signup.jsx, TODO: consider form library and centralise validation
    switch (fieldName) {
      case "fullName":
        return fieldValue.length > 2 && fieldValue.length < 64;
      case "email":
        return EMAILREGEX.test(fieldValue);
      case "dob": {
        const dob = moment(fieldValue, "YYYY-MM-DD");
        return dob.isValid();
      }
      case "password":
        return fieldValue.length >= PASSWORDLENGTH;
      case "passwordConfirm":
        return password.value.length > 0 && password.value === fieldValue;
      case "phoneNumber":
        // Empty phone is valid - so user can delete this optional field.
        return (
          fieldValue === "" || PHONEREGEX.test(fieldValue.replace(/\s+/g, ""))
        );
      default:
        break;
    }
  }

  readyToSubmit() {
    const { form } = this.state;
    // only care about the validation for the dirty form elements
    const dirtyFields = Object.keys(form).filter(function (fieldName) {
      return form[fieldName].isDirty;
    });

    if (dirtyFields.length) {
      return dirtyFields.every(function (key) {
        const field = form[key];
        return field.isValid;
      });
    }

    return false;
  }

  async onSubmit(evt: SyntheticEvent<EventTarget>) {
    evt.preventDefault();

    if (this.state.awaitingResponse) {
      return;
    }
    await this.setState({ awaitingResponse: true });

    const { form } = this.state;
    const editedFields = Object.keys(form).filter(function (key) {
      return form[key].isDirty;
    });
    // initialize the post data with the bare minimum
    const postData: Record<string, string> = {
      name: form.fullName.value,
      email: form.email.value,
    };

    editedFields.forEach(function (key) {
      const field = form[key];

      // TODO: probably neater to use the final form values as keys so we don't need to re-map them here...
      switch (key) {
        case "phoneNumber":
          postData.phone_number = field.value;
          break;
        case "language":
          postData.language_id = field.value;
          break;
        case "fullName":
        case "email":
        case "passwordConfirm":
        default:
          postData[key] = field.value;
          break;
      }
    });

    const id = this.props.user && this.props.user.id;
    let response;

    try {
      // in _theory_ this.props.user could be undefined.
      // Flow can't tell that we couldn't be submitting if props.user is undefined
      if (id) {
        response = await this.props.Api.put("/user/" + id, postData);
      }
    } catch (e) {
      // Copied from Account.jsx - we probably want to rethink and centralise.
      // Consider including handling of api error as well as the message in the util.
      // TODO We probably also would rather go to login page.
      // This would handle deAuth and route user back to referrer once logged in.
      // We should add the ability to display a passed message on the login page.
      if (
        (e as AxiosError).response &&
        (e as AxiosError)?.response?.status === 401
      ) {
        return session.deAuth(() =>
          this.props.history.push("/", {
            message: { text: getAxiosErrorMessage(e), type: "error" },
          }),
        );
      }

      return this.setState({
        message: {
          type: "error",
          text: getAxiosErrorMessage(e),
        },
      });
    } finally {
      this.setState({
        awaitingResponse: false,
      });
    }

    const { t } = this.props;
    if (response && this.props.onUserUpdated) {
      this.setState({
        message: { text: t("pages:account.account_form_message_success") },
      });
      this.props.onUserUpdated(response.data);
    }
  }

  renderTextField({ name, tKey, type }: TextFieldArgs) {
    const { form } = this.state;
    const { t } = this.props;

    return (
      <TextField
        // TODO: could probably generate the translation key using the field name
        // the error message in onChange?
        label={t(tKey)}
        id={this.getIdFor(name)}
        name={name}
        onChange={this.onChange}
        value={form[name].value as string}
        valid={form[name].isValid}
        error={form[name].error}
        type={type}
      />
    );
  }

  render() {
    const { t, user } = this.props;
    const { form, dobFields, message } = this.state;
    const isProfile = user && user.role === "profile";

    if (!this.props.user) {
      return null;
    }

    return (
      <div>
        <MessageBox message={message} />
        <Row justify="center">
          <Col xs={12} md={8} lg={6}>
            <form onSubmit={this.onSubmit}>
              {this.renderTextField({
                name: "fullName",
                tKey: "pages:account.account_form_label_name",
              })}
              {this.renderTextField({
                name: "email",
                tKey: "pages:account.account_form_label_email",
                type: !isProfile ? "email" : "hidden",
              })}
              {this.renderTextField({
                name: "phoneNumber",
                tKey: "pages:account.account_form_label_phone_number",
                type: !isProfile ? "tel" : "hidden",
              })}
              {/* TODO: fix that like the signup page we don't report any errors the user about their */}
              <DateOfBirth {...dobFields} onChange={this.onDOBChange} />
              <LanguageChoice
                legend={t("messagesAndSettings:language_choice")}
                onChangeNotify={this.onLanguageChange}
                selected={form.language.value}
              />
              {!isProfile &&
                this.renderTextField({
                  name: "password",
                  tKey: "pages:account.account_form_label_password",
                  type: "password",
                })}
              {!isProfile &&
                this.renderTextField({
                  name: "passwordConfirm",
                  tKey: "pages:account.account_form_label_password_confirm",
                  type: "password",
                })}
              <SubmitButton
                disabled={!this.readyToSubmit()}
                label={t("pages:account.account_form_label_submit_button")}
                name="signup"
                noIcon={true}
              />
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(withApi(AccountDetails));
