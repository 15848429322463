import type { IntInput, TextInput } from "./types";
import { getLocaleDefaults } from "./l10n";

// Currently only need to validate Int and Text input type fields - can add more if necessary.
function allInputsValid(inputs: Array<IntInput | TextInput>) {
  return inputs.map((i) => i.isValid).every((i) => i === true);
}

// Regex used by w3 for html5 email type input.
export const EMAILREGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

//  Length requirement for a new password is 12 but legacy users may still need to log in with 8
export const PASSWORDLENGTH = 12;
export const LEGACYPASSWORDLENGTH = 8;

// Phone numbers have different international codes, so retrieve them by TLD
export const PHONEREGEX = getLocaleDefaults().phoneValidation;

export { allInputsValid };
