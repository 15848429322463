import i18n from "i18next";

class I18nString extends String {
  key: string;
  value?: string;

  constructor(key: string, value?: string) {
    super(value);
    this.key = key;
    this.value = value;

    // Setup correct length
    Object.defineProperty(this, "length", {
      get: () => {
        return this.valueOf().length;
      },
    });
  }

  toString() {
    return this.value ? this.value : i18n.t(this.key);
  }

  valueOf() {
    return this.toString();
  }
}

export default I18nString;
