type LogoutSymbolProps = {
  title: string;
};

export const LogoutSymbol = ({ title }: LogoutSymbolProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="10"
      role="img"
    >
      <title>{title}</title>
      <path
        d="M7.6995 8.81c.0034-1.1253.9288-2.0507 2.0541-2.0541h8.2171V8.81h-8.217v14.38h8.217v2.0542h-8.217c-1.1254-.0035-2.0508-.9288-2.0542-2.0542V8.81ZM22.26 14.973l-2.605-2.605 1.4524-1.4523L26.1917 16l-5.0843 5.0843-1.4523-1.4523 2.6049-2.605h-6.7628v-2.054H22.26Z"
        fill="#fff"
      />
      <circle
        cx="16"
        cy="16"
        r="15.339"
        fill="none"
        stroke="#fff"
        strokeWidth="1.6"
      />
    </svg>
  );
};
