import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_royal_purple,
} from "../../assets/css/variables";

type Props = {
  className?: string;
  to: string;
  name?: string;
};

function TextLink(props: Props) {
  const classes = props.className ? { className: props.className } : {};
  return (
    <Link {...classes} to={props.to}>
      {props.name}
    </Link>
  );
}

const StyledTextLink = styled(TextLink)`
  color: ${colour_mine_shaft};
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: ${colour_royal_purple};
    text-decoration: underline;
  }
`;

export default StyledTextLink;
