import React from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import {
  colour_royal_purple,
  colour_school_bus_yellow,
  colour_white,
} from "../../assets/css/variables";

type Props = {
  className: string;
  t: TFunction;
};

function ButtonReciteMe(props: Props) {
  const { t, className } = props;

  return (
    <button className={className} type="button" onClick={window.loadService}>
      <div>{t("messagesAndSettings:recite_me_button")}</div>
    </button>
  );
}

const StyledButtonReciteMe = styled(ButtonReciteMe)`
  background: ${colour_royal_purple};
  border: 3px solid ${colour_white};
  border-radius: 2em;
  color: ${colour_white};
  cursor: pointer;
  margin-top: 1rem;

  div {
    margin: 0.3rem;
  }

  &:focus {
    border: 3px solid ${colour_school_bus_yellow};
    box-shadow: none;
  }
`;

export default withTranslation()(StyledButtonReciteMe);
