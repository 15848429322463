import React, { Component } from "react";

import Helmet from "react-helmet";
import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import { LargeButtonLink } from "../subcomponents/ButtonLink";
import { Header } from "../Header";

type Props = {
  className?: string;
  t: TFunction;
};

class Thanks extends Component<Props> {
  render() {
    const { t, className } = this.props;

    return (
      <Layout>
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutContent>
          {/* No header section on this page - set title manually */}
          <Helmet>
            <title>{"One app | " + t("pages:thanks.heading")}</title>
          </Helmet>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <LargeHeading className={className}>
                {t("pages:thanks.heading")}
              </LargeHeading>
              <SmallSubheading className={className}>
                {t("pages:thanks.subheading")}
              </SmallSubheading>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={6} md={5} lg={3}>
              <Frog
                src={t("images:frog.src")}
                alt={t("images:frog.alt")}
                aria-label={t("images:frog.alt")}
              />
            </Col>
          </Row>
        </LayoutContent>
        <LayoutFooter>
          <LargeButtonLink
            name={t("pages:thanks.button_link")}
            to="/choose-service"
          />
        </LayoutFooter>
      </Layout>
    );
  }
}

const LargeHeading = styled.h1`
  font-size: 3rem;
  margin: 2rem 0 0 0;
  text-align: center;
`;

const SmallSubheading = styled.h2`
  margin: 0;
  font-weight: 100;
  text-align: center;
`;

const Frog = styled.img`
  margin: auto;
  margin-top: 2rem;
  /* max-height: 10rem;
  max-width: 10rem; */
`;

export default withTranslation()(Thanks);
