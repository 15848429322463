import i18n from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { colour_white } from "../assets/css/variables";
import HeaderSection from "../components/layouts/HeaderSection";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../components/layouts/Layout";
import LanguageChoice from "../components/subcomponents/LanguageChoice";
import StyledRadioButton from "../components/subcomponents/RadioButton";
import SubmitButton from "../components/subcomponents/SubmitButton";
import { VerticalSpacing } from "../components/VerticalSpacing";

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Fieldset = styled.fieldset`
  background-color: ${colour_white};
  border-radius: 2rem;
  margin: 0;
  max-width: 30ch;
  padding: 0 1rem;
  width: 100%;

  legend {
    font-size: 1.2rem;
    font-weight: bold;
    margin: auto;
  }
`;

const HeadingContainer = styled.div`
  background-image: ${() => {
    const { t } = useTranslation("images");
    return "url(" + t("get_started.src") + ");";
  }};

  aspect-ratio: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12rem;
  text-align: center;
  width: 100%;
`;

interface Language {
  code: string;
  id: number;
}

interface Country {
  name: string;
  code: string;
}

export const GetStarted = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const [language, setLanguage] = useState<Language>({ code: "en", id: 1 });
  const [country, setCountry] = useState<Country | null>();

  function onLanguageChange(lang: Language): void {
    // Change the language
    i18n.changeLanguage(lang.code);
    // Save the language outside the user
    localStorage.setItem("language", JSON.stringify(lang));
    // Set the language into state for other components
    setLanguage(lang);
  }

  const countries: Array<Country> = [
    {
      name: t("get_started.uk_or_europe"),
      code: "uk",
    },
    {
      name: t("get_started.australia"),
      code: "com",
    },
    {
      name: t("get_started.new_zealand"),
      code: "nz",
    },
    {
      name: t("get_started.anywhere_else"),
      code: "uk",
    },
  ];

  const onSubmit = (event: any) => {
    event.preventDefault();

    localStorage.setItem("country", JSON.stringify(country));
    history.push("/login");
  };

  return (
    <Layout>
      <LayoutHeader>
        <HeaderSection
          hideLogoutButton
          hideAccountButton
          hideHomeButton
          pageTitle={"Get Started"}
        ></HeaderSection>
      </LayoutHeader>
      <LayoutContent>
        <Content>
          <HeadingContainer>
            <h1>{t("get_started.title")}</h1>
          </HeadingContainer>
          <VerticalSpacing size={1} />
          <form onSubmit={onSubmit}>
            <Fieldset>
              <legend>{t("get_started.select_country")}</legend>
              {countries.map((item: Country) => {
                return (
                  <StyledRadioButton
                    isSelected={item.name === country?.name}
                    key={item.name}
                    label={item.name}
                    onChangeNotify={() => {
                      setCountry(item);
                    }}
                    value={item.name}
                  />
                );
              })}
            </Fieldset>
            <VerticalSpacing size={1} />
            <SubmitButton disabled={!country} />
          </form>
        </Content>
      </LayoutContent>
      <LayoutFooter>
        <LanguageChoice
          inline
          onChangeNotify={onLanguageChange}
          selected={language.id}
          legend={t("messagesAndSettings:language_choice")}
        />
      </LayoutFooter>
    </Layout>
  );
};
