import React, { useState, FC } from "react";
import { createContext } from "react";
import { getTheme, Theme } from "../../assets/css/themes";

interface SessionContextValue {
  theme: Theme;
  updateTheme: (name: string) => void;
}

// export this so we can use context in class components
export const SessionContext = createContext<SessionContextValue | undefined>(
  undefined,
);

// this is the component that wraps out provider
export const SessionProvider: FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const defaultTheme = getTheme();
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const updateTheme = (name: string) => {
    if (name !== theme.name) {
      setTheme(getTheme(name));
    }
  };

  return (
    <SessionContext.Provider value={{ theme, updateTheme }}>
      {children}
    </SessionContext.Provider>
  );
};

// export this so we can use context in functional components
export const useSession = () => {
  const sessionContext = React.useContext(SessionContext);
  if (sessionContext === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return sessionContext;
};
