import React from "react";

import { colour_royal_purple } from "../../../assets/css/variables";

type Props = {
  title?: string;
  color?: string;
};

export default function EditSymbol(props: Props) {
  const fillColour = props.color ? props.color : colour_royal_purple;
  const ariaHide = props.title ? undefined : "true";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
      clipRule="evenodd"
      viewBox="0 0 68 68"
      width="100%"
      height="100%"
      role="img"
      aria-hidden={ariaHide}
    >
      <title>{props.title}</title>
      <g fill={fillColour} fillRule="nonzero" id="Layer 1">
        <path d="M34 0C15.223 0 0 15.223 0 34s15.223 34 34 34 34-15.223 34-34S52.777 0 34 0m0 2.591c17.319 0 31.411 14.09 31.411 31.409S51.319 65.409 34 65.409C16.681 65.409 2.589 51.319 2.589 34S16.681 2.591 34 2.591" />
        <path d="M50.993 24.82l-8.134-8.134a5.758 5.758 0 0 1 3.678-1.32c1.546 0 2.992.604 4.087 1.69a5.718 5.718 0 0 1 1.688 4.085 5.74 5.74 0 0 1-1.319 3.679M31.1 44.752l-3.325-3.324L46.251 22.95l3.325 3.324L31.1 44.752zM41.405 18.11l3.399 3.4-18.476 18.476-3.4-3.398L41.405 18.11zm10.651-2.486a7.8 7.8 0 0 0-11.043 0l-20.249 20.24a1.011 1.011 0 0 0-.287.58l-1.5 11.112a1.025 1.025 0 0 0 1.003 1.153c.045 0 .091 0 .136-.008l6.693-.905a1.02 1.02 0 1 0-.27-2.019l-5.367.723 1.047-7.75 8.157 8.158c.188.188.452.301.716.301.263 0 .528-.107.716-.301l20.248-20.242a7.761 7.761 0 0 0 2.292-5.525 7.729 7.729 0 0 0-2.292-5.517" />
      </g>
    </svg>
  );
}
