import React, { HTMLProps } from "react";
import styled from "styled-components";

import {
  colour_caribbean_green,
  colour_mine_shaft,
} from "../../assets/css/variables";

// Generic function to enhance the primary component
function getCompositeComponent<
  T,
  U extends { [key: string]: React.ComponentType },
>(PrimaryComponent: T, components: U): T & U {
  const enhancedComponent = PrimaryComponent as T & U;

  Object.keys(components).forEach((key) => {
    (enhancedComponent as any)[key] = components[key];
  });

  return enhancedComponent;
}

const ListItemContents = styled.div`
  color: ${colour_mine_shaft};
`;

const ListItem: React.FC<HTMLProps<HTMLLIElement>> = ({
  children,
  ...props
}) => (
  <li {...props}>
    <ListItemContents>{children}</ListItemContents>
  </li>
);

const StyledListItem = styled(ListItem)`
  & + & {
    margin-top: 1rem;
  }
`;

const ListComponent = styled.ul`
  color: ${colour_caribbean_green};
  list-style: disc outside;
`;

const List = getCompositeComponent(ListComponent, { ListItem });

export { StyledListItem as ListItem };
export default List;
