import React from "react";

import styled from "styled-components";

import {
  colour_red_ribbon,
  colour_royal_purple,
  colour_white,
} from "../../assets/css/variables";
import type { Message } from "../../utils/types";

type Props = {
  message?: Message;
};

function MessageBox(props: Props) {
  const { message } = props;
  // We should only ever have one message box displayed at a time.
  // In future, if not, we might want to make id (used for testing) unique.
  return message && message.text && message.text.length ? (
    <MessageContainer type={message.type}>
      <MessageContent id="message" type={message.type}>
        {message.text}
      </MessageContent>
    </MessageContainer>
  ) : null;
}

const MessageContainer = styled.div<{ type?: string }>`
  background-color: ${(props) =>
    props.type && props.type === "error" ? colour_white : colour_royal_purple};
  border-radius: 1rem;
  text-align: center;
`;

const MessageContent = styled.p<{ type?: string }>`
  color: ${(props) =>
    props.type && props.type === "error" ? colour_red_ribbon : colour_white};
  padding: 1rem 1rem;
  margin: 0;
`;

export default MessageBox;
