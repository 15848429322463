import React, { ChangeEventHandler } from "react";

import styled from "styled-components";

type Props = {
  className?: string;
  id: string;
  onChangeNotify: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  textareaRef?: any;
  rows: number;
  value: string;
  autoFocus?: boolean;
};

function TextareaField(props: Props) {
  const {
    autoFocus,
    textareaRef,
    className,
    id,
    onChangeNotify,
    placeholder,
    rows,
    value,
  } = props;

  return (
    <Textarea
      className={className}
      id={id}
      onChange={onChangeNotify}
      placeholder={placeholder}
      // https://styled-components.com/docs/advanced#refs
      ref={textareaRef}
      rows={rows}
      value={value}
      autoFocus={autoFocus}
    />
  );
}

TextareaField.defaultProps = { rows: 5 };

const Textarea = styled.textarea`
  padding: 0.5em 1.5em;
  resize: none;
  width: 100%;
`;

export default TextareaField;
