import { Location } from "history";
import { Message } from "./types";

const isObject = (value: any): value is object => {
  return typeof value === "object" && value !== null;
};

export const getLocationMessage = (location: Location): Message | undefined => {
  const state = location.state;

  if (!isObject(state)) {
    return;
  }

  const message = "message" in state && state.message;

  if (isObject(message) && "text" in message && "type" in message) {
    return message as Message;
  }
};
