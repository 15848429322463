import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useApi } from "../../api";
import { colour_royal_purple } from "../../assets/css/variables";
import HeaderSection from "../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import { Loading } from "../subcomponents/Loading";
import { ModalConfirmation } from "../ModalConfirmation";
import { StatementList } from "../StatementList";
import session from "../../session";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import getJSON from "../../utils/getJson";

// This is a new TS type, the previous flow type is in use in other files
export interface Statement {
  id: number;
  type: string;
  label: string;
  created_at: string;
  cases: Array<Case>;
  has_been_sent: number;
}

interface Case {
  organisation_name: string;
  worker_type: string;
  created_at: string;
  userMessages?: Array<UserMessage>;
  last_message_at: string;
}

interface UserMessage {
  concern_id: number;
  content: string;
  content_id: number;
  created_at: string;
  id: number;
  message_type: "Downloaded" | "Sent" | "Assigned" | "Responded";
  recipient_id: number;
  sender_id: number;
  updated_at: string;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  text-align: center;

  h2 {
    color: ${colour_royal_purple};
    font-size: 100%;
  }
`;

export const Statements = () => {
  const { t } = useTranslation("pages");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [statementId, setStatementId] = useState<number | undefined>(undefined);
  const [statements, setStatements] = useState<Array<Statement> | undefined>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [message, setMessage] = useState({ text: "" });
  const Api = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getJSON(Api, "/statement");
      setStatements(data);
      setLoading(false);
    };

    fetchData().catch((error) => {
      setLoading(false);
      setMessage({ text: getAxiosErrorMessage(error) });
    });
  }, []);

  /*
   * Opens the confirmation modal and sets the statement id
   * Passed to the bin icon button on a statement
   */
  const handleTrashClick = (id: number) => {
    setIsOpen(!isOpen);
    setStatementId(id);
  };

  /*
   * Closes the confirmation modal and unsets the statement id, as it
   * was not acted on this time
   * Passed to the cancel buttons on the confirmation modal
   */
  const closeModal = () => {
    setIsOpen(!isOpen);
    setStatementId(undefined);
  };

  /*
   * Attempts to delete a statement by id, then refreshes the statements list
   * and closes the modal
   * Passed to the confirm button on the confirmation modal
   */
  const confirmDelete = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
  ) => {
    event.currentTarget.disabled = true;

    try {
      await Api.delete("/statement/" + id);
    } catch (error) {
      setMessage({ text: getAxiosErrorMessage(error) });
      setIsOpen(false);
      return;
    }

    let statements;
    try {
      statements = await getJSON(Api, "/statement");
    } catch (error) {
      setMessage({ text: getAxiosErrorMessage(error) });
      setIsOpen(false);
      return;
    }

    setStatements(statements);
    setIsOpen(false);
    setStatementId(undefined);
  };

  const name =
    session.user && session.user.first_name
      ? session.user.first_name
      : t("messagesAndSettings:defaults.user_firstname");

  return (
    <Layout>
      <LayoutHeader>
        <HeaderSection
          heading={t("statements.heading", { name })}
          subheading={t("statements.subheading")}
          message={message}
        ></HeaderSection>
      </LayoutHeader>
      <LayoutContent>
        <Content>
          {loading ? <Loading /> : null}
          {statements && !loading ? (
            <StatementList
              statements={statements}
              notifyTrashClick={handleTrashClick}
            />
          ) : null}
          <ModalConfirmation
            isOpen={isOpen}
            onCancel={closeModal}
            onConfirm={confirmDelete}
            closeLabel={t("statements.modal_close")}
            confirmLabel={t("statements.modal_confirm")}
            cancelLabel={t("statements.modal_cancel")}
            id={statementId || -1}
          >
            <ModalContent>
              <h2>{t("statements.modal_heading")}</h2>
              <p>{t("statements.modal_p1")}</p>
              <p>{t("statements.modal_p2")}</p>
            </ModalContent>
          </ModalConfirmation>
        </Content>
      </LayoutContent>
    </Layout>
  );
};
