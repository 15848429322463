type StatementArrowSymbolProps = {
  title?: string;
};

export const StatementArrowSymbol = ({ title }: StatementArrowSymbolProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.752"
      height="15.753"
      viewBox="0 0 24.752 15.753"
      role="img"
    >
      <title>{title}</title>;
      <g fillRule="evenodd">
        <path
          data-name="Path 528"
          d="M22.5 7.877a1.125 1.125 0 0 1-1.125 1.125H1.125a1.125 1.125 0 0 1 0-2.25h20.25A1.125 1.125 0 0 1 22.5 7.877Z"
        />
        <path
          data-name="Path 529"
          d="M24.421 7.077a1.125 1.125 0 0 1 0 1.593l-6.75 6.75a1.1264 1.1264 0 1 1-1.596-1.59l5.959-5.953-5.959-5.954a1.1289 1.1289 0 1 1 1.6-1.593l6.75 6.75Z"
        />
      </g>
    </svg>
  );
};
