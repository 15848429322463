import React, { Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";

import HeaderSection from "../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import type { History, Message } from "../../utils/types";
import getJSON from "../../utils/getJson";
import session from "../../session";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  t: TFunction;
};

type State = {
  awaitingResponse: boolean;
  offeredScenarios: Array<string>;
  isYouthJustice: boolean;
  message: Message;
};

class Rights extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.state = {
      awaitingResponse: true,
      offeredScenarios: [],
      isYouthJustice: false,
      message: { text: "" },
    };
  }

  async componentDidMount() {
    let offeredScenarios: string[] = [];
    try {
      // Fetch the offered scenarios for a user's selected services.
      if (session.user !== null) {
        offeredScenarios = await getJSON(
          this.props.Api,
          "/user/services/scenarios",
        );
      }
    } catch (err) {
      return this.handleError(err);
    }
    this.setState({
      awaitingResponse: false,
      offeredScenarios: offeredScenarios,
    });

    if (
      offeredScenarios.indexOf("youth-justice-and-me") > -1 ||
      offeredScenarios.indexOf("my-world") > -1
    ) {
      this.setState({
        isYouthJustice: true,
      });
    }
  }

  handleError(e) {
    if (e.response)
      switch (e.response.status) {
        case 401:
        case 403:
          return session.deAuth(() =>
            this.props.history.push("/", {
              message: { text: getAxiosErrorMessage(e), type: "error" },
            }),
          );
        default:
      }
    return this.setState({
      awaitingResponse: false,
      message: {
        text: getAxiosErrorMessage(e),
        type: "error",
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:rights.h2_title")}
            subheading={t("pages:rights.subheading")}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              {this.state.isYouthJustice === true ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("yjrights:content"),
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("rights:content"),
                  }}
                />
              )}
            </Col>
          </Row>
        </LayoutContent>
      </Layout>
    );
  }
}
export default withTranslation()(withApi(Rights));
