import React, { Component, Fragment, MouseEventHandler } from "react";

import { withTranslation, Trans } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_dusty_grey } from "../../../assets/css/variables";
import type { Consents } from "../../../utils/types";
import { ImgTextButtonAction } from "../../subcomponents/ButtonAction";
import TextLinkExternal from "../../subcomponents/TextLinkExternal";
import PrivacyControl from "./PrivacyControl";

type AllProps = {
  context?: string;
  sections?: Set<"account" | "send" | "signup">;
  t: TFunction;
  userInfo?: { consents: Array<Consents>; id: number };
};

type ToggleProps = {
  textOnlyView: boolean;
  t: TFunction;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type SectionProps = {
  t: TFunction;
  context?: string;
};

type State = {
  textOnlyView: boolean;
};

class PrivacyContentAll extends Component<AllProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      textOnlyView: true,
    };

    this.toggleView = this.toggleView.bind(this);
  }

  show(section) {
    const { sections } = this.props;
    // Don't show if we specify sections and it's not in the set.
    if (sections && !sections.has(section)) {
      return false;
    }
    return true;
  }

  // Todo pull out into utility toggle function (also used in Confirmable link and Checkbox)
  toggleView() {
    this.setState(({ textOnlyView }) => ({
      textOnlyView: !textOnlyView,
    }));
  }

  render() {
    const { t, userInfo } = this.props;
    const { textOnlyView } = this.state;
    const consents = userInfo ? userInfo.consents : undefined;
    const id = userInfo ? userInfo.id : undefined;

    return (
      <Fragment>
        <ViewToggle
          onClick={this.toggleView}
          t={t}
          textOnlyView={textOnlyView}
        />
        {this.show("signup") && (
          <Fragment>
            {textOnlyView && <SignupTextOnly {...this.props} />}
            {!textOnlyView && <SignupWithPics {...this.props} />}
            {consents && consents.indexOf("one_momo") > -1 ? (
              <PrivacyControl purpose="consent_momo" userId={id} />
            ) : null}
          </Fragment>
        )}
        {this.show("send") && (
          <Fragment>
            {textOnlyView && <SendTextOnly {...this.props} />}
            {!textOnlyView && <SendWithPics {...this.props} />}
            {consents && consents.indexOf("one_third_party") > -1 ? (
              <PrivacyControl purpose="consent_third_party" userId={id} />
            ) : null}
          </Fragment>
        )}
        {this.show("account") && (
          <Fragment>
            {textOnlyView && <AccountTextOnly {...this.props} />}
            {!textOnlyView && <AccountWithPics {...this.props} />}
            {consents && consents.indexOf("one_momo") > -1 ? (
              <PrivacyControl purpose="forget_account" userId={id} />
            ) : null}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function ViewToggle(props: ToggleProps) {
  const { t, textOnlyView, onClick } = props;
  const textImage = {
    src: t("images:quick_read_black.src"),
    pos: "left" as const,
    alt: t("images:quick_read_black.alt"),
  };
  const wordsImage = {
    src: t("images:words_with_pictures_black.src"),
    pos: "left" as const,
    alt: t("images:words_with_pictures_black.alt"),
  };

  return (
    <ToggleContainer>
      <span>{t("pages:privacy.span_header_toggle")}</span>
      <div>
        <QuickReadButton
          disabled={textOnlyView}
          image={textImage}
          name={t("pages:privacy.content_button_read")}
          onClick={onClick}
        />
        <WordsPicturesButton
          disabled={!textOnlyView}
          image={wordsImage}
          name={t("pages:privacy.content_button_pics")}
          onClick={onClick}
        />
      </div>
    </ToggleContainer>
  );
}

function SignupTextOnly(props: SectionProps) {
  const { t, context } = props;
  return (
    <PrivacyTextContent>
      <h3>{t("pages:privacy.content_h3_signup_subheading")}</h3>
      <p>{t("pages:privacy.content_p_signup_txt1")}</p>
      <p>{t("pages:privacy.content_p_signup_txt2")}</p>
      <p>{t("pages:privacy.content_p_signup_txt3")}</p>
      {context === "account" ? (
        <p>
          <Trans i18nKey="pages:privacy.content_p_privacy_link">
            <TextLinkExternal
              name={t("pages:privacy.content_a_privacy_link_text")}
              href={"https://mindofmyown.org.uk/privacy-policy-2/"}
              newtab
            />
          </Trans>
        </p>
      ) : null}
      {context === "signup" ? (
        <p>
          <Trans i18nKey="pages:signup.privacy_p_decline">
            Placeholder for decline text
            <TextLinkExternal
              name={t("pages:signup.link_privacy_policy")}
              href={"https://mindofmyown.org.uk/privacy-policy-2/"}
              newtab
            />
            <TextLinkExternal
              name={t("pages:signup.link_email_us_privacy")}
              href={"mailto:" + t("pages:privacy.contact_email")}
            />
          </Trans>
        </p>
      ) : null}
    </PrivacyTextContent>
  );
}

function SignupWithPics(props: SectionProps) {
  const { t, context } = props;
  return (
    <PrivacyImagesContent>
      <div>
        <h3>{t("pages:privacy.content_h3_signup_subheading")}</h3>
        <img
          src={t("images:no_share_email.src")}
          alt={t("images:no_share_email.alt")}
        />
        <p>{t("pages:privacy.content_p_signup_pic1")}</p>
        <img
          src={t("images:birthday_cake.src")}
          alt={t("images:birthday_cake.alt")}
        />
        <p>{t("pages:privacy.content_p_signup_pic2")}</p>
        <img src={t("images:worker.src")} alt={t("images:worker.alt")} />
        <p>{t("pages:privacy.content_p_signup_pic3")}</p>
        <img src={t("images:lock_box.src")} alt={t("images:lock_box.alt")} />
        <p>{t("pages:privacy.content_p_signup_pic4")}</p>
        {context === "account" ? (
          <p>
            <Trans i18nKey="pages:privacy.content_p_privacy_link">
              <TextLinkExternal
                name={t("pages:privacy.content_a_privacy_link_text")}
                href={"https://mindofmyown.org.uk/privacy-policy-2/"}
                newtab
              />
            </Trans>
          </p>
        ) : null}
        {context === "signup" ? (
          <p className="full-width">
            <Trans i18nKey="pages:signup.privacy_p_decline">
              Placeholder for decline text
              <TextLinkExternal
                name={t("pages:signup.link_privacy_policy")}
                href={"https://mindofmyown.org.uk/privacy-policy-2/"}
                newtab
              />
              <TextLinkExternal
                name={t("pages:signup.link_email_us_privacy")}
                href={"mailto:" + t("pages:privacy.contact_email")}
              />
            </Trans>
          </p>
        ) : null}
      </div>
    </PrivacyImagesContent>
  );
}

function SendTextOnly(props: SectionProps) {
  const { t } = props;
  return (
    <PrivacyTextContent>
      <h3>{t("pages:privacy.content_h3_send_subheading")}</h3>
      <p>{t("pages:privacy.content_p_send_txt1")}</p>
      <p>{t("pages:privacy.content_p_send_txt2")}</p>
    </PrivacyTextContent>
  );
}

function SendWithPics(props: SectionProps) {
  const { t } = props;
  return (
    <PrivacyImagesContent>
      <div>
        <h3>{t("pages:privacy.content_h3_send_subheading")}</h3>
        <img
          src={t("images:button_push.src")}
          alt={t("images:button_push.alt")}
        />
        <p>{t("pages:privacy.content_p_send_pic1")}</p>
        <img
          src={t("images:worker_mail.src")}
          alt={t("images:worker_mail.alt")}
        />
        <p>{t("pages:privacy.content_p_send_pic2")}</p>
        <img src={t("images:worker.src")} alt={t("images:worker.alt")} />
        <p>{t("pages:privacy.content_p_send_pic3")}</p>
        <img src={t("images:lock_box.src")} alt={t("images:lock_box.alt")} />
        <p>{t("pages:privacy.content_p_send_pic4")}</p>
      </div>
    </PrivacyImagesContent>
  );
}

function AccountTextOnly(props: SectionProps) {
  const { t } = props;
  const email = t("pages:privacy.contact_email");
  return (
    <PrivacyTextContent>
      <h3>{t("pages:privacy.content_h3_account_subheading")}</h3>
      <p>{t("pages:privacy.content_p_account_txt1")}</p>
      <p>{t("pages:privacy.content_p_account_txt2")}</p>
      <p>{t("pages:privacy.content_p_account_txt3")}</p>
      <p>{t("pages:privacy.content_p_account_txt5")}</p>
      <p>{t("pages:privacy.content_p_account_txt6")}</p>
      <p>{t("pages:privacy.content_p_account_txt7")}</p>
      <p>{t("pages:privacy.content_p_account_txt8")}</p>
      <p>
        <Trans i18nKey="pages:privacy.content_p_account_txt4">
          Placeholder for Right to be forgotten request
          <TextLinkExternal name={email} href={"mailto:" + email} />.
        </Trans>
      </p>
    </PrivacyTextContent>
  );
}

function AccountWithPics(props: SectionProps) {
  const { t } = props;
  const email = t("pages:privacy.contact_email");
  return (
    <PrivacyImagesContent>
      <div>
        <h3>{t("pages:privacy.content_h3_account_subheading")}</h3>
        <img src={t("images:deleted.src")} alt={t("images:deleted.alt")} />
        <p>{t("pages:privacy.content_p_account_pic1")}</p>
        <img
          src={t("images:worker_bin.src")}
          alt={t("images:worker_bin.alt")}
        />
        <p>{t("pages:privacy.content_p_account_pic2")}</p>
        <img
          src={t("images:worker_statements.src")}
          alt={t("images:worker_statements.alt")}
        />
        <p>{t("pages:privacy.content_p_account_pic3")}</p>
        <img
          src={t("images:yp_speaking.src")}
          alt={t("images:yp_speaking.alt")}
        />
        <p>{t("pages:privacy.content_p_account_pic4")}</p>
        <img src={t("images:deleted.src")} alt={t("images:deleted.alt")} />
        <p>
          <Trans i18nKey="pages:privacy.content_p_account_txt4">
            Placeholder for Right to be forgotten request
            <TextLinkExternal name={email} href={"mailto:" + email} />.
          </Trans>
        </p>
      </div>
    </PrivacyImagesContent>
  );
}

const PrivacyImagesContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem auto;

  div {
    display: flex;
    flex-direction: column;
  }

  img {
    height: 5rem;
    margin-top: 1rem;
    width: 100%;
  }

  p {
    margin: 0.5rem auto 1rem auto;
    text-align: center;
    width: 60%;
  }

  .full-width {
    width: 100%;
    text-align: left;
  }
`;

const PrivacyTextContent = styled.div`
  width: 100%;
`;

const ToggleContainer = styled.div`
  display: flex;
  margin: 1.5rem auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 30rem;

  div {
    display: inline-flex;
    width: 100%;
  }
`;

const QuickReadButton = styled(ImgTextButtonAction)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:disabled {
    box-shadow: inset -9px 0px 10px -7px ${colour_dusty_grey};
  }
`;

const WordsPicturesButton = styled(ImgTextButtonAction)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:disabled {
    box-shadow: inset 9px 0px 10px -7px ${colour_dusty_grey};
  }
`;

export default withTranslation()(PrivacyContentAll);
