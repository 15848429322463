import React, { type ReactNode as Node } from "react";

import styled from "styled-components";

import { fontsize_large } from "../../assets/css/variables";
import Checkbox from "./Checkbox";

type ConfirmableSectionProps = {
  confirmText: string;
  content: Node;
  handleChange: Function;
  heading: string;
  isConfirmed: boolean;
};

const Heading = styled.h2`
  font-size: ${fontsize_large};
  margin-top: 2rem;
  text-align: center;
`;

const ConfirmationCheckbox = styled(Checkbox)`
  margin: 2rem 0;

  input {
    & ~ label {
      font-weight: bold;
      line-height: initial;

      &:before {
        line-height: 2rem;
      }
    }
  }
`;

function ConfirmableSection(props: ConfirmableSectionProps) {
  const { content, confirmText, isConfirmed, heading, handleChange } = props;
  return (
    <div>
      <Heading>{heading}</Heading>
      {content}
      <ConfirmationCheckbox
        onCheckboxChange={handleChange}
        value={!isConfirmed}
        isSelected={isConfirmed}
        label={confirmText}
      />
    </div>
  );
}

export default styled(ConfirmableSection)`
  /* ie11 gets weird with flex, we need to restrict the container */
  max-width: 100%;
`;
