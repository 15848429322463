/* COLOURS */
// Primary Theme colours
export const colour_royal_purple = "#8368AE";
export const colour_caribbean_green = "#00DD87";
export const colour_red_ribbon = "#F50E40";
export const colour_deepgreen_cyanturquoise = "#118673";

// Highlight colours
export const colour_east_side = "#A082CB";
export const colour_spring_green = "#2CFBAA";
export const colour_blue_cerulean = "#00A1D1";
export const colour_school_bus_yellow = "#FFE000";

// Achromatic colours
export const colour_mine_shaft = "#333333";
export const colour_dusty_grey = "#999999";
export const colour_alto = "#DDDDDD";
export const colour_gallery = "#EEEEEE";
export const colour_modal_overlay = "rgba(0, 0, 0, 0.2)";
export const colour_white = "#FFFFFF";
export const colour_transparent = "transparent";
export const colour_shadow = "rgba(0, 0, 0, 0.25)";
export const colour_shadow_light = "rgba(0, 0, 0, 0.1)";

/* FONTS */
export const font_primary = '"ModernEra", Georgia';

// Sizes
export const fontsize_small = "0.8rem";
export const fontsize_base = "1rem";
export const fontsize_large = "1.2rem";
export const fontsize_larger = "1.5rem";
export const radiobutton_diameter = "2.25rem";
export const slider_height = "1.8rem";

/* LAYOUT */
export const gutter_px = 15; // in pixels
