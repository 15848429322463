import { Row, Col } from "react-grid-system";

import Layout, {
  LayoutContent,
  LayoutHeader,
} from "../components/layouts/Layout";
import DevInfo from "../components/subcomponents/DevInfo";
import HeaderSection from "../components/layouts/HeaderSection";

export const Version = () => {
  return (
    <Layout>
      <LayoutHeader>
        <HeaderSection hideLogoutButton={true} hideAccountButton={true} />
      </LayoutHeader>
      <LayoutContent>
        <Row justify="center">
          <Col xs={12} md={8} lg={6}>
            <h1>Version Info</h1>
            <DevInfo />
          </Col>
        </Row>
      </LayoutContent>
    </Layout>
  );
};
