import { Row, Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import session from "../session";
import { type Theme } from "../assets/css/themes";
import { colour_white } from "../assets/css/variables";
import { AccountSymbol } from "../assets/images/symbols/AccountSymbol";
import { StatementsSymbol } from "../assets/images/symbols/StatementsSymbol";
import { useSession } from "./contexts/SessionContext";
import { LogoutButton } from "./LogoutButton";

type HeaderProps = {
  hideHomeButton?: boolean;
  hideLogoutButton?: boolean;
  hideAccountButton?: boolean;
};

export const Header = ({
  hideHomeButton,
  hideLogoutButton,
  hideAccountButton,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { theme } = useSession();
  const location = useLocation();

  return (
    <StyledHeader theme={theme}>
      <Container style={{ width: "100%" }}>
        <Row justify="center">
          <Content>
            {!hideHomeButton ? (
              <Logo active={location.pathname === "/choose-service"}>
                <Link to={session.isAuth ? "/choose-service" : "/"}>
                  <img
                    src={t(theme.assets.identity.src)}
                    alt={t(theme.assets.identity.alt)}
                  />
                </Link>
              </Logo>
            ) : null}
            <nav>
              <ul>
                {!hideLogoutButton ? (
                  <NavItem active={location.pathname === "/statements"}>
                    <Link to="/statements">
                      <StatementsSymbol title={t("images:statements.alt")} />
                    </Link>
                  </NavItem>
                ) : null}
                {!hideAccountButton ? (
                  <NavItem active={location.pathname === "/account"}>
                    <Link to="/account">
                      <AccountSymbol title={t("images:account.alt")} />
                    </Link>
                  </NavItem>
                ) : null}
                {!hideLogoutButton ? (
                  <NavItem>
                    <LogoutButton />
                  </NavItem>
                ) : null}
              </ul>
            </nav>
          </Content>
        </Row>
      </Container>
    </StyledHeader>
  );
};

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  min-height: 3rem;
  width: 100%;

  img {
    flex-grow: 0;
    height: 100%;
    width: auto;
  }

  ul {
    display: flex;
    margin: 0;
  }

  li {
    margin-left: 1rem;
  }
`;

const Logo = styled.div<{ active?: boolean }>`
  border-top: 4px solid transparent;
  height: 100%;
  padding: 0.5rem 0;

  ${(props) =>
    props.active
      ? `border-bottom: 4px solid ${colour_white};`
      : "border-bottom: 4px solid transparent;"}

  a {
    border-radius: 4px;
    display: block;
    height: 100%;

    img {
      height: 44px;
      max-width: unset;
    }

    &:focus {
      outline: 2px solid ${colour_white};
    }
  }
`;

const NavItem = styled.li<{ active?: boolean }>`
  border-top: 4px solid transparent;
  color: ${colour_white};
  margin-top: 0;
  padding: 0.5rem 0;

  ${(props) =>
    props.active
      ? `border-bottom: 4px solid ${colour_white};`
      : "border-bottom: 4px solid transparent;"}
`;

const StyledHeader = styled.header<{ theme: Theme }>`
  align-items: center;
  background: ${(props) => {
    return props.theme.branding.colour_bg_contrast;
  }};
  display: flex;
  padding: 0.6rem 0;
  width: 100%;

  nav {
    ul {
      list-style: none;
    }

    a {
      align-items: center;
      border-radius: 50%;
      display: block;
      height: 44px;
      width: 44px;

      &:active,
      &:focus {
        box-shadow: 0 0 0.5rem 0.25rem ${colour_white};
      }

      svg {
        height: 44px;
        width: 44px;
      }
    }

    button {
      height: 44px;
      width: 44px;
    }
  }
`;
