import React, { Component } from "react";

import styled from "styled-components";

import { colour_mine_shaft, colour_white } from "../../assets/css/variables";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import type { Message } from "../../utils/types";
import StyledRadioButton, {
  InlineRadioButton,
} from "../subcomponents/RadioButton";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  className?: string;
  legend: string;
  onChangeNotify: (vals: { code: string; id: number }) => void;
  selected: number;
  inline?: boolean;
};

type State = {
  awaitingResponse: boolean;
  languages: Array<any>;
  message: Message;
};

class LanguageChoice extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: false,
      languages: [],
      message: { text: "" },
    };
  }

  async componentDidMount() {
    // Get languages available on this stack, native apps require all languages
    const languagePath = window.usingCordova
      ? "/language?language=all"
      : "/language";
    let langs = [];

    try {
      langs = await this.props.Api.get(languagePath).then((res) => {
        this.setState({ awaitingResponse: false });
        return res.data;
      });
    } catch (e) {
      this.setState({
        awaitingResponse: false,
        message: { text: getAxiosErrorMessage(e), type: "error" },
      });
      return false;
    }
    // Create a key value pair list for display
    this.setState({ languages: langs });
  }

  render() {
    const { legend, onChangeNotify, selected, inline } = this.props;

    let buttonType;

    const singleLanguage = this.state.languages.length === 1;

    return (
      <Container showBorder={!inline} singleLanguage={singleLanguage}>
        <LanguageFieldset alignment={!!inline}>
          {inline ? (
            <HiddenLegend>{legend}</HiddenLegend>
          ) : (
            <VisibleLegend>{legend}</VisibleLegend>
          )}

          {this.state.languages
            .sort((a, b) => a.id - b.id)
            .map(function (lang) {
              if (inline) {
                buttonType = (
                  <InlineRadioButton
                    isSelected={lang.id === selected}
                    key={lang.id}
                    label={lang.name}
                    onChangeNotify={function () {
                      return onChangeNotify({ code: lang.code, id: lang.id });
                    }}
                    value={lang.id}
                  />
                );
              } else {
                buttonType = (
                  <StyledRadioButton
                    isSelected={lang.id === selected}
                    key={lang.id}
                    label={lang.name}
                    onChangeNotify={function () {
                      return onChangeNotify({ code: lang.code, id: lang.id });
                    }}
                    value={lang.id}
                  />
                );
              }
              return buttonType;
            }, this)}
        </LanguageFieldset>
      </Container>
    );
  }
}

const VisibleLegend = styled.legend`
  background: ${colour_white};
  font-weight: bold;
  left: 1.5rem;
  padding: 0 0.5rem;
  position: relative;
  top: -0.6rem;
`;

const HiddenLegend = styled.legend`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

const Container = styled.div<{ showBorder: boolean; singleLanguage: boolean }>`
  border: ${(props) =>
    props.showBorder ? "1px solid " + colour_mine_shaft : "unset"};
  border-radius: 2em;
  display: ${(props) => (props.singleLanguage ? "none" : "block")};
  width: 100%;
`;

const LanguageFieldset = styled.fieldset<{ alignment: boolean }>`
  border: none;
  padding: 0;
  margin: 0;
  margin-top: ${(props) => (props.alignment ? "1rem" : "0")};
  padding-left: ${(props) => (props.alignment ? "0" : "0.6em")};
  text-align: ${(props) => (props.alignment ? "center" : "unset")};

  div + div {
    &::before {
      content: "|";
    }
  }
`;

LanguageFieldset.defaultProps = {
  alignment: false,
};

export default withApi(LanguageChoice);
