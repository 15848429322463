import { AxiosInstance } from "axios";
import AuthToken from "./auth/AuthToken";
import AxiosAuthenticator from "./auth/axiosAuthenticator";
import MockAuthenticator from "./auth/mockAuthenticator";
import User from "./auth/User";
import i18n from "i18next";

// TODO: CRA deliberately hobbles moment.js locales. Replace.
import moment from "moment";

const session = {
  authenticator: AxiosAuthenticator,

  /**
   * Does authentication with an Authenticator
   * @param {Object} credentials
   * @param {string} credentials.email
   * @param {string} credentials.password
   * @param sx Success function
   * @param fx Failure function, should take an error message
   * @return {Promise<void>}
   */
  doAuth: async function (Api: AxiosInstance, credentials, sx, fx) {
    if (!this.authenticator) {
      // default to something sensible.
      // @ts-expect-error - we're setting this to our mock usage
      this.authenticator = MockAuthenticator;
    }
    const report = await this.authenticator.execute(Api, credentials);
    if (report.data) {
      this.setSession(report.data);
      if (sx && typeof sx === "function") {
        sx();
      }
    } else {
      if (fx && typeof fx === "function") {
        fx(report.errorMessage ? report.errorMessage : null);
      }
    }
  },

  /**
   * Tidies up when you quit and runs a callback if you gave it one.
   */
  deAuth: function (cb?: () => void) {
    User.purgeLocal();
    // remove the chosen service when we get here
    localStorage.removeItem("chosen-service");
    i18n.changeLanguage(i18n.options.fallbackLng?.[0]);
    AuthToken.purgeLocal();
    if (cb && typeof cb === "function") {
      cb();
    }
  },

  /**
   * Stashes session items in local storage
   * @param authData Data returned by authentication process.
   */
  setSession: function (authData) {
    User.stashLocal(authData);
    const country_code =
      this.user && this.user.country_code
        ? this.user.country_code.replace("-", "_")
        : "";

    if (this.user) {
      i18n.changeLanguage(this.user.language_code + "-" + country_code);
    }
    AuthToken.stashLocal(authData);
  },

  /**
   * Hydrates token from local storage.
   * @return {AuthToken | null}
   */
  get token() {
    return AuthToken.fetchLocal();
  },

  /**
   * Hydrates user from local storage.
   * @return {User | null}
   */
  get user() {
    return User.fetchLocal();
  },

  /**
   * Returns current session authentication status
   * @return { boolean }
   */
  get isAuth() {
    if (!this.token) {
      return false;
    } else {
      const expiresAt = moment(this.token.auth_token_expires_at);
      const now = moment();
      const isFuture = now < expiresAt;
      if (!isFuture) {
        this.deAuth();
      }
      return expiresAt && isFuture;
    }
  },
};

export default session;
