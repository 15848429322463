import React, { Fragment } from "react";

import Helmet from "react-helmet";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import ButtonReciteMe from "../subcomponents/ButtonReciteMe";
import { Header } from "../Header";
import Heading from "../subcomponents/Heading";
import MessageBox from "../subcomponents/MessageBox";
import Subheading from "../subcomponents/Subheading";

import type { Message } from "../../utils/types";

type Props = {
  heading?: string;
  subheading?: string;
  message?: Message;
  pageTitle?: string;
  hideLogoutButton?: boolean;
  hideAccountButton?: boolean;
  hideHomeButton?: boolean;
  icon?: string;
};

function HeaderSection(props: Props) {
  const {
    heading,
    subheading,
    message,
    pageTitle,
    hideHomeButton,
    hideLogoutButton,
    hideAccountButton,
    icon,
  } = props;

  const shouldDisplayReciteMe = !window.usingCordova;

  let messageError: Message | null = null;
  let messagePlain: Message | null = null;

  if (message) {
    switch (message.type) {
      case "error":
        // We display below page title.
        messageError = message;
        break;
      default:
        // We display above page title.
        messagePlain = message;
        break;
    }
  }

  return (
    <Fragment>
      <Helmet>
        {/* If a header section does not need a heading, it can provide just a
        pageTitle instead. Both must be null checked because Flow does not know
        it can expect one or the other. Consider changing to string literal type. */}
        {heading && <title>{"One app | " + (heading || pageTitle)}</title>}
        {pageTitle && <title>{"One app | " + pageTitle}</title>}
      </Helmet>
      <Header
        hideHomeButton={hideHomeButton}
        hideLogoutButton={hideLogoutButton}
        hideAccountButton={hideAccountButton}
      />
      <Container style={{ width: "100%" }}>
        {shouldDisplayReciteMe ? <ButtonReciteMe /> : null}
        {messagePlain && (
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <MessageBoxHolder>
                <MessageBox message={messagePlain} />
              </MessageBoxHolder>
            </Col>
          </Row>
        )}
        {icon && <Icon src={icon} alt="" />}
        {heading && <Heading text={heading} />}
        {subheading && <Subheading text={subheading} />}
        <Row justify="center">
          <Col xs={12} md={8} lg={6}>
            {messageError && <MessageBox message={messageError} />}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const MessageBoxHolder = styled.div`
  margin-top: 1rem;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  display: block;
  margin: 3rem auto 1rem auto;
  width: min(33%, 10rem);
`;

export default HeaderSection;
