import { FormEvent, useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import { Loading } from "../components/subcomponents/Loading";
import { VerticalSpacing } from "../components/VerticalSpacing";
import HeaderSection from "../components/layouts/HeaderSection";
import Layout, {
  LayoutContent,
  LayoutHeader,
} from "../components/layouts/Layout";
import RadioButton from "../components/subcomponents/RadioButton";
import SubmitButton from "../components/subcomponents/SubmitButton";
import VisuallyHidden from "../components/subcomponents/VisuallyHidden";
import session from "../session";
import { useApi } from "../api";

interface Service {
  country: string;
  distance: number | null;
  id: number;
  name: string;
}

export const ChooseService = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const Api = useApi();

  const [loadingServices, setLoadingServices] = useState<boolean>(true);
  const [loadingUserServices, setLoadingUserServices] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [services, setServices] = useState<Array<Service> | null>(null);
  const [userServices, setUserServices] = useState<Array<number> | null>(null);
  const [chosenService, setChosenService] = useState<string | null>(null);

  useEffect(() => {
    // Request and array of the service ids a user is signed up to.
    // We don't use the ones from local storage here as they are not
    // updates by the Services page, only on fresh login
    async function getUserServices() {
      const userServicesResponse = await Api.get<Array<number> | string>(
        "/user/services",
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.status.toString());
          }

          return response.data;
        })
        .catch((error: Error) => {
          return error.message
            ? error.message
            : t("choose_service.error_user_services");
        })
        .finally(() => {
          setLoadingUserServices(false);
        });

      // Our promise came back with an error
      if (typeof userServicesResponse === "string") {
        setError(userServicesResponse);
      }

      if (
        userServicesResponse !== undefined &&
        typeof userServicesResponse === "object"
      ) {
        setUserServices(userServicesResponse);
      }
    }

    // Request all services so that we have their names to match
    // to the ids from getUserServices()
    async function getAllServices() {
      const allServicesResponse = await Api.get<Array<Service> | string>(
        "/organisation",
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.status.toString());
          }

          return response.data;
        })
        .catch((error: Error) => {
          return error.message
            ? error.message
            : t("choose_service.error_all_services");
        })
        .finally(() => {
          setLoadingServices(false);
        });

      // Our promise came back with an error
      if (typeof allServicesResponse === "string") {
        setError(allServicesResponse);
      }

      if (
        allServicesResponse !== undefined &&
        typeof allServicesResponse === "object"
      ) {
        setServices(allServicesResponse);
      }
    }

    getUserServices();
    getAllServices();
  }, [t]);

  if (userServices && userServices.length < 2) {
    if (userServices.length === 1) {
      localStorage.setItem("chosen-service", userServices[0].toString());
    }
    return <Redirect to={"/scenarios"} />;
  }

  const updateSelection = (value: string) => {
    setChosenService(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!chosenService) {
      return;
    }

    try {
      localStorage.setItem("chosen-service", chosenService);
      history.push("/scenarios");
    } catch (err) {
      setError(t("choose_service.error_selecting_service"));
    }
  };

  const userServiceDetails = services?.filter((element) => {
    return userServices?.includes(element.id);
  });

  const username =
    session.user && session.user.first_name
      ? session.user.first_name
      : t("defaults.user_firstname");
  const greeting = t("pages:scenarios.heading") + " " + username;

  return (
    <Layout>
      <LayoutHeader>
        <HeaderSection
          heading={greeting}
          subheading={
            loadingServices || loadingUserServices
              ? " "
              : t("choose_service.heading")
          }
          hideLogoutButton={false}
          hideAccountButton={false}
          message={{ text: error, type: "error" }}
        />
      </LayoutHeader>
      <LayoutContent>
        <Row justify="center">
          <Col xs={12} md={8} lg={6}>
            <VerticalSpacing size={1} />

            {loadingServices || loadingUserServices ? (
              <Loading message={t("pages:scenarios.loading_scenarios")} />
            ) : (
              <Form onSubmit={onSubmit}>
                <RadiosContainer>
                  <VisuallyHidden>
                    <legend>{t("choose_service.legend")}</legend>
                  </VisuallyHidden>

                  {userServiceDetails?.map((item) => {
                    return (
                      <RadioButton
                        key={item.id}
                        label={item.name}
                        value={item.id.toString()}
                        isSelected={item.id.toString() === chosenService}
                        onChangeNotify={updateSelection}
                      />
                    );
                  })}
                </RadiosContainer>

                <VerticalSpacing size={2} />

                <SubmitButton
                  disabled={!chosenService}
                  label={t("choose_service.submit")}
                  noIcon
                />
              </Form>
            )}
          </Col>
        </Row>
      </LayoutContent>
    </Layout>
  );
};

const Form = styled.form`
  margin: auto;
  max-width: 25rem;
  padding: 0 1rem;
  width: 100%;
`;

const RadiosContainer = styled.fieldset`
  border: none;
  margin: auto;
  max-width: 25rem;
  padding: 0;
  width: 100%;
`;
