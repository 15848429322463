import type { Consents } from "../utils/types";

/**
 * Class for User construction, storage, fetching
 * Required because the data blob returned from Auth needs to be separated.
 */
class User {
  id: number;
  email: string;
  first_name: string;
  name: string;
  language_code: string;
  language_id: number;
  country_code: string;
  consented_to: Array<Consents>;
  service_ids: Array<number>;
  dob: string;
  phone_number: string;
  role: "momo" | "profile";
  worker: { email: string; organisation_id: number } | null;
  location: { name: string };

  /**
   * Filters data and assigns to attribs
   * @param {Object} data
   */
  constructor(data: any) {
    this.id = data.id;
    this.email = data.email;
    this.first_name = data.first_name;
    this.name = data.name;
    this.language_code = data.language_code;
    this.language_id = data.language_id;
    this.country_code = data.country_code;
    this.consented_to = data.consented_to;
    this.dob = data.dob;
    this.phone_number = data.phone_number;
    this.role = data.role;
    this.service_ids = data.service_ids;
    this.worker = data.worker
      ? {
          email: data.worker.email,
          organisation_id: data.worker.organisation_id,
        }
      : null;
    this.location = data.location;
  }

  /**
   * Stashes a user (or user data) in local storage
   * @param {Object} user
   */
  static stashLocal(user: User | any) {
    if (!(user instanceof User)) {
      user = new User(user);
    }
    localStorage.setItem("user", JSON.stringify(user));
  }

  /**
   * Fetches a user from local storage
   * @return {User|null}
   */
  static fetchLocal() {
    const fetched = localStorage.getItem("user");
    return fetched ? new User(JSON.parse(fetched)) : null;
  }

  /**
   * Cleans a user from local storage
   */
  static purgeLocal() {
    localStorage.removeItem("user");
  }

  get locationName() {
    return this.location && this.location.name;
  }
}

export default User;
