import React from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_transparent } from "../../assets/css/variables";

type Props = {
  className?: string;
  required: boolean;
  valid: boolean;
  value: string;
  t: TFunction;
};

function InputStateIndicator(props: Props) {
  return <div className={props.className} />;
}

const StyledInputStateIndicator = styled(InputStateIndicator)`
  background: ${(props) =>
    props.value === ""
      ? colour_transparent
      : props.valid
        ? "url(" + props.t("images:tick_circle_green.src") + ")"
        : "url(" + props.t("images:cross_circle_red.src") + ")"};
  background-size: 100% 100%;
  flex: 1 0 1em;
  height: 1em;
  width: 1em;
  margin: 0 1em;
`;

StyledInputStateIndicator.defaultProps = {
  required: false,
  valid: false,
  value: "",
};

export default withTranslation()(StyledInputStateIndicator);
