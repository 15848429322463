import {
  colour_deepgreen_cyanturquoise,
  colour_royal_purple,
} from "./variables";

export interface Theme {
  name: string;
  branding: {
    colour_bg_contrast: string;
    background_image: string;
  };
  assets: {
    identity: {
      src: string;
      alt: string;
    };
  };
}

const DEFAULT_THEME = "default";

export function getTheme(name = DEFAULT_THEME): Theme {
  const theme = themes.find((theme) => {
    return theme.name === name;
  });
  // give it back or rerun and get then default
  return theme ?? getTheme();
}

export const themes = [
  {
    name: "default",
    branding: {
      colour_bg_contrast: colour_royal_purple,
      background_image: "images:backgrounds.default",
    },
    assets: {
      identity: {
        src: "images:logo_mymind.src",
        alt: "images:logo_mymind.alt",
      },
    },
  },
  {
    name: "one",
    branding: {
      colour_bg_contrast: colour_royal_purple,
      background_image: "images:backgrounds.one",
    },
    assets: {
      identity: {
        src: "images:logo.src",
        alt: "images:logo.alt",
      },
    },
  },
  {
    name: "xchange",
    branding: {
      colour_bg_contrast: colour_deepgreen_cyanturquoise,
      background_image: "images:backgrounds.xchange",
    },
    assets: {
      identity: {
        src: "images:logo_xchange.src",
        alt: "images:logo_xchange.alt",
      },
    },
  },
];
