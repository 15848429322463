import React, { Component } from "react";

import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_caribbean_green,
  fontsize_base,
  radiobutton_diameter,
  colour_royal_purple,
} from "../../assets/css/variables";

type Props = {
  className?: string;
  isSelected: boolean;
  label: string;
  onChangeNotify: Function;
  value: string;
};

let idCounter = 0;

class RadioButton extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.id = `${idCounter++}_radio_button`;

    this.onChange = this.onChange.bind(this);
  }

  id: string;

  onChange() {
    const { onChangeNotify, value } = this.props;
    // Notify parent fieldset component of change.
    onChangeNotify(value);
  }

  render() {
    const { className, isSelected, value, label } = this.props;

    return (
      <div className={className}>
        <input
          type="radio"
          id={this.id}
          checked={isSelected}
          onChange={this.onChange}
          value={value}
        />
        <label htmlFor={this.id}>{label}</label>
        <div />
      </div>
    );
  }
}

const StyledRadioButton = styled(RadioButton)`
  display: block;
  margin: 1.2rem 0;
  position: relative;
  text-align: left;
  user-select: none;

  input {
    opacity: 0;
    cursor: pointer;
    height: ${radiobutton_diameter};
    position: absolute;
    width: ${radiobutton_diameter};
  }

  &::before {
    display: none;
  }

  input:checked ~ div {
    &:after {
      background-color: ${colour_caribbean_green};
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      height: 1rem;
      position: absolute;
      width: 1rem;
    }
  }

  input:checked ~ label {
    font-weight: bold;
  }

  input:focus ~ div {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
  }

  label {
    cursor: pointer;
    display: flex;
    font-size: ${fontsize_base};
    font-weight: normal;
    height: 100%;
    position: relative;
    line-height: ${radiobutton_diameter};
    margin-left: 3rem;
    vertical-align: middle;
  }

  div {
    border: 1px solid ${colour_mine_shaft};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: ${radiobutton_diameter};
    padding: calc(0.5rem + 1px);
    pointer-events: none;
    position: absolute;
    top: 0;
    width: ${radiobutton_diameter};
  }
`;

const InlineRadioButton = styled(RadioButton)`
  display: inline-block;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    display: inline;
    height: 0;
    opacity: 0;
    width: 0;
  }

  input:checked ~ label {
    border-bottom: none;
    font-weight: bold;
  }

  input:focus ~ label {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
  }

  label {
    border-bottom: 1.5px solid ${colour_mine_shaft};
    cursor: pointer;
    display: inline;
    font-size: ${fontsize_base};
    font-weight: normal;
    height: 100%;
    position: relative;
    margin: 0.5rem;
  }
`;

export { InlineRadioButton };

export default StyledRadioButton;
