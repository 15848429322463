import React, { MouseEventHandler } from "react";

import styled from "styled-components";

import {
  colour_alto,
  colour_caribbean_green,
  colour_dusty_grey,
  colour_east_side,
  colour_mine_shaft,
  colour_royal_purple,
  colour_school_bus_yellow,
  colour_spring_green,
  colour_shadow,
  colour_white,
  fontsize_base,
  fontsize_large,
} from "../../assets/css/variables";
import BaseButton from "../subcomponents/BaseButton";

export type Props = {
  className?: string;
  disabled?: boolean;
  image?: { src: string; pos: "left" | "right"; alt: string };
  name: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  // Added another optional prop - we might want to consolidate our buttons differently.
  // This value is used Textarea for the tags.
  value?: string;
  "aria-controls"?: string;
  type?: "submit" | "reset" | "button";
};

type ProxyProps = {
  className?: string;
  "aria-controls"?: string;
};

function ButtonAction(props: Props) {
  // we're styling this button so it will always have a className
  const proxyProps: ProxyProps = { className: props.className };
  const { disabled, onClick, image, name, value, type } = props;

  const valueAttr = value ? { value } : {};

  if (props["aria-controls"]) {
    proxyProps["aria-controls"] = props["aria-controls"];
  }

  return (
    <BaseButton
      {...proxyProps}
      disabled={disabled}
      name={name}
      onClick={onClick}
      type={type || "button"}
      {...valueAttr}
    >
      {image && image.pos === "left" && <img src={image.src} alt={image.alt} />}
      <span>{name}</span>
      {image && image.pos === "right" && (
        <img src={image.src} alt={image.alt} />
      )}
    </BaseButton>
  );
}

const StyledButtonAction = styled(ButtonAction)`
  width: 100%;
  margin-top: 1em;
  background: none;
  border-radius: 2em;
  font-size: ${fontsize_large};
  font-weight: 700;
  padding: 0.5em;
  text-align: center;

  &:disabled {
    color: ${colour_mine_shaft};
    cursor: default;
  }

  &:disabled:hover {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0.125rem 0.25rem 0.5rem 0 ${colour_shadow};
    transform: translate(-0.125rem, -0.25rem);
  }
`;

const ColouredButtonAction = styled(StyledButtonAction)`
  &:disabled {
    background-color: ${colour_alto};
  }
  &:disabled:hover {
    background-color: ${colour_alto};
    box-shadow: none;
    text-decoration: none;
    transform: none;
  }
`;

const GreenButtonAction = styled(ColouredButtonAction)`
  background-color: ${colour_caribbean_green};
  width: 100%;
  height: auto;
  min-height: 3.5rem;
  &:hover {
    background-color: ${colour_spring_green};
  }
`;

const GreenToYellowButtonAction = styled(ColouredButtonAction)`
  background-color: ${colour_caribbean_green};
  &:active,
  &:disabled {
    background-color: ${colour_school_bus_yellow};
  }
  &:active:hover,
  &:disabled:hover {
    background-color: ${colour_school_bus_yellow};
  }
  &:hover {
    background-color: ${colour_spring_green};
  }
`;

const PurpleButtonAction = styled(ColouredButtonAction)`
  background-color: ${colour_royal_purple};
  color: ${colour_white};
  height: 3.5rem;
  width: 100%;
  &:hover {
    background-color: ${colour_east_side};
  }
`;

const TagButton = styled(ColouredButtonAction)`
  background-color: ${colour_royal_purple};
  color: ${colour_white};
  flex: 0 1 0;
  font-size: ${fontsize_base};
  height: 2.5rem;
  padding: 0 10px;
  width: auto;

  &:hover {
    background-color: ${colour_east_side};
  }

  span {
    white-space: nowrap;
  }
`;

const TextOnlyButtonAction = styled(StyledButtonAction)`
  border: 1px solid ${colour_dusty_grey};
  font-size: ${fontsize_base};
  flex: 1 0 35%;
  margin-right: 2em;

  &:focus,
  &:hover {
    transform: none;
  }
`;

const ImgTextButtonAction = styled(StyledButtonAction)`
  align-items: center;
  display: flex;
  font-size: ${fontsize_base};
  background-color: ${colour_alto};
  font-weight: 500;
  padding: 0.5em;
  height: 3rem;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;

  img {
    width: 1.5rem;
    margin: 0 0.3rem;
  }

  &:disabled {
    background-color: ${colour_caribbean_green};
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    text-decoration: none;
    transform: none;
  }
`;

export {
  GreenButtonAction,
  GreenToYellowButtonAction,
  ImgTextButtonAction,
  PurpleButtonAction,
  TagButton,
  TextOnlyButtonAction,
};
export default StyledButtonAction;
