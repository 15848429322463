import React from "react";

import i18n, { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
  Container,
  ScreenClassProvider,
  setConfiguration,
} from "react-grid-system";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components";

import { WarningBanner } from "../WarningBanner";
import { useSession } from "../contexts/SessionContext";

setConfiguration({ maxScreenClass: "xl" });

type Props = {
  // TODO Specify children's type better
  children: any;
};

type SectionProps = {
  children: React.ReactNode | Array<React.ReactNode>;
  className?: string;
};

const FullWidthContainer = styled(Container)`
  width: 100%;
`;

const MainContainer = ({ className, children }: SectionProps) => (
  <main className={className}>
    <FullWidthContainer>{children}</FullWidthContainer>
  </main>
);

const FooterContainer = ({ className, children }: SectionProps) => (
  <footer className={className}>
    <FullWidthContainer>{children}</FullWidthContainer>
  </footer>
);

const LayoutRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Content = styled(MainContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Footer = styled(FooterContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Background = styled.div<{ translation: TFunction }>`
  background-image: ${(props) => {
    const { theme, translation } = props;
    return "url(" + translation(theme.branding.background_image) + ")";
  }};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -10;
`;

function Layout(props: Props) {
  const { children } = props;
  const { t } = useTranslation();
  const childrenArray = React.Children.toArray(children);

  // these children are pure components thus we can check against the constructor
  // instanceof would not apply here
  const headerChildren = childrenArray.filter(function (c) {
    return React.isValidElement(c) && c.type === Header;
  })[0];
  const contentChildren = childrenArray.filter(function (c) {
    return React.isValidElement(c) && c.type === Content;
  })[0];
  const footerChildren = childrenArray.filter(function (c) {
    return React.isValidElement(c) && c.type === Footer;
  })[0];

  // Ask i18n for the current language
  const lang = i18n.language?.split("-")[0] || "en";
  // Grab the theme
  const { theme } = useSession();

  return (
    <ScreenClassProvider>
      <LayoutRoot>
        <Helmet htmlAttributes={{ lang }} />
        <ThemeProvider theme={theme}>
          <Background translation={t} />
          {headerChildren}
          {contentChildren}
          {footerChildren}
          <WarningBanner />
        </ThemeProvider>
      </LayoutRoot>
    </ScreenClassProvider>
  );
}

export {
  Header as LayoutHeader,
  Content as LayoutContent,
  Footer as LayoutFooter,
};
export default Layout;
