import React, { Component } from "react";

import styled from "styled-components";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";

import session from "../../session";
import type { Message, TextInput } from "../../utils/types";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import { EMAILREGEX } from "../../utils/validation";
import { PurpleButtonAction } from "../subcomponents/ButtonAction";
import ButtonToggle from "../subcomponents/ButtonToggle";
import RadioButton from "../subcomponents/RadioButton";
import TextField from "../subcomponents/TextField";
import VisuallyHidden from "../subcomponents/VisuallyHidden";
// Probs should move this about, feels odd for a subcomponent to reach into pages
import PrivacyContent from "../pages/Privacy/PrivacyContent";
import ConfirmableSection from "./ConfirmableSection";
import { type Consents } from "../../utils/types";
import { SessionContext } from "../contexts/SessionContext";
import { ApiHocProps, withApi } from "../../api";

type Selection = {
  alias: string;
  email: string;
  org: { defaultEmail: string; id: number };
  value: string;
};

type Props = {
  t: TFunction;
  organisations: Array<any>;
  // Todo improve type when we know shape
  statement: any;
  onSent?: () => void;
};

type State = {
  awaitingResponse: boolean;
  customEmail: TextInput;
  message?: Message;
  selected: Selection;
  termsAccepted: boolean;
};

function userHasConsentedPreviously() {
  if (session.user && session.user.consented_to) {
    return session.user.consented_to.indexOf("one_third_party") !== -1;
  }

  return false;
}

function isProfile() {
  return session.user && session.user.role === "profile";
}

// TODO: use bound functions instead of this string value mess
function encodeValue(value) {
  return encodeURI(JSON.stringify(value));
}

function decodeValue(value) {
  return JSON.parse(decodeURI(value));
}

function OrganisationInfo(props) {
  const { org } = props;

  return (
    <div>
      {org.description && (
        <p>
          <b>{org.description}</b>
        </p>
      )}
      <p>{org.address}</p>
      <p>{org.website}</p>
      <p>{org.phone}</p>
    </div>
  );
}

class SendStatement extends Component<
  ApiHocProps<Props>,
  State,
  typeof SessionContext
> {
  // grab context for a classy component
  static contextType = SessionContext;
  constructor(props) {
    super(props);

    this.state = {
      awaitingResponse: false,
      customEmail: { error: "", isValid: false, value: "" },
      // API expects integer - we use 0 to indicate no organisation.
      selected: {
        alias: "",
        email: "",
        org: { defaultEmail: "", id: 0 },
        value: "",
      },
      termsAccepted: userHasConsentedPreviously(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAliasChange = this.handleAliasChange.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.setAcceptedTerms = this.setAcceptedTerms.bind(this);
  }

  setAcceptedTerms(termsChecked) {
    this.setState({ termsAccepted: termsChecked });
  }

  handleAliasChange(value) {
    const { customEmail, selected } = this.state;
    let newAlias = selected.alias;
    let newEmail = selected.email;
    // rewrite
    let newOrg = { defaultEmail: "", id: 0 };
    let newCustomEmail = { error: "", isValid: false, value: "" };

    const decodedValue = decodeValue(value);

    // They will all contain a name.
    switch (decodedValue.name) {
      case "self":
        newAlias = "";
        // There should be one - but if not better to throw an error.
        newEmail = session.user ? session.user.email : "";
        break;
      case "custom":
        newAlias = "";
        newEmail = customEmail.value;
        newCustomEmail = customEmail;
        break;
      // The only other option is that this is a standard alias belonging to an org.
      default:
        newAlias = decodedValue.name;
        newEmail = decodedValue.orgEmail;
        newOrg = {
          defaultEmail: decodedValue.orgEmail,
          id: decodedValue.orgId,
        };
        break;
    }

    this.setState(function (prevState) {
      return {
        selected: {
          ...prevState.selected,
          alias: newAlias,
          email: newEmail,
          org: newOrg,
          value: value,
        },
        customEmail: newCustomEmail,
      };
    });
  }

  handleEmail(event) {
    const email = event.target.value;

    // We'll set the custom field value and the selected value.
    // We won't enable send unless the custom field is valid so it's ok to sync.
    this.setState(
      function (prevState) {
        return {
          customEmail: { ...prevState.customEmail, value: email },
          selected: { ...prevState.selected, email: email },
        };
      },
      () => {
        this.validateEmail(email);
      },
    );
  }

  validateEmail(email) {
    const isValid = EMAILREGEX.test(email);

    this.setState(function (prevState) {
      return {
        customEmail: { ...prevState.customEmail, isValid },
      };
    });
  }

  isSelected(org, alias) {
    const { selected } = this.state;

    if (org.id === selected.org.id) {
      return alias === selected.alias;
    }

    return false;
  }

  handleError(error) {
    // TODO pass message back to parent - to display in header? For now we'll stick it near send.
    return this.setState({
      awaitingResponse: false,
      message: {
        text: getAxiosErrorMessage(error),
      },
    });
  }

  async handleSubmit() {
    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });

    const { email, org, alias } = this.state.selected;
    const { statement, Api } = this.props;
    const { user } = session;

    // We should have a user.
    // we do this in the submit, assuming they've consented during this form.
    if (user && !userHasConsentedPreviously()) {
      const consent: Consents = "one_third_party";

      try {
        await Api.post(`/user/${user.id}/consents`, {
          type: consent,
        });
      } catch (e) {
        return this.handleError(e);
      }

      let updatedUser;
      try {
        updatedUser = await Api.get("/user/" + user.id);
      } catch (e) {
        return this.handleError(e);
      }

      // We might need to add additional check that this is a user object.
      session.setSession(updatedUser.data);
    }
    try {
      await Api.post("statement/send", {
        html: statement.html,
        organisation_id: org.id,
        entry: {
          content: statement.content,
          id: statement.id,
          label: statement.label,
          type: statement.type,
        },
        email: {
          alias: alias,
          to: [email],
          // Subject & body copy required by API - probably for legacy.
          // Email subject and body now constructed by server.
          subject: "Email subject",
          body: "Email body",
        },
      });
    } catch (e) {
      return this.handleError(e);
    }
    // Success!! set awaitingResponse back to false. and a message to user?
    // TODO better success message - maybe from api?
    await this.setState({
      awaitingResponse: false,
    });

    if (this.props.onSent) {
      this.props.onSent();
    }
  }

  componentDidMount() {
    const { t } = this.props;
    // If we are a profile account...
    const user = session.isAuth ? session.user : null;
    if (user && user.role === "profile") {
      const worker = user.worker;
      const workerAlias = t("pages:account.statement_sent_direct");
      const workerEmail = worker?.email;
      const workerOrgId = worker?.organisation_id;
      // @ts-expect-error fix this
      this.setState(function (prevState) {
        return {
          customEmail: { ...prevState.customEmail },
          selected: {
            ...prevState.selected,
            alias: workerAlias,
            email: workerEmail,
            org: { ...prevState.selected.org, id: workerOrgId },
          },
        };
      });
    }
  }

  render() {
    const { t } = this.props;
    const { awaitingResponse, customEmail, selected } = this.state;
    const handleAliasChange = this.handleAliasChange;
    const isSelected = this.isSelected;
    const selectedName = selected.value ? decodeValue(selected.value).name : "";

    let selectedOrg;
    if (selected.org.id !== 0) {
      const stateOrg = selected.org;

      selectedOrg = this.props.organisations.find(function (org) {
        return (
          org.id === stateOrg.id && org.aliases.indexOf(selected.alias) !== -1
        );
      });
    }

    // Loop over each of the users organisations and their aliases,
    // displaying them as radio buttons.
    const aliases = this.props.organisations.map(function (org) {
      return org.aliases.map(function (alias) {
        return (
          <div key={alias + org.id}>
            <RadioButton
              value={encodeValue({
                orgId: org.id,
                orgEmail: org.email,
                name: alias,
              })}
              label={alias + " (" + org.name + ")"}
              onChangeNotify={handleAliasChange}
              isSelected={isSelected(org, alias)}
            />
            {isSelected(org, alias) && selectedOrg && (
              <StyledOrganisationInfo org={selectedOrg} />
            )}
          </div>
        );
      });
    });

    return (
      <SendStatementContainer>
        {!awaitingResponse && !isProfile() && (
          <ButtonToggle
            name={t("pages:send_statement.dropdown_recipient")}
            colour="green"
          >
            <RadiosContainer>
              <VisuallyHidden>
                <legend>{t("pages:send_statement.dropdown_recipient")}</legend>
              </VisuallyHidden>
              {aliases}
              <RadioButton
                label={t("pages:send_statement.radio_label.myself")}
                value={encodeValue({ name: "self" })}
                isSelected={selectedName === "self"}
                onChangeNotify={this.handleAliasChange}
              />
              {/* @ts-expect-error old style theme, fix this */}
              {this.context.theme.name !== "xchange" ? (
                <RadioButton
                  label={t("pages:send_statement.radio_label.someone_else")}
                  value={encodeValue({ name: "custom" })}
                  isSelected={selectedName === "custom"}
                  onChangeNotify={this.handleAliasChange}
                />
              ) : null}
              {selectedName === "custom" && (
                <TextField
                  type="email"
                  id="someone-email"
                  name="email"
                  label={t("pages:send_statement.someone_else_email_label")}
                  value={customEmail.value}
                  onChange={this.handleEmail}
                  valid={this.state.customEmail.isValid}
                  required={true}
                />
              )}
            </RadiosContainer>
          </ButtonToggle>
        )}
        {!userHasConsentedPreviously() && (
          <ConfirmableSection
            heading={t("pages:send_statement.privacy_link_header")}
            content={<PrivacyContent sections={new Set(["send"])} t={t} />}
            confirmText={t("pages:send_statement.privacy_p_desc")}
            isConfirmed={this.state.termsAccepted}
            handleChange={this.setAcceptedTerms}
          ></ConfirmableSection>
        )}
        <SmallButtonContainer>
          <PurpleButtonAction
            name={t("pages:send_statement.button_send")}
            onClick={this.handleSubmit}
            disabled={!(this.state.termsAccepted && this.state.selected.email)}
          />
        </SmallButtonContainer>
      </SendStatementContainer>
    );
  }
}

const SendStatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const RadiosContainer = styled.fieldset`
  border: none;
  margin: 0.6rem auto;
  max-width: 25rem;
  padding: 0;
  width: 100%;
`;

const SmallButtonContainer = styled.div`
  max-width: 10rem;
  width: 100%;
`;

const StyledOrganisationInfo = styled(OrganisationInfo)`
  text-align: center;
`;

export default withTranslation()(withApi(SendStatement));
