import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import {
  colour_alto,
  colour_royal_purple,
  fontsize_base,
  slider_height,
} from "../../assets/css/variables";
import FieldsetWrapper from "../layouts/FieldsetWrapper";
import type { SliderGroupValue } from "../../utils/types";

interface SliderGroupProps {
  heading: string;
  id: string;
  onChangeNotify: (arg0: SliderGroupValue) => void;
  subQuestions: Array<{
    title: string;
    image: { src: string };
    option1: string;
    option2: string;
  }>;
  t: TFunction;
}

type SliderAnswer = { id: string; value: number; text_value: string };

export const SliderGroup = ({
  heading,
  onChangeNotify,
  id,
  t,
  subQuestions,
}: SliderGroupProps) => {
  const defaultAnswer = [] as Array<SliderAnswer>;

  const [answers, setAnswers] = useState<Array<SliderAnswer>>(defaultAnswer);

  function onSlide(event: { target: HTMLInputElement }, questionId: string) {
    const value = event.target.value;

    let isNewEntry = true;

    setAnswers((prevState: Array<SliderAnswer>) => {
      if (prevState.length) {
        prevState.map((stateEntry) => {
          if (stateEntry.id === questionId) {
            const updatedEntry = Object.assign(stateEntry, {
              value: parseInt(value),
              text_value: t(`scenarios:shared.sliderValues.` + value),
            });
            isNewEntry = false;
            return updatedEntry;
          }
          return null;
        });
      }

      if (isNewEntry) {
        const newEntry = [
          {
            id: questionId,
            value: parseInt(value),
            text_value: t(`scenarios:shared.sliderValues.` + value),
          },
        ];
        return [...prevState, ...newEntry];
      }

      return [...prevState];
    });
  }

  // Wait for state to update before reporting up to scenario
  useEffect(() => {
    const notifyValue = {
      contentKey: "group",
      id: id,
      isValid: true,
      value: answers,
    };
    if (answers.length) {
      // @ts-expect-error the code is correct because it's working
      onChangeNotify(notifyValue);
    }
  }, [answers, id, onChangeNotify]);

  // This may want to be defined from content if a questions needed a higher resolution slider
  const sliderPositions = 5;
  // We need values to start at 0 for styling
  const zeroIndexedPositions = sliderPositions - 1;
  const defaultScore = 0;

  return (
    <FieldsetWrapper id={id} heading={heading}>
      <Container>
        <Row justify="center">
          <Col xs={12} md={8} lg={6}>
            {subQuestions.map((question) => {
              // Look for the current question in state
              const questionState = answers.find((item) => {
                return item?.id === question.title;
              });

              // Calculate a % score if we have a value, or use a default
              const valueDisplay: number =
                questionState !== undefined
                  ? ((questionState?.value - 1) / zeroIndexedPositions) * 100
                  : 0;

              const htmlID = (id + question.title).replace(/\s/g, "-");

              return (
                <div key={question.title}>
                  <SliderImage alt="" src={question.image.src} />
                  <SliderInput percentage={valueDisplay}>
                    <label htmlFor={htmlID}>{question.title}</label>
                    <ValueDisplay aria-live="polite">
                      {questionState !== undefined
                        ? t(
                            `scenarios:shared.sliderValues.` +
                              questionState?.value,
                          )
                        : t(`scenarios:shared.sliderValues.0`)}
                    </ValueDisplay>
                    <SlideContainer>
                      {
                        // Hash marks on range inputs are not well supported by browsers, so lets make our own!
                        [0.25, 0.5, 0.75, 1].map((mark: number) => {
                          // We need to conditionally show the marks so they don't appear on top of the track
                          if (valueDisplay < mark * 100) {
                            return (
                              <SlideMark position={mark} key={mark}></SlideMark>
                            );
                          }
                          return null;
                        })
                      }
                      <input
                        type="range"
                        id={htmlID}
                        defaultValue={defaultScore}
                        min={1}
                        max={sliderPositions}
                        onChange={(e) => onSlide(e, question.title)}
                      />
                    </SlideContainer>
                  </SliderInput>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </FieldsetWrapper>
  );
};

const Container = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const SliderImage = styled.img`
  width: 8rem;
`;

const ValueDisplay = styled.div`
  color: ${colour_royal_purple};
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SlideContainer = styled.div`
  position: relative;
`;

const SlideMark = styled.div<{ position: number }>`
  background: #7d7d7d; // One-off colour to provide sufficient contrast
  border-radius: 100px;
  height: calc(0.5rem - 1px); // track height, less a 1px margin
  left: calc(
    (100% - 2.5rem - 1px) * ${(props) => props.position} + 1rem
  ); // #maths
  position: absolute;
  top: calc(1rem + 1px); // Allow for the 1px margin
  width: calc(0.5rem - 1px);
`;

const SliderInput = styled.div<{ percentage: number }>`
  margin: auto;

  label {
    box-sizing: border-box;
    display: block;
    font-size: ${fontsize_base};
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  input[type="range"] {
    box-sizing: border-box;
    padding: 1rem;
    -webkit-appearance: none;
    width: 100%;
  }

  input[type="range"]:focus {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    outline: none;
  }

  /* Chromium */
  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(
      90deg,
      ${colour_royal_purple} ${(props) => props.percentage}%,
      ${colour_alto} ${(props) => props.percentage}%
    );
    border-radius: 10px;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: ${colour_royal_purple};
    border-radius: 50%;
    cursor: pointer;
    height: ${slider_height};
    margin-top: -0.65rem;
    -webkit-appearance: none;
    width: ${slider_height};
  }

  /* Firefox */
  input[type="range"]::-moz-range-track {
    background: linear-gradient(
      90deg,
      ${colour_royal_purple} ${(props) => props.percentage}%,
      ${colour_alto} ${(props) => props.percentage}%
    );
    border-radius: 10px;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }

  input[type="range"]::-moz-range-thumb {
    background: ${colour_royal_purple};
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: ${slider_height};
    margin-top: -0.65rem;
    width: ${slider_height};
  }
`;

export default withTranslation()(SliderGroup);
