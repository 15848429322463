import React, { Component } from "react";

import session from "../../session";
import moment from "moment";

import { Row, Col } from "react-grid-system";
import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_caribbean_green,
  colour_royal_purple,
  colour_transparent,
  fontsize_base,
  fontsize_large,
  slider_height,
} from "../../assets/css/variables";
import type { ImageAttr } from "../../utils/types";
import VisuallyHidden from "../subcomponents/VisuallyHidden";

type Props = {
  className?: string;
  heading: string;
  hint: string;
  id: string;
  label: string;
  onChangeNotify: Function;
  positions: Array<{ name: string; image: ImageAttr; imageOlderYP: ImageAttr }>;
};

type State = {
  // TODO be aware that currently the user is not made aware of the name value.
  position: { name: string; image: ImageAttr; imageOlderYP: ImageAttr };
};

class Slider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { positions } = this.props;
    const totalPos = positions.length;
    const midPos = Math.round(totalPos / 2);
    this.state = {
      position: positions[midPos - 1],
    };

    this.onChange = this.onChange.bind(this);
  }

  async onChange(event: any) {
    const { value } = event.target;
    const { id, onChangeNotify, positions } = this.props;
    const intValue = parseInt(value, 10);
    await this.setState({ position: positions[intValue - 1] });

    // Notify scenario parent.
    const notifyValue = {
      contentKey: "choice",
      id,
      isValid: true,
      // For radio options the choice and text are the same value.
      // Here the text is the name we use for alt and to identify the image. User does not see this text.
      value: { choice: value, text: this.state.position.name },
    };
    onChangeNotify(notifyValue);
  }

  render() {
    const { className, heading, hint, id, label, positions } = this.props;
    const { position } = this.state;
    // TODO should we provide a default here in case positions[i] undefined?
    const userDOB = new Date(session?.user?.dob || Date.now());
    const userAgeinYears = moment(Date.now()).diff(moment(userDOB), "years");

    const sliderImg =
      userAgeinYears < 13 ? position.image : position.imageOlderYP;

    const slideValue = positions.indexOf(position) + 1;

    return (
      <Container>
        <Heading>{heading}</Heading>
        <Row justify="center">
          <Col xs={12} md={6} lg={4}>
            {userAgeinYears < 13 ? (
              <SliderImage alt={sliderImg?.alt} src={sliderImg?.src} />
            ) : (
              <SliderImageSmall alt={sliderImg?.alt} src={sliderImg?.src} />
            )}
            <SliderInput className={className}>
              <label htmlFor={id + "slider"}>
                {label} <VisuallyHidden>{hint}</VisuallyHidden>
              </label>
              <input
                type="range"
                id={id + "slider"}
                defaultValue={slideValue}
                max={positions.length}
                min={1}
                onChange={this.onChange}
              />
            </SliderInput>
          </Col>
        </Row>
      </Container>
    );
  }
}

const Container = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const Heading = styled.h3`
  font-size: ${fontsize_large};
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const SliderImage = styled.img`
  margin: -6rem auto -4rem auto;
`;

const SliderImageSmall = styled.img`
  width: 50%;
  margin: -3rem auto -3rem auto;
`;

const SliderInput = styled.div`
  padding-top: 3rem;
  margin: auto;
  max-width: 20em;

  label {
    box-sizing: border-box;
    display: block;
    font-size: ${fontsize_base};
    font-weight: normal;
    margin-bottom: 2rem;
  }

  input[type="range"] {
    box-sizing: border-box;
    padding: 5px;
    -webkit-appearance: none;
    width: 100%;
  }

  input[type="range"]:focus {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    outline: none;
  }

  /* Chrome, sometimes Edge */
  input[type="range"]::-webkit-slider-runnable-track {
    background: ${colour_mine_shaft};
    cursor: pointer;
    height: 2px;
    width: 100%;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: ${colour_caribbean_green};
    border-radius: 50%;
    cursor: pointer;
    height: ${slider_height};
    margin-top: -0.9rem;
    -webkit-appearance: none;
    width: ${slider_height};
  }

  /* Firefox */
  input[type="range"]::-moz-range-track {
    background: ${colour_mine_shaft};
    cursor: pointer;
    height: 2px;
    outline: none;
    width: 100%;
  }

  input[type="range"]::-moz-range-thumb {
    background: ${colour_caribbean_green};
    border: 0 solid ${colour_transparent};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: ${slider_height};
    width: ${slider_height};
  }

  /* IE */
  input[type="range"]::-ms-track {
    background: ${colour_transparent};
    border: 1px solid transparent;
    border-width: calc(0.9rem - 1px);
    box-sizing: border-box;
    cursor: pointer;
    height: ${slider_height};
    width: 100%;
  }

  input[type="range"]::-ms-tooltip {
    display: none;
  }

  input[type="range"]::-ms-fill-lower {
    background: ${colour_mine_shaft};
  }

  input[type="range"]::-ms-fill-upper {
    background: ${colour_mine_shaft};
  }

  input[type="range"]::-ms-thumb {
    background: ${colour_caribbean_green};
    border-radius: 50%;
    cursor: pointer;
    height: ${slider_height};
    margin: auto;
    width: ${slider_height};

    &:active {
      border: none;
    }
  }
`;

export default Slider;
