import React, { type ReactNode as Node } from "react";

import styled from "styled-components";

import { fontsize_large } from "../../assets/css/variables";

type Props = {
  children: Node;
  className?: string;
  heading?: string;
  label?: string;
  span?: string;
  id: string;
};

function FieldsetWrapper(props: Props) {
  const { children, className, heading, id, label, span } = props;
  return (
    <fieldset className={className}>
      {heading && <Legend>{<FieldsetTitle>{heading}</FieldsetTitle>}</Legend>}
      {span && <FieldsetSpan>{span}</FieldsetSpan>}
      {label && <FieldsetLabel htmlFor={id}>{label}</FieldsetLabel>}
      {children}
    </fieldset>
  );
}

const Legend = styled.legend`
  margin: auto;
`;

const FieldsetTitle = styled.h3`
  font-size: ${fontsize_large};
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const FieldsetLabel = styled.label`
  display: inline-block;
  margin-bottom: 1rem;
`;

const FieldsetSpan = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
`;

const StyledFieldsetWrapper = styled(FieldsetWrapper)`
  border: none;
  text-align: center;
  margin-bottom: 1rem;
`;

export default StyledFieldsetWrapper;
