import styled from "styled-components";

interface VerticalSpacingProps {
  size?: number;
}

const Spacing = styled.div`
  margin: ${(props: VerticalSpacingProps) => (props.size ? props.size : 2)}rem 0;
`;

/**
 * Layout utility for adding vertical space between components
 * @param {number} size - optional size in rem, defaults to 2
 */
export const VerticalSpacing = ({ size }: VerticalSpacingProps) => {
  return <Spacing size={size} />;
};
