import React, { Component } from "react";

import i18n from "i18next";
import { Row, Col } from "react-grid-system";
import { withTranslation, Trans } from "react-i18next";
import type { TFunction } from "i18next";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import session from "../../session";
import { getLocaleDefaults } from "../../utils/l10n";
import type { History, Location, Message, TextInput } from "../../utils/types";
import {
  allInputsValid,
  EMAILREGEX,
  LEGACYPASSWORDLENGTH,
} from "../../utils/validation";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import PrivacyContent from "./Privacy/PrivacyContent";
import { GreenButtonAction } from "../subcomponents/ButtonAction";
import LanguageChoice from "../subcomponents/LanguageChoice";
import MessageBox from "../subcomponents/MessageBox";
import SubmitButton from "../subcomponents/SubmitButton";
import TextField from "../subcomponents/TextField";
import TextLink from "../subcomponents/TextLink";
import TextLinkExternal from "../subcomponents/TextLinkExternal";
import { VerticalSpacing } from "../VerticalSpacing";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  location: Location;
  t: TFunction;
};

type State = {
  authMessage: Message;
  awaitingResponse: boolean;
  email: TextInput;
  language: { value: number };
  password: TextInput;
  redirectToReferrer: boolean;
};

class Login extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      authMessage: { text: "" },
      awaitingResponse: false,
      email: { value: "", isValid: false, error: "" },
      language: { value: 1 },
      password: { value: "", isValid: false, error: "" },
      redirectToReferrer: false,
    };

    this.addSignupConsent = this.addSignupConsent.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    const { name, value } = event.target;
    // Todo validate fields & form callbacks?
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: { value },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  async componentDidMount() {
    const fetched = localStorage.getItem("language");
    const languageId = fetched ? JSON.parse(fetched).id : null;
    const langCode = fetched ? JSON.parse(fetched).code : null;

    // If we retrieve a local language object, set the language
    if (langCode !== null) {
      i18n.changeLanguage(langCode);
    }

    // Provide the language to state
    if (languageId !== null) {
      this.setState(function (prevState) {
        return {
          ...prevState,
          language: { value: languageId },
        };
      });
    }
  }

  onLanguageChange(lang) {
    // Change the language
    i18n.changeLanguage(lang.code);
    // Save the language outside the user
    localStorage.setItem("language", JSON.stringify(lang));
    // Set the language into state for other components
    this.setState(function (prevState) {
      return {
        ...prevState,
        language: { value: lang.id },
      };
    });
  }

  validateField(name, value) {
    const { email, password } = this.state;
    switch (name) {
      case "email":
        email.isValid = EMAILREGEX.test(value);
        break;
      case "password":
        password.isValid = value.length >= LEGACYPASSWORDLENGTH;
        break;
      default:
        break;
    }

    this.setState({ email, password });
  }

  async addSignupConsent() {
    const userId = session.user ? session.user.id : null;
    let response;
    if (userId) {
      try {
        response = await this.props.Api.post(`/user/${userId}/consents`, {
          type: "one_momo",
        });
      } catch (e) {
        return this.setState({
          authMessage: { text: getAxiosErrorMessage(e), type: "error" },
        });
      }
      const user = response ? response.data : session.user;
      session.setSession(user);
      this.setState({ redirectToReferrer: true });
    } else {
      this.props.history.push("/", {
        message: {
          text: this.props.t("pages:login.consent.message_add_consent_error"),
          type: "error",
        },
      });
    }
  }

  async onSubmit(event) {
    event.preventDefault();

    // Do nothing if we're waiting for server response to prevent double submit.
    // Unlikely to get here - since button disabled on 1st click anyway - but no harm done.
    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });

    await session.doAuth(
      this.props.Api,
      {
        email: this.state.email.value,
        password: this.state.password.value,
      },
      // The sx
      () => {
        // If user hasn't signed up to services yet - go there
        if (session.user && session.user.service_ids.length < 1) {
          // Todo add redirect referrer path to pass on after services registered?
          this.props.history.push("/services");
        } else {
          this.setState({ awaitingResponse: false, redirectToReferrer: true });
        }
      },
      // The fx
      (rawMessage) => {
        this.setState({
          authMessage: { text: rawMessage, type: "error" },
          awaitingResponse: false,
        });
      },
    );
  }

  render() {
    // We are using an app and need to know a country to chose a server
    if (window.usingCordova && !localStorage.getItem("country")) {
      this.props.history.push("/get-started");
    }

    const passedState = this.props.history.location.state as Record<
      string,
      unknown
    >;
    const message: Message =
      passedState && passedState.message
        ? (passedState.message as Message)
        : { text: "" };
    const { location, t } = this.props;
    const {
      authMessage,
      awaitingResponse,
      email,
      password,
      redirectToReferrer,
    } = this.state;

    const { from } = (location?.state as { from: { pathname: string } }) || {
      from: { pathname: "/choose-service" },
    };

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    // Only display the accessibility link in local dev or the UK sites
    const shouldDisplayAccessibilityLink =
      getLocaleDefaults().tld === "" || getLocaleDefaults().tld === "uk";

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:login.h2_title")}
            message={authMessage}
            hideLogoutButton={true}
            hideAccountButton={true}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            {!session.isAuth && (
              <Col xs={12} md={8} lg={6}>
                <Form onSubmit={this.onSubmit}>
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    label={t("pages:login.label_email")}
                    value={email.value}
                    onChange={this.onChange}
                    valid={email.isValid}
                    required={true}
                  />
                  <TextField
                    type="password"
                    id="password"
                    name="password"
                    label={t("pages:login.label_password")}
                    value={password.value}
                    onChange={this.onChange}
                    valid={password.isValid}
                    required={true}
                  />
                  <SubmitButton
                    label={t("pages:login.button_submit")}
                    name="login"
                    disabled={
                      !allInputsValid([email, password]) || awaitingResponse
                    }
                  />
                </Form>
              </Col>
            )}
            {session.user &&
              session.user.consented_to &&
              !(session.user.consented_to.indexOf("one_momo") > -1) && (
                <Col>
                  <h3>{t("pages:login.consent.h3_explain")}</h3>
                  <PrivacyContent sections={new Set(["signup"])} />
                  <p>
                    <Trans i18nKey="pages:signup.privacy_p_decline">
                      Placeholder for decline text
                      <TextLinkExternal
                        name={t("pages:signup.link_privacy_policy")}
                        href={"https://mindofmyown.org.uk/privacy-policy-2/"}
                        newtab
                      />
                      <TextLinkExternal
                        name={t("pages:signup.link_email_us_privacy")}
                        href={"mailto:" + t("pages:privacy.contact_email")}
                      />
                    </Trans>
                  </p>
                  <GreenButtonAction
                    onClick={this.addSignupConsent}
                    name={t("pages:login.consent.button_accept")}
                  />
                </Col>
              )}
          </Row>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              {message && <MessageBox message={message} />}
            </Col>
          </Row>
          <VerticalSpacing />
          {session?.user?.role === "profile" ? null : (
            <SignUpLink>
              <div>{t("pages:login.or")}</div>
              <TextLink to="/signup" name={t("pages:login.link_signup")} />
            </SignUpLink>
          )}
        </LayoutContent>

        <LayoutFooter>
          <LinkList>
            {window.usingCordova ? (
              <LinkItem>
                <TextLink
                  to="/get-started"
                  name={t("pages:login.change_location_link")}
                />
              </LinkItem>
            ) : null}

            <LinkItem>
              <TextLink
                to="/forgot-password"
                name={t("pages:login.link_forgot_password")}
              />
            </LinkItem>

            <LinkItem>
              <TextLinkExternal
                href={t("messagesAndSettings:privacy_policy_href")}
                name={t("messagesAndSettings:privacy_policy_link")}
                newtab
              />
            </LinkItem>

            {shouldDisplayAccessibilityLink ? (
              <LinkItem>
                <TextLinkExternal
                  href={t("messagesAndSettings:accessibility_statement_href")}
                  name={t("messagesAndSettings:accessibility_statement_link")}
                  newtab
                />
              </LinkItem>
            ) : null}

            <VerticalSpacing />

            <VersionLinkItem
              to="/version"
              name={process.env.REACT_APP_VERSION}
            />
            <VerticalSpacing />
          </LinkList>
          <Row justify="center">
            <LanguageChoice
              inline
              onChangeNotify={this.onLanguageChange}
              selected={this.state.language.value}
              legend={t("messagesAndSettings:language_choice")}
            />
          </Row>
        </LayoutFooter>
      </Layout>
    );
  }
}

const SignUpLink = styled.div`
  text-align: center;

  a {
    font-size: 1.5rem;
  }
`;

const LinkList = styled.ul`
  text-align: center;
`;

const LinkItem = styled.li`
  & + li {
    margin-top: 0.5rem;
  }
`;

const Form = styled.form`
  padding-top: 3rem;
`;

const VersionLinkItem = styled(TextLink)`
  font-size: 12px;
`;

export default withTranslation()(withApi(Login));
