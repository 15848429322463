import React, { Component, Fragment } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import {
  colour_alto,
  colour_mine_shaft,
  colour_royal_purple,
  colour_white,
} from "../../assets/css/variables";
import type { TextInput } from "../../utils/types";
import MessageBox from "../subcomponents/MessageBox";
import BaseButton from "../subcomponents/BaseButton";

type Props = {
  className?: string;
  id: string;
  label: string;
  maxchars: number;
  onClickNotify: Function;
  t: TFunction;
  valid: boolean;
};

type State = {
  text: TextInput;
};

class AddOption extends Component<Props, State> {
  static defaultProps = {
    maxchars: 20,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      text: { value: "", isValid: false, error: "" },
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(event: any) {
    const { value } = event.target;
    const { maxchars } = this.props;
    const tooLong = value.length && value.length > maxchars;
    // Todo add maxchars to translate
    const error = tooLong ? "errors.fieldsets.add_own_too_long" : "";
    const isValid = value.length && !tooLong;
    this.setState({ text: { error: error, isValid: isValid, value: value } });
  }

  onClick() {
    const { onClickNotify } = this.props;
    const { text } = this.state;

    this.setState({ text: { error: "", isValid: false, value: "" } });
    onClickNotify(text.value);
  }

  render() {
    const { className, id, label: labelHidden, maxchars, t } = this.props;

    const { text } = this.state;
    return (
      <Fragment>
        <div className={className}>
          <label htmlFor={id + "-add-option"}>{labelHidden}</label>
          <input
            aria-invalid={!text.isValid}
            id={id + "-add-option"}
            onChange={this.onChange}
            type="text"
            value={text.value}
          />
          <BaseButton disabled={!text.isValid} onClick={this.onClick}>
            {text.isValid ? (
              <img
                src={t("images:plus_sign_white.src")}
                alt={t("images:plus_sign_white.alt")}
              />
            ) : (
              <img
                src={t("images:plus_sign_black.src")}
                alt={t("images:plus_sign_white.alt")}
              />
            )}
          </BaseButton>
        </div>
        <MessageBox
          message={{
            text: t(text.error, { maxchars: maxchars }),
            type: "error",
          }}
        />
      </Fragment>
    );
  }
}

const StyledAddOption = styled(AddOption)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  max-width: 25em;
  position: relative;
  width: 100%;

  label {
    background: ${colour_white};
    border-radius: 2rem;
    color: ${colour_mine_shaft};
    font-weight: bold;
    left: 2rem;
    padding: 0 0.5rem;
    position: absolute;
    top: -0.6rem;
    white-space: nowrap;
  }

  input {
    border-radius: 2em;
    border: 1px solid ${colour_mine_shaft};
    border-right: none;
    box-sizing: border-box;
    height: 3.5em;
    margin-right: -4.5em;
    padding: 0.5em 4.5em 0.5em 1.5em;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
      outline: none;
    }

    // Prevents Firefox highlighting
    &:required {
      box-shadow: none;
    }
  }

  button {
    background-color: ${colour_royal_purple};
    border: 1px solid ${colour_mine_shaft};
    border-left: none;
    border-bottom-right-radius: 2em;
    border-top-right-radius: 2em;
    display: block;
    flex: 1 0 4.5em;
    height: 3.5em;
    margin: 0;
    width: 4.5em;

    &:disabled {
      background-color: ${colour_alto};
      box-shadow: none;
      cursor: not-allowed;
    }

    img {
      height: 2em;
      width: 2em;
    }
  }
`;

export default withTranslation()(StyledAddOption);
