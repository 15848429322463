import React from "react";

import styled from "styled-components";

import { fontsize_base } from "../../assets/css/variables";

type Props = {
  className?: string;
  text: string;
};

function Subheading(props: Props) {
  return <h2 className={props.className}>{props.text}</h2>;
}

const styledSubheading = styled(Subheading)`
  font-size: ${fontsize_base};
  font-weight: normal;
  margin: 1rem;
  text-align: center;
`;

export default styledSubheading;
