import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_royal_purple,
  colour_white,
} from "../assets/css/variables";
import { StatementArrowSymbol } from "../assets/images/symbols/StatementArrowSymbol";
import TrashSymbol from "../assets/images/symbols/TrashSymbol";
import type { Statement } from "./pages/Statements";
import BaseButton from "./subcomponents/BaseButton";
import { StatementMessage } from "./StatementMessage";

interface StatementListItemProps {
  statement: Statement;
  onTrashClick: Function;
}

const IconButton = styled(BaseButton)`
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  margin-left: auto;
  height: 2.5rem;
  width: 2.5rem;
`;

const Headline = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  width: 100%;

  a {
    align-items: center;
    border-radius: 2rem;
    color: ${colour_mine_shaft};
    display: flex;
    font-weight: 700;
    height: 100%;
    margin-left: -0.5rem;
    padding: 0.5rem;
    text-decoration: none;

    svg:nth-child(3) {
      height: 1rem;
      width: 1rem;
      margin: 0 2rem 0 0.5rem;
      flex-shrink: 0;
    }

    :hover {
      color: ${colour_royal_purple};

      svg:nth-child(3) {
        margin-left: 1rem;
        fill: ${colour_royal_purple};
      }
    }

    :focus {
      background-color: ${colour_white};
    }
  }
`;

const IconContainer = styled.span`
  align-items: center;
  background-color: ${colour_white};
  border-radius: 2rem;
  border: 1px solid grey;
  display: flex;
  flex-shrink: 0;
  height: 3rem;
  justify-content: center;
  margin-right: 1rem;
  width: 3rem;

  img {
    height: 2rem;
    width: 2rem;
  }
`;

const ListItem = styled.li`
  border-bottom: 2px solid ${colour_royal_purple};
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

function messages(t: TFunction, statement: Statement) {
  // Messages for statements that are sent and eligible for system and practitioner messages
  return statement.cases.map((statementCase) => {
    return (
      // We're using Fragment here explicitly rather than <></> so we can pass a key prop
      <Fragment key={statementCase.created_at}>
        {statementCase.userMessages
          ? statementCase.userMessages.map((message) => {
              let status: string;
              let details: string;

              switch (message.message_type) {
                case "Assigned":
                  status = t("statements.status_assigned");
                  details = t("statements.to_org_worker", {
                    worker_type: statementCase.worker_type,
                    organisation_name: statementCase.organisation_name,
                  });
                  break;

                case "Downloaded":
                  status = t("statements.status_downloaded");
                  details = t("statements.by_org_worker", {
                    worker_type: statementCase.worker_type,
                    organisation_name: statementCase.organisation_name,
                  });
                  break;

                case "Responded":
                  status = t("statements.status_response");
                  details = message.content;
                  break;

                // 'Sent' messages are made on statement case creation. We ignore this
                // and use the statement send data instead, as this information will be
                // available for statements made before messages were introduced.
                case "Sent":
                  status = "";
                  details = "";
                  break;

                default:
                  status = "";
                  details = "";
              }

              if (status) {
                return (
                  <StatementMessage
                    key={"message_" + message.content_id}
                    status={status}
                    details={details}
                    date={message.created_at}
                  />
                );
              }

              return null;
            })
          : null}

        {/* Message for the statement send, made from statement case data */}
        <StatementMessage
          key={"send_" + statementCase.created_at}
          status={t("statements.status_sent")}
          details={t("statements.to_org_worker", {
            worker_type: statementCase.worker_type,
            organisation_name: statementCase.organisation_name,
          })}
          date={statement.created_at}
        />
      </Fragment>
    );
  });
}

export const StatementListItem = ({
  statement,
  onTrashClick,
}: StatementListItemProps) => {
  const { t } = useTranslation("pages");

  function handleClick(evt: React.MouseEvent<HTMLButtonElement>) {
    evt.stopPropagation();
    evt.preventDefault();
    onTrashClick(statement.id);
  }

  const target = {
    pathname: `/preview/${statement.id}`,
    state: { fromMyAccount: true },
  };

  return (
    <ListItem>
      <Headline>
        <Link to={target}>
          <IconContainer>
            <img src={t(t("scenarios:" + statement.type + ".icon"))} alt="" />
          </IconContainer>
          <span>{t("scenarios:" + statement.type + ".title-past-tense")}</span>
          <StatementArrowSymbol />
        </Link>

        <IconButton
          onClick={handleClick}
          aria-label={t("pages:account.statements_delete_button_label")}
        >
          <TrashSymbol
            title={t("pages:account.statements_delete_button_label")}
          />
        </IconButton>
      </Headline>

      <ul>
        {messages(t, statement)}

        {/* The message for a statement that has been created but not sent, or has no statement case */}
        {!statement.cases.length ? (
          <StatementMessage
            key={"created_" + statement.id}
            status={
              statement.has_been_sent
                ? t("pages:statements.status_sent")
                : t("pages:statements.status_created")
            }
            date={statement.created_at}
          />
        ) : null}
      </ul>
    </ListItem>
  );
};
