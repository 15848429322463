import React, { ChangeEvent, Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";

import type { History, Message, TextInput } from "../../utils/types";
import { allInputsValid, PASSWORDLENGTH } from "../../utils/validation";
import HeaderSection from "../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import SubmitButton from "../subcomponents/SubmitButton";
import TextField from "../subcomponents/TextField";
import { match } from "react-router-dom";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  match: match<{ email: string; token: string }>;
  t: TFunction;
};

type State = {
  awaitingResponse: boolean;
  message: Message;
  password: TextInput;
  passwordConfirm: TextInput;
};

class ResetPassword extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: false,
      message: { text: "" },
      password: { value: "", isValid: false, error: "" },
      passwordConfirm: { value: "", isValid: false, error: "" },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    this.setState(
      (oldState) => {
        // Uses computed property dynamic key name.
        return { ...oldState, [name]: { value } };
      },
      // Process in callback
      () => {
        this.validateField(name, value);
      },
    );
  }

  validateField(name, value) {
    const { password, passwordConfirm } = this.state;
    switch (name) {
      case "password":
        {
          const isValid = value.length && value.length >= PASSWORDLENGTH;
          password.isValid = isValid;
          password.error = !isValid ? "pages:signup.password_too_short" : "";
        }
        break;
      case "passwordConfirm":
        passwordConfirm.isValid =
          password.value.length > 0 && password.value === passwordConfirm.value;
        break;
      default:
        break;
    }

    this.setState({ password, passwordConfirm });
  }

  async onSubmit(event) {
    event.preventDefault();
    // Prevent 2 submits on a double click.
    if (this.state.awaitingResponse) {
      return;
    }

    const { email, token } = this.props.match.params;
    const { password, passwordConfirm } = this.state;

    // Flow needs these to be strings for sure.
    const e = email || "";
    const t = token || "";

    this.setState({ awaitingResponse: true });
    let response;
    try {
      // Post new password info
      response = await this.props.Api.post("user/lost_password/reset", {
        email: decodeURIComponent(e), // need to unescape
        password: password.value,
        password_confirmation: passwordConfirm.value,
        token: t,
      });
    } catch (err) {
      const msg = getAxiosErrorMessage(err);

      return this.setState({
        awaitingResponse: false,
        message: { text: msg, type: "error" },
      });
    }
    this.setState({ awaitingResponse: false });
    // TODO API returns status with text - we should translate and make standard success/ error keys.
    this.props.history.push("/login", { message: { text: response.status } });
  }

  render() {
    const { t } = this.props;
    const { awaitingResponse, password, passwordConfirm } = this.state;
    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:resetpassword.heading")}
            hideLogoutButton={true}
            hideAccountButton={true}
            hideHomeButton={true}
            message={this.state.message}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <form onSubmit={this.onSubmit}>
                <TextField
                  type="password"
                  id="password"
                  name="password"
                  label={t("pages:resetpassword.label_password")}
                  error={t(password.error)}
                  value={password.value}
                  onChange={this.onChange}
                  valid={password.isValid}
                  required={true}
                />
                <TextField
                  type="password"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  label={t("pages:resetpassword.label_password_confirm")}
                  value={passwordConfirm.value}
                  onChange={this.onChange}
                  valid={passwordConfirm.isValid}
                  required={true}
                />

                <SubmitButton
                  label={t("pages:resetpassword.button_submit")}
                  name="save"
                  noIcon={true}
                  disabled={
                    !allInputsValid([password, passwordConfirm]) ||
                    awaitingResponse
                  }
                />
              </form>
            </Col>
          </Row>
        </LayoutContent>
      </Layout>
    );
  }
}

export default withTranslation()(withApi(ResetPassword));
