import React, { ChangeEvent, Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import type { History, Message, TextInput } from "../../utils/types";
import { EMAILREGEX } from "../../utils/validation";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import SubmitButton from "../subcomponents/SubmitButton";
import TextField from "../subcomponents/TextField";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  t: TFunction;
  history: History;
};

type State = {
  awaitingResponse: boolean;
  email: TextInput;
  message: Message;
};

class ForgotPassword extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: false,
      email: { value: "", isValid: false, error: "" },
      message: { text: "" },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: { value },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  validateField(name, value) {
    const { email } = this.state;
    switch (name) {
      case "email":
        email.isValid = EMAILREGEX.test(value);
        break;
      default:
        break;
    }

    this.setState({ email });
  }

  async onSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    const { t, Api } = this.props;

    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });

    try {
      await Api.post("/user/lost_password", {
        email: email.value,
      });
    } catch (e) {
      // Todo get this message from server response.
      return this.setState({
        awaitingResponse: false,
        message: { text: (e as { message: string }).message, type: "error" },
      });
    }

    // Todo get response message from server? We'd want to change it first.
    // Message currently We have e-mailed your password reset link!
    this.props.history.push("/login", {
      message: { text: t("pages:forgot_password.message_success") },
    });
  }

  render() {
    // Equiv to `const t = this.props.t`
    const { t } = this.props;
    const { awaitingResponse, email, message } = this.state;

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:forgot_password.h2_title")}
            message={message}
            hideHomeButton={true}
            hideLogoutButton={true}
            hideAccountButton={true}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              <Form onSubmit={this.onSubmit}>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  value={email.value}
                  label={t("pages:forgot_password.label_email")}
                  onChange={this.onChange}
                  valid={email.isValid}
                  required={true}
                />
                <SubmitButton
                  label={t("pages:forgot_password.button_submit")}
                  name="forgotPassword"
                  disabled={!email.isValid || awaitingResponse}
                />
              </Form>
            </Col>
          </Row>
        </LayoutContent>
      </Layout>
    );
  }
}

const Form = styled.form`
  padding-top: 3rem;
`;

export default withTranslation()(withApi(ForgotPassword));
