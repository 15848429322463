import React from "react";

type Props = {
  title: string;
};

export default function TrashSymbol({ title }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
      clipRule="evenodd"
      viewBox="0 0 12.2565625 12.2565625"
      width="100%"
      height="100%"
    >
      <title>{title}</title>
      <g fill="#8568af" fillRule="nonzero">
        <path d="M6.12 0a6.12 6.12 0 1 0 0 12.24A6.12 6.12 0 0 0 6.12 0m0 .466a5.661 5.661 0 0 1 5.654 5.654 5.661 5.661 0 0 1-5.654 5.654A5.661 5.661 0 0 1 .466 6.12 5.661 5.661 0 0 1 6.12.466" />
        <path d="M8.531 4.131a.412.412 0 0 1-.412.411H4.143a.412.412 0 0 1-.412-.411v-.04c0-.227.185-.412.412-.412h3.976c.227 0 .412.185.412.412v.04zm-.495 4.426a.412.412 0 0 1-.411.412H4.638a.413.413 0 0 1-.412-.412V4.914h3.81v3.643zM6.131 2.858c.271 0 .496.194.546.449H5.585a.558.558 0 0 1 .546-.449m1.988.449H7.053a.93.93 0 0 0-.922-.821.93.93 0 0 0-.921.821H4.143a.784.784 0 0 0-.783.784v.04c0 .33.205.612.495.727v3.699c0 .432.351.783.783.783h2.987a.784.784 0 0 0 .783-.783V4.858a.784.784 0 0 0 .495-.727v-.04a.785.785 0 0 0-.784-.784" />
        <path d="M5.136 8.494a.185.185 0 0 0 .185-.186v-2.09a.185.185 0 1 0-.371 0v2.09c0 .103.083.186.186.186M6.131 8.494a.186.186 0 0 0 .186-.186v-2.09a.186.186 0 1 0-.371 0v2.09c0 .103.083.186.185.186M7.127 8.494a.185.185 0 0 0 .185-.186v-2.09a.185.185 0 1 0-.371 0v2.09c0 .103.083.186.186.186" />
      </g>
    </svg>
  );
}
