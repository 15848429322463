import React, { Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";

import session from "../../session";
import type { History, Message } from "../../utils/types";
import HeaderSection from "../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import { match } from "react-router-dom";
import { ApiHocProps, withApi } from "../../api";

export type Props = {
  history: History;
  match: match<{ profileId: string; token: string }>;
  t: TFunction;
};

type State = {
  awaitingResponse: boolean;
  message: Message;
};

class Handover extends Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: false,
      message: { text: "" },
    };
  }

  async componentDidMount() {
    const { Api } = this.props;
    const { profileId, token } = this.props.match.params;

    // Flow needs these to be strings for sure.
    const p = profileId || "";
    const t = token || "";

    this.setState({ awaitingResponse: true });
    let response;
    try {
      // Exchange token for a profile user
      response = await Api.post("/profile/" + p + "/token", { token: t });
      // We'll get a 404 from the server if profile not found and a 200 if found.
      if (response.status !== 200) {
        // Return to catch the 404.
        return;
      }
    } catch (e) {
      const msg = getAxiosErrorMessage(e);

      return this.setState({
        awaitingResponse: false,
        message: { text: msg, type: "error" },
      });
    }
    // Success - we should have a profile user. Auth them.
    const profile = response.data;
    try {
      session.setSession(profile);
    } catch (e) {
      return this.setState({
        awaitingResponse: false,
        message: { text: (e as any).message, type: "error" },
      });
    }
    this.setState({ awaitingResponse: false });
    this.props.history.push("/choose-service");
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("messagesAndSettings:handover.heading")}
            hideLogoutButton={true}
            hideAccountButton={true}
            hideHomeButton={true}
            message={this.state.message}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              {this.state.awaitingResponse && (
                <h2>{t("messagesAndSettings:handover.loading")}</h2>
              )}
            </Col>
          </Row>
        </LayoutContent>
      </Layout>
    );
  }
}

export default withApi(withTranslation()(Handover));
