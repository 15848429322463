import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import FieldsetWrapper from "../layouts/FieldsetWrapper";
import AddOption from "../subcomponents/AddOption";
import RadioButton from "../subcomponents/RadioButton";
import type { RadioSelectValue } from "../../utils/types";

type Props = {
  className?: string;
  heading?: string;
  id: string;
  initRadioOptions: Array<{ name: string }>;
  label: string;
  onChangeNotify: (val: Readonly<RadioSelectValue>) => void;
  t: TFunction;
  type: string;
};

type State = {
  choice: string;
  radioOptions: Array<{ name: string }>;
};

class RadioButtons extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { initRadioOptions } = this.props;

    this.state = {
      choice: "",
      radioOptions: initRadioOptions,
    };

    this.onChange = this.onChange.bind(this);
  }

  isCustom(choice: string) {
    const { initRadioOptions } = this.props;
    const found = initRadioOptions.some(function (v) {
      return choice === v.name;
    });
    return !found;
  }

  async onChange(optionId: string) {
    const { onChangeNotify, id } = this.props;

    this.setState((prevState) => {
      const oldState = prevState.radioOptions;
      const found = oldState.some(function (radioOption) {
        return radioOption.name === optionId;
      });
      // If it's new - select it and add to array of radioOptions
      if (!found) {
        const newRadioOption = { name: optionId };
        return {
          choice: optionId,
          radioOptions: prevState.radioOptions.concat([newRadioOption]),
        };
      }

      // Just the choice - options not changed
      return { choice: optionId, radioOptions: prevState.radioOptions };
    });

    // Notify the scenario page
    onChangeNotify({
      id,
      value: {
        choice: this.state.choice,
        // @ts-expect-error not sure if this is used
        custom: this.isCustom(this.state.choice),
        text: this.state.choice,
      },
      isValid: this.state.choice !== "",
      contentKey: "choice",
    });
  }

  render() {
    const { className, heading, id, label, t, type } = this.props;
    const { choice, radioOptions } = this.state;

    return (
      <FieldsetWrapper
        label={label}
        heading={heading}
        id={id}
        className={className}
      >
        <RadioContainer>
          {radioOptions.map((option) => {
            return (
              <RadioButton
                isSelected={option.name === choice}
                key={id + option.name}
                label={option.name}
                onChangeNotify={this.onChange}
                value={option.name}
              />
            );
          })}
          {type === "radioplus" && (
            <AddOption
              id={id}
              label={t("pages:scenario.add_own_option")}
              onClickNotify={this.onChange}
            />
          )}
        </RadioContainer>
      </FieldsetWrapper>
    );
  }
}

const RadioContainer = styled.div`
  margin: 0.6rem auto;
  max-width: 25rem;
  width: 100%;
`;

export default withTranslation()(RadioButtons);
