type AccountSymbolProps = {
  title: string;
};

export const AccountSymbol = ({ title }: AccountSymbolProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="10"
      role="img"
    >
      <title>{title}</title>;
      <g fill="none" stroke="#fff" strokeWidth="1.6">
        <path d="M7.982 29.165c0-6.405 3.583-11.596 8-11.596s8 5.191 8 11.596" />
        <circle cx="15.781" cy="13.072" r="3.843" />
        <circle cx="16" cy="16" r="15.339" />
      </g>
    </svg>
  );
};
