import React, { Children, cloneElement } from "react";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  children?: ReactNode;
};

// wrap a component with this one to make it _visually_ hidden but still available to screen readers
function VisuallyHidden({ className, children }: Props) {
  if (React.isValidElement(children)) {
    const child = Children.only(children);

    if (child) {
      const { className: childClassName = "" } = child.props;

      return cloneElement(child, {
        className: childClassName + " " + className,
      } as React.DOMAttributes<HTMLElement>);
    }
  } else {
    return <span className={className}>{children}</span>;
  }

  return children;
}

// styling hijacked from https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
const StyledVisuallyHidden = styled(VisuallyHidden)`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export default StyledVisuallyHidden;
