import React from "react";

import Routes from "./routes";
import { useConfig } from "./components/ConfigProvider";
import { Loading } from "./components/subcomponents/Loading";

export const App = () => {
  const { loading } = useConfig();

  if (loading) {
    return <Loading />;
  }

  return <Routes />;
};

export default App;
