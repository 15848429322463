import React, { ButtonHTMLAttributes } from "react";

import styled from "styled-components";

export type Props = ButtonHTMLAttributes<HTMLButtonElement>;

function Button({ type, ...props }: Props) {
  const typeOverride = props.onClick ? ("button" as const) : type;

  // the default type attribute for buttons is "submit"...
  return <button type={typeOverride} {...props} />;
}

const StyledButton = styled(Button)`
  /* Fix for iOS... https://stackoverflow.com/questions/27525228/react-click-events-not-firing-on-mobile */
  cursor: pointer;
`;

export default StyledButton;
