import React, { Component } from "react";

import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_royal_purple,
  fontsize_large,
} from "../../assets/css/variables";
import type { SmallTextValue } from "../../utils/types";

// Heading and label props are part of Scenario genericProps, but
// are now closer to label and hint respectively
type Props = {
  className?: string;
  heading: string;
  id: string;
  label?: string;
  onChangeNotify: (value: Readonly<SmallTextValue>) => void;
  placeholder?: string;
};

type State = {
  value: string;
};

class SmallText extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Will we need value prop when we are loading from statement in progress?
      value: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event: any) {
    const { value } = event.target;
    // The onChange passed from parent and an id.
    const { onChangeNotify, id } = this.props;
    const isValid = value.length ? true : false;
    this.setState({ value: value });

    // Notify parent of change through onChange passed in props.
    onChangeNotify({
      id,
      isValid,
      value: { text: value },
      contentKey: "text",
    });
  }

  render() {
    const { className, heading, id, label, placeholder } = this.props;

    return (
      <Container>
        <Heading>
          <label htmlFor={id}>{heading}</label>
        </Heading>
        <Hint>{label}</Hint>
        <SmallTextInput
          className={className}
          id={id}
          // Do we need name?
          name={id}
          onChange={this.onChange}
          placeholder={placeholder}
          type="text"
          value={this.state.value}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const Heading = styled.h3`
  font-size: ${fontsize_large};
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const Hint = styled.div`
  margin-bottom: 1rem;
`;

const SmallTextInput = styled.input`
  border-radius: 2em;
  border: 1px solid ${colour_mine_shaft};
  box-sizing: border-box;
  height: 3.5em;
  padding: 0.5em 1.5em;
  width: 100%;

  &:focus {
    box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
    outline: none;
  }

  // Prevents Firefox highlighting
  &:required {
    box-shadow: none;
  }
`;

export default SmallText;
