import { createContext, useState, useEffect, useContext } from "react";

export interface ConfigContext {
  /** The upstream service API URL */
  serviceApiUrl: string;
  /** The practitioner space URL */
  spaceUrl: string;
  /** The deploy stage name, e.g. development, staging, or production */
  stage: string;
  /** Places API Key */
  placesApiKey?: string;
  /** Where the config was loaded from */
  loadedFrom: "defaults" | "config.json";
  /** Fetch is in progress */
  loading: boolean;
}

export const defaultConfigContext: ConfigContext = {
  serviceApiUrl: process.env.REACT_APP_API_URL ?? "",
  spaceUrl: process.env.REACT_APP_PRACTITIONER_SPACE ?? "",
  stage: process.env.REACT_APP_STAGE_OVERRIDE ?? "development",
  placesApiKey: process.env.REACT_APP_PLACESKEY,
  loadedFrom: "defaults",
  loading: false,
};

export const ConfigContext = createContext<ConfigContext>(defaultConfigContext);

export const useConfig = () => useContext(ConfigContext);

export const configJsonPromise = fetch("/config.json").then((response) =>
  response.json(),
);

export const ConfigProvider = ({ children }: React.PropsWithChildren) => {
  const [config, setConfig] = useState<ConfigContext>({
    ...defaultConfigContext,
    loading: true,
  });

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await configJsonPromise;

        setConfig((prevConfig) => ({
          ...prevConfig,
          ...data,
          loadedFrom: "config.json",
        }));
      } catch (error) {
        console.error("Failed to fetch config:", error);
      } finally {
        setConfig((prevConfig) => ({
          ...prevConfig,
          loading: false,
        }));
      }
    };

    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
