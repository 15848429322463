import React from "react";
import { Route, Switch, Redirect, RouteProps } from "react-router-dom";

import ScrollToTop from "./components/subcomponents/ScrollToTop";

import {
  SessionProvider,
  useSession,
} from "./components/contexts/SessionContext";
import session from "./session";

// Pages
import Account from "./components/pages/Account/Account";
import ForgotPassword from "./components/pages/ForgotPassword";
import Handover from "./components/pages/Handover";
import Landing from "./components/pages/Landing";
import Locator from "./components/pages/Locator";
import Login from "./components/pages/Login";
import ResetPassword from "./components/pages/ResetPassword";
import Preview from "./components/pages/Preview";
import Rights from "./components/pages/Rights";
import Scenario from "./components/pages/Scenario";
import Scenarios from "./components/pages/Scenarios";
import Services from "./components/pages/Services";
import { ChooseService } from "./pages/ChooseService";
import Signup from "./components/pages/Signup";
import Thanks from "./components/pages/Thanks";
import { Version } from "./pages/Version";
import { GetStarted } from "./pages/GetStarted";
import { Statements } from "./components/pages/Statements";

function GuardedRoute({
  component: Component,
  redirectTo,
  ...rest
}: { component: any; redirectTo?: string } & RouteProps) {
  return (
    <Route
      {...rest}
      render={function render(props) {
        const user = session.isAuth === true ? session.user : null;
        const consented = user && user.consented_to.indexOf("one_momo") > -1;
        return consented ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

GuardedRoute.defaultProps = {
  redirectTo: "/login",
};

function DeAuthRoute({ component: Component, ...rest }) {
  // We can arrive here from a variety of places, set out theme and chosen service to the default
  const { updateTheme } = useSession();
  updateTheme("default");
  localStorage.removeItem("chosen-service");

  return (
    <Route
      {...rest}
      render={function render(props) {
        if (session.isAuth === true) {
          if (
            session.user &&
            // Don't deAuth if we are looking to add consent.
            session.user.consented_to.indexOf("one_momo") > -1
          ) {
            session.deAuth();
          }
        }
        return <Component {...props} />;
      }}
    />
  );
}

function NoMatchRoute({ ...rest }) {
  return (
    <Route
      {...rest}
      render={function render(props) {
        const routeTo = session.isAuth === true ? "/scenarios" : "/login";
        return (
          <Redirect
            to={{
              pathname: routeTo,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

const Routes = function () {
  return (
    <SessionProvider>
      <ScrollToTop />
      <Switch>
        <DeAuthRoute exact path="/" component={Landing} />
        <DeAuthRoute path="/login" component={Login} />
        <DeAuthRoute path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/get-started" component={GetStarted} />
        <Route
          path="/lost-password/reset/:email/:token"
          component={ResetPassword}
        />
        <Route path="/handover/:token/:profileId" component={Handover} />
        <Route path="/rights" component={Rights} />
        <Route path="/version" component={Version} />
        <GuardedRoute exact path="/scenarios" component={Scenarios} />
        <GuardedRoute path="/scenarios/:scenario" component={Scenario} />
        <GuardedRoute path="/preview/:id" component={Preview} />
        <GuardedRoute path="/account" component={Account} />
        <GuardedRoute path="/locator" component={Locator} />
        <GuardedRoute path="/services" component={Services} />
        <GuardedRoute path="/statements" component={Statements} />
        <GuardedRoute path="/choose-service" component={ChooseService} />
        <GuardedRoute path="/thanks" component={Thanks} />
        <Route component={NoMatchRoute} />
      </Switch>
    </SessionProvider>
  );
};

export default Routes;
