import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import VisuallyHidden from "./VisuallyHidden";
import {
  colour_mine_shaft,
  colour_royal_purple,
  colour_white,
} from "../../assets/css/variables";

type Props = {
  className?: string;
  onCheckboxChange: Function;
  isSelected: boolean;
  label: string;
  t: TFunction;
  value: string;
};

let idCounter = 0;

class Checkbox extends Component<Props> {
  id: string;

  constructor(props: Props) {
    super(props);

    this.id = `${idCounter++}_checkbox`;

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.props.onCheckboxChange(this.props.value);
  }

  render() {
    const { className, label, value, isSelected } = this.props;

    return (
      <div className={className}>
        <VisuallyHidden>
          <input
            type="checkbox"
            id={this.id}
            value={value}
            name={label}
            checked={isSelected}
            onChange={this.onChange}
          />
        </VisuallyHidden>
        <label htmlFor={this.id}>{label}</label>
      </div>
    );
  }
}

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  margin-top: 1rem;

  input {
    & ~ label {
      display: block;
      cursor: pointer;
      line-height: 2rem;
      padding-left: 3rem;

      &:before {
        content: "";
        cursor: pointer;
        background: ${colour_white};
        border: 1px solid ${colour_mine_shaft};
        height: 2rem;
        left: 0;
        position: absolute;
        width: 2rem;
        display: flex;
        justify-content: center;
      }
    }

    &:focus ~ label:before {
      box-shadow: 0 0 8px 2px ${colour_royal_purple};
    }

    &:checked ~ label:before {
      content: "✔";
    }
  }
`;

export { Checkbox };
export default withTranslation()(StyledCheckbox);
