import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import styled, { createGlobalStyle } from "styled-components";

import {
  colour_alto,
  colour_caribbean_green,
  colour_dusty_grey,
  colour_mine_shaft,
  colour_modal_overlay,
  colour_spring_green,
  colour_white,
} from "../assets/css/variables";

interface ModalConfirmationProps {
  children: React.ReactNode;
  isOpen: boolean;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>, id: number) => void;
  onCancel: () => void;
  closeLabel: string;
  confirmLabel: string;
  cancelLabel: string;
  id: number;
}

export const ModalConfirmation = ({
  children,
  isOpen,
  onConfirm,
  onCancel,
  closeLabel,
  confirmLabel,
  cancelLabel,
  id,
}: ModalConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <ReactModal isOpen={isOpen} overlayClassName="overlay" className="modal">
      <Container>
        <Row justify="center">
          <Col xs={12} md={10} lg={6}>
            <ModalBody>
              <CloseButton t={t} onClick={onCancel} aria-label={closeLabel} />
              {children}
              <ButtonContainer>
                <button
                  type="button"
                  onClick={(event) => {
                    onConfirm(event, id);
                  }}
                >
                  {confirmLabel}
                </button>
                <button type="button" onClick={onCancel}>
                  {cancelLabel}
                </button>
              </ButtonContainer>
            </ModalBody>
          </Col>
        </Row>
      </Container>
      <GlobalStyle />
    </ReactModal>
  );
};

const ModalBody = styled.div`
  background-color: ${colour_white};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  padding: 2rem;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  button {
    background-color: ${colour_white};
    border-radius: 2rem;
    border: 1px solid ${colour_dusty_grey};
    font-weight: 700;
    height: auto;
    min-height: 3rem;
    min-width: 12rem;

    &:hover {
      background-color: ${colour_alto};
    }
  }

  button:first-child {
    background-color: ${colour_caribbean_green};

    &:hover {
      background-color: ${colour_spring_green};
    }
  }

  button + button {
    margin-top: 1rem;
  }

  img {
    height: 1em;
    margin-left: 0.5rem;
  }
`;

const CloseButton = styled.button<{ t: any }>`
  background-color: ${colour_white};
  background-image: ${(props) =>
    "url(" + props.t("images:cross_black.src") + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: 3px solid ${colour_mine_shaft};
  border-radius: 50%;
  height: 44px;
  position: absolute;
  right: 0.5rem;
  top: 0;
  width: 44px;
`;

// Remove scroll on the body when react-modal is open.
const GlobalStyle = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .overlay {
    align-items: center;
    background-color: ${colour_modal_overlay};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
  }
`;
