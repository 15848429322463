import React, { Component } from "react";

import { Row, Col, Container } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import ReactModal from "react-modal";
import styled, { createGlobalStyle } from "styled-components";

import TextareaField from "./TextareaField";

import {
  colour_mine_shaft,
  colour_modal_overlay,
  colour_royal_purple,
  colour_shadow_light,
  colour_white,
} from "../../assets/css/variables";
import {
  GreenButtonAction,
  PurpleButtonAction,
} from "../subcomponents/ButtonAction";
import { TagButton } from "../subcomponents/ButtonAction";

type Tag = {
  name: string;
  fragment: string;
};

type Props = {
  choice: string;
  className?: string;
  message: string;
  closeControl: string;
  deleteControl: string;
  id: string;
  isOpen: boolean;
  onActionNotify: (val: string, stateVal: string) => unknown;
  question: string;
  saveControl: string;
  t: TFunction;
  textareaValue: string;
  tags?: Array<Tag>;
  ariaHideApp: boolean;
};

type State = {
  text: string;
};

// When we have use for another modal - remove the outer wrapper, make it dumb and give it children.
class ModalTextarea extends Component<Props, State> {
  textareaInput: { current: null | HTMLTextAreaElement };

  constructor(props: Props) {
    super(props);
    this.textareaInput = React.createRef();
    this.state = { text: props.textareaValue };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onTagButtonClick = this.onTagButtonClick.bind(this);
  }

  // For text changes - we keep the current value local to Textarea.
  onChange(event) {
    const { value } = event.target;
    this.setState({ text: value });
  }

  // On save, cancel or delete, we let the parent component know.
  onButtonClick(event) {
    const { onActionNotify } = this.props;
    // Using event.currentTarget.
    // Sometimes in chrome, the event.target was undefined on save action.
    // That caused intermittent text value loss.
    onActionNotify(event.currentTarget.value, this.state.text);
  }

  onTagButtonClick(event) {
    event.preventDefault();
    const value = event.currentTarget.value;

    this.setState(({ text }) => ({
      text: text + (text ? `\n${value}` : value),
    }));

    if (this.textareaInput.current) {
      this.textareaInput.current.focus();
    }
  }

  render() {
    const {
      className,
      id,
      isOpen,
      t,
      choice,
      question,
      message,
      closeControl,
      deleteControl,
      saveControl,
      tags,
      ariaHideApp,
    } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        portalClassName={className}
        overlayClassName="Overlay"
        className="Modal"
        ariaHideApp={ariaHideApp}
      >
        <Container>
          <Row justify="center">
            <Col xs={12} md={10} lg={6}>
              <ModalBody>
                <CloseButton
                  onClick={this.onButtonClick}
                  aria-label={closeControl}
                  value="close"
                />
                <Label htmlFor={id}>
                  {question}
                  <div>{choice}</div>
                </Label>
                {tags && tags.length ? (
                  <div>
                    <Message>{message}</Message>
                    <TagContainer>
                      {tags.map((tag) => (
                        <TagButton
                          key={tag.name}
                          name={tag.name}
                          onClick={this.onTagButtonClick}
                          value={tag.fragment}
                        />
                      ))}
                    </TagContainer>
                  </div>
                ) : null}
                <TextareaField
                  id={id}
                  textareaRef={this.textareaInput}
                  onChangeNotify={this.onChange}
                  value={this.state.text}
                  autoFocus={true}
                />
                <ButtonContainer>
                  <PurpleButtonAction
                    name={deleteControl}
                    image={{
                      src: t("images:bin_white.src"),
                      pos: "right",
                      alt: t("images:bin_white.alt"),
                    }}
                    onClick={this.onButtonClick}
                    value="delete"
                  />
                  <GreenButtonAction
                    name={saveControl}
                    image={{
                      src: t("images:save.src"),
                      pos: "right",
                      alt: t("images:save.alt"),
                    }}
                    onClick={this.onButtonClick}
                    value="save"
                  />
                </ButtonContainer>
                <Shadow />
              </ModalBody>
            </Col>
          </Row>
        </Container>
        <GlobalStyle />
      </ReactModal>
    );
  }
}

// TODO: Make a TagContainer component, this is copied from Textarea.jsx
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    margin: 10px 5px;
  }
`;

const StyledModal = styled(ModalTextarea)`
  .Modal {
    height: 100%;
  }

  .Overlay {
    background-color: ${colour_modal_overlay};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
  }

  textarea {
    border: 1px solid ${colour_mine_shaft};
    border-radius: 0.5rem;
    flex-grow: 1;
    padding: 1rem;

    &:focus {
      box-shadow: 0 0 0.5rem 0.125rem ${colour_royal_purple};
      outline: none;
    }
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 1rem;

  div {
    align-items: center;
    display: flex;
    margin-top: 1rem;

    &::before {
      background-color: ${colour_royal_purple};
      border-radius: 1rem;
      content: "";
      display: inline-block;
      height: 0.7rem;
      margin-right: 0.5rem;
      width: 0.7rem;
    }
  }
`;

const ModalBody = styled.div`
  background-color: ${colour_white};
  border-radius: 2rem;
  height: 75vh;
  margin: 5.5rem 1rem 1rem 1rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    flex: 0 1 45%;
  }

  img {
    height: 1em;
    margin-left: 0.5rem;
  }
`;

const Message = styled.div`
  margin-top: 1rem;
`;

const CloseButton = withTranslation()(styled.button<{ t: TFunction }>`
  background-color: ${colour_royal_purple};
  background-image: ${(props) =>
    "url(" + props.t("images:cross_white.src") + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  border-radius: 50%;
  height: 2.5rem;
  position: absolute;
  right: -1.2rem;
  top: -1.2rem;
  width: 2.5rem;
`);

const Shadow = styled.div`
  background-color: ${colour_shadow_light};
  border-radius: 2rem;
  bottom: -15px;
  height: 4rem;
  left: 0;
  margin auto;
  position: absolute;
  right: 0;
  width: 95%;
  z-index: -1;
`;

// Remove scroll on the body when react-modal is open.
const GlobalStyle = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

export default withTranslation()(StyledModal);
