import React, { ChangeEventHandler } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";
import moment from "moment";

import VisuallyHidden from "../subcomponents/VisuallyHidden";

import {
  colour_transparent,
  colour_gallery,
  colour_mine_shaft,
} from "../../assets/css/variables";

type Props = {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  day: number;
  month: number;
  year: number;
  t: TFunction;
};

const days: Array<React.JSX.Element> = [];
for (let i = 1; i < 32; i++) {
  if (i < 10) {
    days.push(
      <option key={i} value={i}>
        0{i}
      </option>,
    );
  } else {
    days.push(
      <option key={i} value={i}>
        {i}
      </option>,
    );
  }
}

const years: Array<React.JSX.Element> = [];
const lastYear = moment().year();
/// arbitrary?
const earliestYear = moment().add(-61, "years").year();
for (let i = lastYear; i > earliestYear; i--) {
  years.push(
    <option key={i} value={i}>
      {i}
    </option>,
  );
}

function DateOfBirth(props: Props) {
  const { day, month, onChange, year, t } = props;
  return (
    <DateInput>
      <legend>{t("messagesAndSettings:dob.label_dob")}</legend>
      <VisuallyHidden>
        <label htmlFor="day">{t("messagesAndSettings:dob.label_day")}</label>
      </VisuallyHidden>
      <Day
        id="day"
        name="day"
        onChange={onChange}
        value={day}
        required
        aria-required="true"
      >
        <option value="0" disabled>
          {t("messagesAndSettings:dob.label_day")}
        </option>
        {days}
      </Day>

      <VisuallyHidden>
        <label htmlFor="month">
          {t("messagesAndSettings:dob.label_month")}
        </label>
      </VisuallyHidden>
      <select
        id="month"
        name="month"
        value={month}
        onChange={onChange}
        required
        aria-required="true"
      >
        <option value="0" disabled>
          {t("messagesAndSettings:dob.label_month")}
        </option>
        {/* TODO: you could do this:
          {moment.months('MMM').map(function(localisedMonth, index) {
            return <option value={index + 1} key={localisedMonth}>{localisedMonth}</option>;
          })}
        */}
        <option value="1">{t("messagesAndSettings:dob.months.january")}</option>
        <option value="2">
          {t("messagesAndSettings:dob.months.february")}
        </option>
        <option value="3">{t("messagesAndSettings:dob.months.march")}</option>
        <option value="4">{t("messagesAndSettings:dob.months.april")}</option>
        <option value="5">{t("messagesAndSettings:dob.months.may")}</option>
        <option value="6">{t("messagesAndSettings:dob.months.june")}</option>
        <option value="7">{t("messagesAndSettings:dob.months.july")}</option>
        <option value="8">{t("messagesAndSettings:dob.months.august")}</option>
        <option value="9">
          {t("messagesAndSettings:dob.months.september")}
        </option>
        <option value="10">
          {t("messagesAndSettings:dob.months.october")}
        </option>
        <option value="11">
          {t("messagesAndSettings:dob.months.november")}
        </option>
        <option value="12">
          {t("messagesAndSettings:dob.months.december")}
        </option>
      </select>

      <VisuallyHidden>
        <label htmlFor="year">{t("messagesAndSettings:dob.label_year")}</label>
      </VisuallyHidden>
      <select
        id="year"
        name="year"
        value={year}
        onChange={onChange}
        required
        aria-required="true"
      >
        <option value="0" disabled>
          {t("messagesAndSettings:dob.label_year")}
        </option>
        {years}
      </select>
      <ValidityIndicator
        t={props.t}
        day={props.day}
        month={props.month}
        year={props.year}
      />
    </DateInput>
  );
}

// Todo - move to common size vars
const height = "3.5em";

const DateInput = styled.fieldset`
  border-radius: 2em;
  border: 1px solid ${colour_mine_shaft};
  display: flex;
  margin: 1em 0;
  height: ${height};
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;

  legend {
    background: #ffffff;
    border-radius: 2rem;
    color: #333333;
    font-weight: bold;
    left: 2rem;
    padding: 0 0.5rem;
    position: absolute;
    top: -0.6rem;
    white-space: nowrap;
  }

  select {
    appearance: none;
    background: none;
    border: none;
    border-right: 1px solid ${colour_mine_shaft};
    flex: 1 0 auto;
    height: 100%;
    padding-left: 1.5em;
    text-align: center;
    &:focus {
      outline: 0;
      background: ${colour_gallery};
    }
  }

  select::-ms-value {
    background: transparent;
    color: ${colour_mine_shaft};
  }
`;

const Day = styled.select`
  border-radius: 2em 0 0 2em;
`;

const ValidityIndicator = styled.div<{
  day: number;
  month: number;
  year: number;
  t: TFunction;
}>`
  background: ${(props) =>
    props.day !== 0 && props.month !== 0 && props.year !== 0
      ? "url(" + props.t("images:tick_circle_green.src") + ")"
      : colour_transparent};
  background-size: 100% 100%;
  flex: 0 0 1em;
  height: 1em;
  width: 1em;
  margin: 0 1em;
`;

export default withTranslation()(DateOfBirth);
