import React, { Component } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import { colour_white } from "../../assets/css/variables";
import EditSymbol from "../../assets/images/symbols/EditSymbol";
import type {
  ImageAttr,
  CheckboxText,
  CheckboxesTextValue,
  IconSize,
} from "../../utils/types";
import FieldsetWrapper from "../layouts/FieldsetWrapper";
import AddOption from "../subcomponents/AddOption";
import ImageCheckbox from "../subcomponents/ImageCheckbox";
import ModalTextarea from "../subcomponents/ModalTextarea";

type Props = {
  heading?: string;
  id: string;
  initCheckboxes: Array<
    CheckboxText & { image: ImageAttr; imageOlderYP?: ImageAttr }
  >;
  label: string;
  onChangeNotify: (val: Readonly<CheckboxesTextValue>) => void;
  t: TFunction;
  type: string;
  iconSize: IconSize;
};

type State = {
  checkboxes: Array<
    CheckboxText & { image: ImageAttr; imageOlderYP?: ImageAttr }
  >;
  modal: { isOpen: boolean; cbxName: string | null };
};

class CheckboxesText extends Component<Props, State> {
  modalQuestion: React.RefObject<HTMLDivElement>;
  modalMessage: React.RefObject<HTMLDivElement>;
  modalChoice: React.RefObject<HTMLDivElement>;
  modalControlClose: React.RefObject<HTMLDivElement>;
  modalControlDelete: React.RefObject<HTMLDivElement>;
  modalControlSave: React.RefObject<HTMLDivElement>;
  tagRefs: Array<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    const { initCheckboxes } = this.props;
    this.modalQuestion = React.createRef();
    this.modalMessage = React.createRef();
    this.modalChoice = React.createRef();
    this.modalControlClose = React.createRef();
    this.modalControlDelete = React.createRef();
    this.modalControlSave = React.createRef();
    this.tagRefs = [];

    const cbxWithDefaults = initCheckboxes.map(function (cbx) {
      cbx.custom = false;
      // Fine to replace if false and when doesn't exist.
      cbx.checked = cbx.checked ? true : false;
      cbx.text = "";
      cbx.placeholder = cbx.placeholder ? cbx.placeholder : "";
      // @ts-expect-error tags are a hack, we need to fix this
      cbx.tags = cbx.tags ? cbx.tags : [];
      return cbx;
    });

    this.state = {
      checkboxes: cbxWithDefaults,
      modal: { isOpen: false, cbxName: null },
    };

    this.onChange = this.onChange.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);
  }

  async onChange(checkboxValue: string) {
    const { onChangeNotify, id, type, iconSize } = this.props;

    this.setState(
      (prevState) => {
        const oldState = prevState.checkboxes;
        const found = oldState.some(function (checkbox) {
          return checkbox.name === checkboxValue;
        });

        if (!found) {
          const newCheckbox = {
            name: checkboxValue,
            checked: true,
            custom: true,
            text: "",
            iconSize: iconSize,
          };
          return {
            // @ts-expect-error we are adding a new checkbox - legacy code no idea why it's OK
            checkboxes: prevState.checkboxes.concat([newCheckbox]),
            modal: {
              isOpen: type === "checkboxestext" ? true : false,
              cbxName: newCheckbox.name,
            },
          };
        }

        let doOpen = type === "checkboxestext" ? true : false;
        const newCheckboxes = prevState.checkboxes.map(function (checkbox) {
          if (checkbox.name === checkboxValue) {
            if (checkbox.checked) {
              doOpen = false;
            }
            checkbox.checked = !checkbox.checked;
          }
          return checkbox;
        });

        const newModal = { isOpen: doOpen, cbxName: checkboxValue };

        return { checkboxes: newCheckboxes, modal: newModal };
      },
      () => {
        const notifyValue = {
          id,
          contentKey: "checkboxes",
          value: this.state.checkboxes.map(function ({
            name,
            checked,
            custom,
            text,
            iconSize,
          }) {
            text = checked ? text : "";
            return {
              name,
              checked,
              custom,
              text,
              iconSize,
            };
          }),
          isValid: this.hasTick(this.state.checkboxes),
        };

        // @ts-expect-error we are adding a new checkbox - legacy code no idea why it's OK
        onChangeNotify(notifyValue);
      },
    );
  }

  // The checkboxesText field is valid as long as one item is ticked
  hasTick(checkboxes) {
    return checkboxes.some(function (checkbox) {
      return checkbox.checked;
    });
  }

  // These might be better as distinct functions.
  async handleModalAction(
    action: "close" | "delete" | "edit" | "save",
    text = "",
    // TODO: we only pass the name up from the edit action
    // we should probably do it all the time instead of threading it through state?
    name = "",
  ) {
    const { onChangeNotify, id } = this.props;

    await this.setState(function (prevState) {
      const oldModal = prevState.modal;
      const newModal = Object.assign({}, oldModal, {
        isOpen: action === "edit",
        cbxName: name || oldModal.cbxName,
      });
      let newCheckboxes = prevState.checkboxes;
      if (action === "delete" || action === "save") {
        newCheckboxes = prevState.checkboxes.map(function (cbx) {
          if (cbx.name === oldModal.cbxName) {
            return Object.assign({}, cbx, {
              text: action === "delete" ? "" : text,
              checked: action === "delete" ? false : cbx.checked,
            });
          }
          return cbx;
        });
      }
      // Then replace the whole lot.
      return { checkboxes: newCheckboxes, modal: newModal };
    });

    // Copied from onChange - this is overkill but solves a problem.
    // Modal actions can update the text values in checkboxes.
    // So without this - we are always one behind.
    // Waiting for a checkbox action to trigger the form update.
    const notifyValue = {
      id,
      contentKey: "checkboxes",
      value: this.state.checkboxes.map(function ({
        name,
        checked,
        custom,
        text,
        iconSize,
      }) {
        // We only want text if the checkbox is still active.
        text = checked ? text : "";
        return {
          name,
          checked,
          custom,
          text,
          iconSize,
        };
      }),
      isValid: this.hasTick(this.state.checkboxes),
    };

    // Notify the scenario page
    // @ts-expect-error legacy code no idea why it's OK
    onChangeNotify(notifyValue);
  }

  getCbxByName(name) {
    if (name) {
      const cbx = this.state.checkboxes.find(function (cbx) {
        return cbx.name === name;
      });
      return cbx;
    } else {
      return null;
    }
  }

  render() {
    const { heading, id, label, t, type, iconSize } = this.props;
    const { checkboxes, modal } = this.state;
    const currentCbx = this.getCbxByName(modal.cbxName);
    // @ts-expect-error we hack the tags array onto the dom object, we need to fix this
    const tags = currentCbx ? currentCbx.tags : [];

    return (
      <FieldsetWrapper heading={heading} id={id} span={label}>
        {/* Information to pass to a modal, included in the page here to be available for Recite Me to translate */}
        {type === "checkboxestext" && (
          <ModalContent>
            <div ref={this.modalQuestion}>{heading}</div>
            <div ref={this.modalChoice}>{currentCbx?.name}</div>
            <div ref={this.modalMessage}>
              {t("pages:scenario.checkboxestext.modal.message")}
            </div>
            {tags &&
              tags.map((tag) => (
                <div
                  key={tag.name}
                  ref={(ref) => ref && this.tagRefs.push(ref)}
                >
                  {tag.name}
                </div>
              ))}
            <div ref={this.modalControlClose}>
              {t("pages:scenario.checkboxestext.modal.button_close")}
            </div>
            <div ref={this.modalControlDelete}>
              {t("pages:scenario.checkboxestext.modal.button_delete")}
            </div>
            <div ref={this.modalControlSave}>
              {t("pages:scenario.checkboxestext.modal.button_save")}
            </div>
          </ModalContent>
        )}
        <Row justify="center">
          {checkboxes.map(
            ({ name, checked, image, imageOlderYP, text }, key) => {
              return (
                // We'd probably like to slugify this. Though - the id + name will be unique - so do the spaces matter?
                <Col xs={6} sm={3} key={id + key}>
                  <ImageCheckbox
                    value={name}
                    imageOlderYP={imageOlderYP?.src ? imageOlderYP : null}
                    image={image?.src ? image : null}
                    isSelected={checked}
                    label={name}
                    onCheckboxChange={this.onChange}
                    size={iconSize}
                  />
                  {text && checked && (
                    <EditButton
                      onClick={() => {
                        this.handleModalAction("edit", text, name);
                      }}
                    >
                      <EditSymbol title={t("images:edit.alt")} />
                    </EditButton>
                  )}
                </Col>
              );
            },
          )}
        </Row>
        {type === "checkboxestext" && modal.isOpen && (
          <ModalTextarea
            id={id}
            key={currentCbx ? currentCbx.name : ""}
            isOpen={modal.isOpen}
            question={this.modalQuestion.current?.textContent}
            message={this.modalMessage.current?.textContent}
            tags={tags}
            choice={this.modalChoice.current?.textContent}
            closeControl={this.modalControlClose.current?.textContent}
            deleteControl={this.modalControlDelete.current?.textContent}
            saveControl={this.modalControlSave.current?.textContent}
            onActionNotify={this.handleModalAction}
            textareaValue={currentCbx ? currentCbx.text : ""}
          />
        )}
        <AddOption
          id={id}
          label={t("pages:scenario.add_own_option")}
          onClickNotify={this.onChange}
        />
      </FieldsetWrapper>
    );
  }
}

const EditButton = styled.button`
  background: ${colour_white};
  border-radius: 50%;
  display: block;
  height: 3.5rem;
  left: 50%;
  margin: 5px;
  padding: 0;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(20%);
  width: 3.5rem;
`;

const ModalContent = styled.div`
  display: none;
`;

export default withTranslation()(CheckboxesText);
