import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  colour_royal_purple,
  colour_white,
  fontsize_large,
} from "../assets/css/variables";

const StyledLink = styled(Link)`
  align-items: center;
  background: ${colour_royal_purple};
  border-radius: 2rem;
  color: ${colour_white};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-width: 25rem;
  min-height: 3.5rem;
  padding: 0 2rem;
  width: 100%;

  img {
    height: 3.2rem;
    width: 3.2rem;
  }

  span {
    font-size: ${fontsize_large};
  }

  div {
    height: 3.2rem;
    width: 3.2rem;
  }
`;

/**
 * Large purple block link to the /rights page
 */
export const RightsLink = () => {
  const { t } = useTranslation("pages");

  return (
    <StyledLink to={"/rights"}>
      <img src={t("images:gavel_white.src")} alt="" />
      <span>{t("pages:scenarios.button_rights_action")}</span>
      <div />
    </StyledLink>
  );
};
