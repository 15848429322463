import session from "../session";

interface LocationSettings {
  tld: string;
  fallbackLng: Array<string>;
  searchScope: Array<string>;
  phoneValidation: RegExp;
}

const localeDefaults = [
  {
    tld: "uk",
    fallbackLng: ["en"],
    searchScope: ["gb", "gg", "im", "je"],
    phoneValidation: /((\+44|0)(\d{8,10}))$/,
    urls: {
      production: {
        api: "https://service.mindofmyown.org.uk",
        space: "https://space.mindofmyown.org.uk",
      },
      demo: {
        api: "https://service-demo.mindofmyown.org.uk",
        space: "https://space-demo.mindofmyown.org.uk",
      },
      staging: {
        api: "https://service-staging.mindofmyown.org.uk",
        space: "https://space-staging.mindofmyown.org.uk",
      },
      dev: {
        api: "http://service-momo.test",
        space: "http://space-momo.test",
      },
    },
  },
  {
    tld: "nz",
    fallbackLng: ["en"],
    searchScope: ["nz"],
    phoneValidation: /((\+64|0)(\d{8,10}))$/,
    urls: {
      production: {
        api: "https://service.mindofmyown.org.nz",
        space: "https://space.mindofmyown.org.nz",
      },
      demo: {
        api: "https://service-demo.mindofmyown.org.nz",
        space: "https://space-demo.mindofmyown.org.nz",
      },
      staging: {
        api: "https://service-staging.mindofmyown.org.nz",
        space: "https://space-staging.mindofmyown.org.nz",
      },
      dev: {
        api: "http://service-momo.test",
        space: "http://space-momo.test",
      },
    },
  },
  {
    tld: "com",
    fallbackLng: ["en"],
    searchScope: ["au", "nf"],
    phoneValidation: /((\+61|0)(\d{8,10}))$/,
    urls: {
      production: {
        api: "https://service.mindofmyownapp.com",
        space: "https://space.mindofmyownapp.com",
      },
      demo: {
        api: "https://service-demo.mindofmyownapp.com",
        space: "https://space-demo.mindofmyownapp.com",
      },
      dev: {
        api: "http://service-momo.test",
        space: "http://space-momo.test",
      },
    },
  },
];

// This setting is most likely encountered in local development,
// so it is the least restrictive on search scopes and validation
const defaultLocationSettings = {
  tld: "",
  fallbackLng: ["en"],
  searchScope: ["gb", "gg", "im", "je", "nz", "uk"],
  phoneValidation: /((\+44|\+64|\+61|0)(\d{8,10}))$/,
};

/**
 * Uses information from the URL, or an app user's chosen location or country
 * to select settings for connected URLs and other location configuration
 */
export function getLocaleDefaults(): LocationSettings {
  // Map a native app user's country to an appropriate TLD
  if (
    window.usingCordova &&
    session?.user?.country_code &&
    session?.user?.location
  ) {
    let appLocaleDefaults;

    switch (session.user.country_code) {
      case "GB-ENG":
      case "GB-SCT":
      case "GB-WLS":
      case "GB-NIR":
      case "JE":
      case "IM":
      case "GG":
        appLocaleDefaults = localeDefaults.find(function (localeDefault) {
          return localeDefault.tld === "uk";
        });
        break;
      case "NZ":
        appLocaleDefaults = localeDefaults.find(function (localeDefault) {
          return localeDefault.tld === "nz";
        });
        break;
      case "AU":
        appLocaleDefaults = localeDefaults.find(function (localeDefault) {
          return localeDefault.tld === "com";
        });
        break;
      default:
        return defaultLocationSettings;
    }

    return appLocaleDefaults || defaultLocationSettings;
  }

  const storedCountry = localStorage.getItem("country");
  const countryObject = storedCountry ? JSON.parse(storedCountry) : null;

  // If we're in a native app with country set
  if (window.usingCordova && countryObject) {
    return (
      localeDefaults.find(function (localeDefault) {
        return localeDefault.tld === countryObject.code;
      }) || defaultLocationSettings
    );
  }

  // If we're in a native app somehow with no user info, use the default
  if (window.usingCordova) {
    return (
      localeDefaults.find(function (localeDefault) {
        return localeDefault.tld === "uk";
      }) || defaultLocationSettings
    );
  }

  // On the web, use a hostname to choose settings
  const hostname = process.env.REACT_APP_HOSTNAME
    ? process.env.REACT_APP_HOSTNAME
    : window.location.hostname;

  const tld = hostname.split(".").pop();

  const defaults = localeDefaults.find(function (localeDefault) {
    return localeDefault.tld === tld;
  });

  return defaults || defaultLocationSettings;
}
