import React from "react";
import { Trans, withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { Col, Row } from "react-grid-system";
import moment from "moment/moment";

import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import getJSON from "../../utils/getJson";
import type {
  History,
  Message,
  Organisation,
  Statement,
} from "../../utils/types";
import HeaderSection from "../layouts/HeaderSection";
import Layout, { LayoutHeader, LayoutContent } from "../layouts/Layout";
import SendStatement from "../subcomponents/SendStatement";
import { Loading } from "../subcomponents/Loading";
import session from "../../session";
import { match } from "react-router-dom";
import { ApiHocProps, withApi } from "../../api";

/* These are the fields required/ expected by API - but also should contain at least one fieldset.
 Some of them will only be present if the statement is a new one (not been retrieved from state table)
 We need to check which are really required... will be sued by content object
type StatementData = {
  title: string, // Scenario title
  created_at?: string, // Need to type date
  html?: string,
  id?: number, //Statement id
  label: string, // Statement title
  stack_id?: string, // Do we nee this?
  status?: string, // We might not need this is "published"
  type: string, // One of our statement ids e.g. complaint
  updated_at?: string, // Will be a date
  user_id?: number
};
*/

type Props = {
  match: match<{ id?: string }>;
  t: TFunction;
  history: History;
};

type State = {
  awaitingResponse: boolean;
  message: Message;
  services?: Array<Organisation>;
  statement?: Statement & {
    html: string;
  };
};

class Preview extends React.Component<ApiHocProps<Props>, State> {
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.state = {
      awaitingResponse: true,
      message: this.getHistoryState().message || { text: "" },
    };

    this.handleSent = this.handleSent.bind(this);
  }

  handleSent() {
    const { t } = this.props;

    this.props.history.push("/statements", {
      message: {
        text: t("pages:send_statement.success_message"),
      },
    });
  }

  getHistoryState(): { fromMyAccount?: boolean; message?: Message } {
    const { history } = this.props;

    if (history?.location?.state) {
      return history.location.state;
    }

    return {};
  }

  handleError(error) {
    const msg = getAxiosErrorMessage(error);

    return this.setState({
      awaitingResponse: false,
      message: { text: msg, type: "error" },
    });
  }

  async componentDidMount() {
    const chosenServiceInt = parseInt(
      localStorage.getItem("chosen-service") ?? "",
    );
    const { id } = this.props.match.params;
    // flow doesn't seem to understand when I default it in the destructure
    // i'd prefer to do const { id = '' } = ...;
    const statementId = id || "";

    const { fromMyAccount } = this.getHistoryState();

    // kind of ugly branching logic
    // TODO: rethink this method
    if (fromMyAccount) {
      let statement;

      try {
        statement = await getJSON(this.props.Api, `/statement/${statementId}`);
      } catch (e) {
        return this.handleError(e);
      }

      return this.setState({
        awaitingResponse: false,
        statement,
      });
    }

    let resources;
    try {
      // since these queries don't rely on each other we can run them at the same time
      resources = await Promise.all([
        // TODO should we be adding phone, email, address and description to /organisation?user=id response instead?
        getJSON(this.props.Api, "/user/services"),
        getJSON(this.props.Api, "/organisation?withSendInfo=1"),
        getJSON(this.props.Api, `/statement/${statementId}`),
      ]);
    } catch (error) {
      return this.handleError(error);
    }

    // Destructure the results,
    const [userServiceIds, orgs, statement] = resources;

    // only use the orgs which this user has, either chosen or from the list
    const services =
      chosenServiceInt && userServiceIds.includes(chosenServiceInt)
        ? orgs.filter((org) => {
            return org.id === chosenServiceInt;
          })
        : orgs.filter((org) => {
            return userServiceIds.includes(org.id);
          });

    this.setState({
      awaitingResponse: false,
      services,
      statement,
    });
  }

  // largely copied from StatementListItem
  listDates(t: TFunction, statement: Statement) {
    const month_list = t("messagesAndSettings:month_list");
    moment.locale(session.user?.language_code, {
      months: month_list.split("_"),
    });
    const date = moment(statement.created_at, "YYYY-MM-DD HH:mm:ss");

    if (!statement.cases.length) {
      return (
        <li>
          <Trans
            i18nKey="pages:account.statements_created_on"
            values={{
              dateISO: date.toISOString(),
              formattedDate: date.format(t("date_formats.pretty_date")),
            }}
            components={{ time: <time /> }}
          />
        </li>
      );
    }

    return statement.cases.map(function (statementCase) {
      return (
        <li key={statementCase.created_at + statementCase.worker_type}>
          <Trans
            i18nKey={
              typeof statementCase.organisation_name === "string"
                ? "pages:account.statements_sent_to_with_org"
                : "pages:account.statements_sent_to"
            }
            values={{
              dateISO: date.toISOString(),
              formattedDate: date.format(t("date_formats.pretty_date")),
              worker_type: statementCase.worker_type,
              organisation_name: statementCase.organisation_name,
            }}
            components={{ time: <time /> }}
          />
        </li>
      );
    });
  }

  render() {
    const { t } = this.props;
    const { awaitingResponse, message, services, statement } = this.state;

    const statementsExist = statement?.cases && statement.cases.length > 0;

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            message={message}
            pageTitle={t("pages:send_statement.button_send")}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              {awaitingResponse && <Loading />}
              {!awaitingResponse && statementsExist && (
                <ul>{this.listDates(t, statement)}</ul>
              )}
              {!awaitingResponse && statement && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: statement.html,
                  }}
                />
              )}
              {!awaitingResponse && services && statement && (
                <SendStatement
                  onSent={this.handleSent}
                  organisations={services}
                  statement={statement}
                />
              )}
            </Col>
          </Row>
        </LayoutContent>
      </Layout>
    );
  }
}

export default withTranslation()(withApi(Preview));
