/**
 * Class for AuthToken construction, storage, fetching
 * Required because the data blob returned from Auth needs to be separated.
 */
class AuthToken {
  auth_token = null;
  auth_token_ttl = null;
  auth_token_expires_at = null;

  /**
   * Filters data and assigns to attribs
   * @param {Object} data
   */
  constructor(data) {
    this.auth_token = data.auth_token;
    this.auth_token_ttl = data.auth_token_ttl;
    this.auth_token_expires_at = data.auth_token_expires_at;
  }

  /**
   * Stashes a token (or token data) in local storage
   * @param {Object} token
   */
  static stashLocal(token) {
    if (!(token instanceof AuthToken)) {
      token = new AuthToken(token);
    }
    localStorage.setItem("token", JSON.stringify(token));
  }

  /**
   * Fetches a token from local storage
   * @return {AuthToken|null}
   */
  static fetchLocal() {
    const tokenData = localStorage.getItem("token");
    return tokenData ? new AuthToken(JSON.parse(tokenData)) : null;
  }

  /**
   * Cleans a token from local storage
   */
  static purgeLocal() {
    localStorage.removeItem("token");
  }
}

export default AuthToken;
